import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  AreaType,
  BatteryLevel,
  FillFactorLevel,
  IconStatus,
  UnitOfMeasureOfOccurrence,
} from '@tarvos-ag/tarvos-firestore-models/src/enums';
import {
  EvolutionChart,
  Field,
  MonitoringReport,
  Occurrence,
  Glebe,
  Point,
  Trap,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { Observable, Subscription } from 'rxjs';
import { borderColor } from 'src/app/constants/color';
import { IconUrl } from 'src/app/constants/mapIconCustom';
import { IconCustom, OccurrenceUrl } from 'src/app/constants/occurrenceIcon';
import { ApplicationService } from 'src/app/services/application.service';
import {
  // GET_ACCUMULATED_CHART,
  GET_EVOLUTION_CHART,
  // GET_GLEBES,
  // GET_WEATHER_CHART,
  // GET_MAD_CHART,
  GET_REPORT_FRUIT,
} from './report-fruit.actions';
import {
  ReportFruitState,
  cardFarm,
  cardField,
  cardGlebe,
  reportFruitStateDefault,
} from './report-fruit.state';
import { google } from 'google-maps';
import { TranslateTypes } from 'src/app/services/translation.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import moment from 'moment';
import { CardTrap, FieldsByGlebe, GlebeInfos, InfoCard } from 'src/app/interfaces/Report';
import _ from 'lodash';
import { PheromoneUrl, pheromoneIconCustom } from 'src/app/constants/pheromoneIcon';
import { cardFarmLabel, cardFieldLabel, cardGlebeLabel } from 'src/app/constants/cardLabel';
import { MatDialog } from '@angular/material/dialog';
import { InstructionModalFruitComponent } from './instruction-modal-fruit/instruction-modal-fruit.component';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { FarmDetailsModalComponent } from './farm-details-modal/farm-details-modal.component';
import { GetWeatherChartArguments } from 'src/app/dto/get-weather-chart-arguments.dto';
import { GetWeatherChartResultDto } from 'src/app/dto/get-weather-chart-result.dto';
import { Infestation } from '@tarvos-ag/tarvos-firestore-models/src/enums/Infestation';
import { WeatherChart } from '@tarvos-ag/tarvos-firestore-models/src/interfaces/Weather';
import { TARVOS_WPP_LINK } from '@tarvos-ag/tarvos-firestore-models/src/constants/infos';
import { OCCURRENCES } from '@tarvos-ag/tarvos-firestore-models/src/constants/occurrences';

declare const google: google;

@Component({
  selector: 'app-report-fruit',
  templateUrl: './report-fruit.component.html',
  styleUrls: ['./report-fruit.component.scss'],
})
export class ReportFruitComponent implements OnInit {
  public initMap: boolean = false;
  public mapTypeId: any = 'satellite';
  public borderColor: any = borderColor;
  public map: any = {};
  public selected: boolean = true;
  public groundOverlayList: Array<google.maps.GroundOverlay> = [];

  public formGroup!: FormGroup;

  public monitoringReportSelected!: MonitoringReport | null;
  public fieldSelected!: Field | null;
  public glebeSelected!: Glebe | null;
  public glebeSelectedInfos!: GlebeInfos | null;
  public lastFieldsSelected!: Array<Field>;
  public occurrenceSelected!: Occurrence;
  public weatherData: Array<WeatherChart> = [];
  public trapsField!: Array<any>;
  public expandView: boolean = false;
  public isMobile!: boolean;
  public moment = moment;
  public areaType = AreaType;
  public batteryLevel = BatteryLevel;
  public fillFactorLevel = FillFactorLevel;
  public unit = UnitOfMeasureOfOccurrence;
  public fieldsByGlebe!: Array<FieldsByGlebe>;
  public titleChart!: string;
  public titleFarm!: string;
  public titleInfo!: string;
  public cardFarm!: InfoCard | null;
  public cardTrap!: Array<CardTrap>;
  public generalTab!: boolean;
  public stateCaption: boolean = false;
  public noOccurrence: boolean = false;
  public fieldNoMonitoring!: boolean;
  public openTagPoint: boolean = true;

  public reportFruitState$: Observable<ReportFruitState>;
  public state: ReportFruitState = reportFruitStateDefault;
  public subscribe!: Subscription;

  public showMenuLegend: boolean = false;
  public clickedMainContent: boolean = true;
  public occurrences = OCCURRENCES;

  // EXEMPLO METODO 2 GET ARGS /////////////////////////
  // getQueryArguments!: GetWeatherChartArguments;
  getQueryResult!: GetWeatherChartResultDto;
  public testChartFields: any;

  constructor(
    public applicationService: ApplicationService,
    public trans: TranslateTypes,
    private store: Store,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private labelPipe: LabelPipe,
    private dialog: MatDialog
  ) {
    this.reportFruitState$ = this.store.pipe(select('reportFruitState' as any));

    const token = this.route.snapshot.queryParams.token;
    const ux = this.route.snapshot.queryParams.ux;
    this.store.dispatch(GET_REPORT_FRUIT({ token, ux }));
    this.state.phone = TARVOS_WPP_LINK;
  }

  public ngOnInit(): void {
    this.onResize();
    this.formGroup = this.formBuilder.group({ occurrence: [null], field: [null] });
    this.initialTab();
    this.subscribe = this.reportFruitState$.subscribe((state: ReportFruitState) => {
      this.state = state;
      if (!this.initMap) {
        if (
          this.state.monitoringReport.length > 0 &&
          this.state.reportFruit &&
          this.occurrences.length > 0
        ) {
          this.titleFarm = this.labelPipe.transform(this.trans.label.farm);
          this.occurrenceSelected = this.checkTheOccurrencesMonitoring().filter(
            (occ) => occ.id == 'CAPITATA'
          )[0];
          this.noOccurrence = false;
          // Ocorrência marker
          this.load_polygon_centroid();

          // marcações sem ocorrências
          //this.iconPoint();

          this.initMap = true;
        }
        if (this.state.fields.length > 0) {
          const fields = this.state.fields;
          this.lastFieldsSelected = fields;
          this.generalTab = true;
          this.updateReport();
          this.getGlebeAndFields(fields);
        }
        // if (this.state.showModal) {
        //   if (this.state.showModal === true) this.modalInstruction();
        // }
      }
    });
  }
  public onClickMainContent() {
    this.clickedMainContent = true;
  }
  public onMenuLegendClick() {
    this.showMenuLegend = !this.showMenuLegend;
    this.clickedMainContent = false;
  }

  onResetAll() {
    this.noOccurrence = false;
    this.updateReport();
    this.ngOnInit();
  }

  openModalFarmDetails(): void {
    const dialogRef = this.dialog.open(FarmDetailsModalComponent, {
      width: '750px',
      data: {
        fone: this.state.phone,
        glebe: this.fieldsByGlebe,
        nome: this.state.reportFruit?.alias
          ? this.state.reportFruit?.alias
          : this.state.reportFruit?.name,
        glebesInfos: this.state.infos,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const glebe = this.fieldsByGlebe.filter((fbg) => fbg.glebe.id == result.id)[0];
        this.tabSelected('', glebe);
      }
    });
  }

  public async mapReady(map: any) {
    this.map = map;

    this.map.setOptions({
      tilt: 0,
      scaleControl: true,
    });
    this.onSelectionChangeOccurrence(this.checkTheOccurrencesMonitoring()[0]);
  }

  public async onSelectionChangeOccurrence(occurrence: Occurrence) {
    this.occurrenceSelected = occurrence;
    this.updateReport();

    await this.setGroundOverlay();
    await this.setFitBounds(this.lastFieldsSelected);

    const monitoringReport = occurrence
      ? this.getMonitoringReport().filter((report) => report.occurrenceId === occurrence.id)[0]
      : this.getMonitoringReport()[0];

    this.noOccurrence = monitoringReport === undefined || monitoringReport === null;

    if (this.noOccurrence) this.gotoTop();
  }

  public async onSelectionChangeGlebes(fields: Array<Field>) {
    this.lastFieldsSelected = fields;
    this.fieldSelected = null;
    this.monitoringReportSelected = null;
    this.updateReport();
    this.weatherData = this.compileWeatherData(this.glebeSelected, this.fieldSelected);
    await this.setGroundOverlay();
    await this.setFitBounds(fields);
  }

  public getMonitoringReport(): Array<any> {
    return this.state.monitoringReport
      .filter(
        (monitoringReport: MonitoringReport) =>
          monitoringReport.occurrenceId === this.occurrenceSelected.id
      )
      .filter((monitoringReport: MonitoringReport) => {
        return this.lastFieldsSelected.some(
          (field) => field.id === monitoringReport.field?.fieldId
        );
      });
  }

  // OBJ USED IN MAP //////////////
  public getOcorrence(): Array<any> {
    let fieldResult: any = {
      monitoringType: '',
      field: [],
      occurrenceId: '',
      points: [],
      icoUrl: '',
      icoSize: '',
      glebe: {},
    };

    fieldResult = this.state.monitoringReport
      .filter(
        (monitoringReport: MonitoringReport) =>
          monitoringReport.occurrenceId === this.occurrenceSelected.id
      )
      .filter((monitoringReport: MonitoringReport) => {
        return this.lastFieldsSelected.some((field) => field.id === monitoringReport.field.fieldId);
      });

    for (let index = 0; index < fieldResult.length; index++) {
      const arrResult = fieldResult[index];
      let icon = this.getIconOccurrenceSvg(arrResult);
      !this.glebeSelected ? (arrResult.icoUrl = icon.url) : (arrResult.icoUrl = icon);
      for (let index = 0; index < this.fieldsByGlebe.length; index++) {
        const fg = this.fieldsByGlebe[index];
        for (let index = 0; index < fg.fields.length; index++) {
          const fg_fields = fg.fields[index];
          if (arrResult.field?.fieldId === fg_fields.id) {
            arrResult.glebe = {
              fields: fg.fields,
              glebe: fg.glebe,
            };
          }
        }
      }
    }

    return fieldResult;
  }

  public getPolygonPoints(fieldId: string): Array<Point> {
    const points: Array<Point> | undefined = this.state.fields.find(
      (field: Field) => field.id === fieldId
    )?.polygon.points;
    return points ? points : [];
  }

  public setGroundOverlay(fields?: Array<Field>): Promise<void> {
    this.groundOverlayList.forEach((groundOverlay) => groundOverlay.setMap(null));

    return new Promise<void>((resolve) => {
      if (!fields) {
        if (this.state.reportFruit && this.state.reportFruit.fieldIds) {
          for (const fieldId of this.state.reportFruit.fieldIds) {
            const points: Array<Point> = this.getPolygonPoints(fieldId);
            const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

            points.forEach((coordinate) => {
              bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
            });

            // INSERT HEATMAP IMAGE ////////////////////
            if (this.glebeSelected) {
              const report = this.getMonitoringReport().filter(
                (report) => report.field?.fieldId == fieldId
              )[0];

              if (report?.field?.heatmapImage) {
                const groundOverlay: google.maps.GroundOverlay = new google.maps.GroundOverlay(
                  `data:image/svg;base64,${report.field?.heatmapImage}`,
                  bounds
                );
                groundOverlay.setOpacity(0.8);
                groundOverlay.setMap(this.map);
                this.groundOverlayList.push(groundOverlay);
              }
            }
          }
        }
      }

      resolve();
    });
  }

  public setFitBounds(fields?: Array<Field>): Promise<void> {
    return new Promise((resolve) => {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

      if (!fields || fields.length == 0) {
        for (const report of this.getMonitoringReport()) {
          const points: Array<Point> = this.getPolygonPoints(report.field?.fieldId);
          points.forEach((coordinate) => {
            bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
          });
        }
        this.map.fitBounds(bounds);
      } else {
        for (const field of fields) {
          const points: Array<Point> = field.polygon?.points;
          points.forEach((coordinate) => {
            bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
          });
        }
        this.map.fitBounds(bounds);
      }
      resolve();
    });
  }

  public initialTab(): void {
    document.getElementById('defaultOpen')!.click();
  }

  public getIconPheromoneSvg(pheromone: string, fieldId?: string): any {
    const iconCustom = { ...pheromoneIconCustom };
    let focus = false;
    if (fieldId) {
      focus = this.monitoringReportSelected?.field?.fieldId === fieldId;
    }

    if (pheromone) {
      if (!focus) {
        iconCustom.url = PheromoneUrl['NORMAL'][pheromone]
          ? `./../assets/icons/${PheromoneUrl['NORMAL'][pheromone]}`
          : `./../assets/icons/${PheromoneUrl['NORMAL']['Helicoverpa Armigera']}`;
      } else {
        iconCustom.url = PheromoneUrl['FOCO'][pheromone]
          ? `./../assets/icons/${PheromoneUrl['FOCO'][pheromone]}`
          : `./../assets/icons/${PheromoneUrl['FOCO']['Helicoverpa Armigera']}`;
      }
    } else {
      iconCustom.url = `./../assets/icons/${PheromoneUrl['Helicoverpa Armigera']}`;
    }
    return iconCustom;
  }

  public getIconOccurrence(occurrenceId: string): any {
    let url;
    if (occurrenceId) {
      url = OccurrenceUrl['OCCURRENCE'][occurrenceId]
        ? `./../assets/images/${OccurrenceUrl['OCCURRENCE'][occurrenceId]}`
        : `./../assets/images/${OccurrenceUrl['OCCURRENCE']['ARMIGERA']}`;
    } else {
      url = `./../assets/images/${IconUrl['TRAP'][IconStatus.LIGHT]}`;
    }
    return url;
  }

  public getIconOccurrenceSvg(occurrence: any): any {
    const iconCustom = IconCustom;

    if (occurrence.occurrenceId) {
      if (!this.glebeSelected) {
        this.isMobile
          ? (iconCustom.scaledSize = { height: 50, width: 50 })
          : (iconCustom.scaledSize = { height: 70, width: 70 });
        // IMPORTANTE!: VERIFICAR IDS DAS INFESTAÇÕES E PEDIR PARA PADRONIZAR ///////////////
        iconCustom.url = OccurrenceUrl['OCCURRENCE_HEAT'][occurrence.field.infestation][
          occurrence.occurrenceId
        ]
          ? `./../assets/icons/report/${
              OccurrenceUrl['OCCURRENCE_HEAT'][occurrence.field.infestation][
                occurrence.occurrenceId
              ]
            }`
          : `./../assets/icons/report/${
              OccurrenceUrl['OCCURRENCE_HEAT'][occurrence.field.infestation]['ARMIGERA']
            }`;
      } else {
        iconCustom.scaledSize = { height: 20, width: 20 };
        iconCustom.url = OccurrenceUrl['OCCURRENCE'][occurrence.occurrenceId]
          ? `./../assets/images/${OccurrenceUrl['OCCURRENCE'][occurrence.occurrenceId]}`
          : `./../assets/images/${OccurrenceUrl['OCCURRENCE']['ARMIGERA']}`;
      }
    } else {
      iconCustom.url = `./../assets/images/${IconUrl['TRAP'][IconStatus.LIGHT]}`;
    }

    // solução para remover botão close do agm-info-window / content: gm-style-iw-a / btn: gm-style-iw-chr
    const btnClouseIw = document.getElementsByClassName('gm-style-iw-a');
    if (btnClouseIw) {
      for (let index = 0; index < btnClouseIw.length; index++) {
        const e = btnClouseIw[index];
        !this.glebeSelected ? e.getElementsByClassName('gm-style-iw-chr')[0]?.remove() : e.remove();
      }
    }

    return iconCustom;
  }

  public onFieldSelect(fieldId: string, glebeId: string): void {
    const monitoringReportFilter = this.getMonitoringReport().filter(
      (report) => report.field?.fieldId == fieldId
    );
    if (monitoringReportFilter.length == 0) return;
    const monitoringReport = monitoringReportFilter[0];
    this.monitoringReportSelected = monitoringReport;
    if (monitoringReport) {
      this.initMap = true;
      this.monitoringReportSelected = monitoringReport;
      this.fieldSelected = this.state.fields.filter(
        (field: Field) => field.id === monitoringReport.field?.fieldId
      )[0];
      // this.setAccumulatedChart(this.fieldSelected.id);
      // this.setMadChart(this.fieldSelected.glebe.name);
      this.iconPoint();
      if (monitoringReport.points.length > 0) {
        this.fieldNoMonitoring = false;
      } else {
        this.fieldNoMonitoring = true;
      }
      //this.setCardTrap(monitoringReport.points);
      this.updateReport();
      this.glebeSelected = this.fieldSelected.glebe;
      this.glebeSelectedInfos = this.getGlebeInfos(glebeId);
    } else {
      this.fieldSelected = null;
      this.noOccurrence = true;
    }

    let tablink = document.getElementById(glebeId);
    const tablinks = document.getElementsByClassName('tablinks');
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }
    tablink?.classList.toggle('active');
    this.weatherData = this.compileWeatherData(this.glebeSelected!, this.fieldSelected);
  }

  public getEvolutionChartData(): any {
    return this.state.evolutionChartByFields;
  }

  public getReportAccumulatedData(): any {
    return this.state.reportAccumulated;
  }

  public getMadControlLevels() {
    return {
      control: this.state.madControlLevels?.controlLevels?.controlLevel || 0.5,
      damage: this.state.madControlLevels?.controlLevels?.damageLevel || 1,
    };
  }

  public getOccurrences(): Array<Occurrence> {
    if (this.state.reportFruit && this.occurrences.length > 0) {
      return this.occurrences
        .filter(
          (occurrence: any) =>
            this.state.reportFruit &&
            this.state.reportFruit.occurrenceIds.filter((id: string) => id === occurrence.id)
              .length > 0 &&
            occurrence.id !== 'DANO_EM_ESTRUTURA_REPRODUTIVA'
        )
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
    }

    return [];
  }

  public getGlebeAndFields(fields: Array<Field>): any {
    let glebes = {};
    if (fields.length > 0) {
      glebes = fields.reduce((acc: any, obj: Field) => {
        const key = `${obj.glebe.name}`;
        if (!acc[key]) {
          acc[key] = {
            glebe: obj.glebe,
            fields: [],
          };
        }
        if (acc[key]) {
          acc[key].fields.push(obj);
        }
        return acc;
      }, {});
    }
    this.fieldsByGlebe = [...Object.values(glebes)] as Array<FieldsByGlebe>;
  }

  public getGlebeInfos(glebeId?: string): GlebeInfos | null {
    return glebeId ? this.state.infos.filter((glebe: any) => glebe.id == glebeId)[0] : null;
  }

  public getFieldsInfos() {
    let fields;

    if (!this.fieldSelected) {
      fields = this.glebeSelectedInfos ? this.glebeSelectedInfos.fields : null;
    } else {
      fields =
        this.glebeSelectedInfos?.fields.filter((item) => {
          return item.name == this.fieldSelected?.name;
        }) ?? null;
    }

    if (!fields) return null;
    else {
      return fields;
    }
  }

  public getTrapsFromField(fieldId: string): Array<Trap> | null {
    if (!this.glebeSelectedInfos) return null;
    return this.glebeSelectedInfos.traps.filter((trap) => trap.fieldId == fieldId);
  }

  public getTrapsModels(glebeId: string, fieldId?: string) {
    const glebeInfos = this.getGlebeInfos(glebeId);
    if (!glebeInfos) return [];

    const traps = fieldId
      ? glebeInfos.traps.filter((trap) => trap.fieldId == fieldId)
      : glebeInfos.traps;

    const models = traps.reduce((acc: { [key: string]: number }, cur) => {
      if (!acc[cur.device.model]) acc[cur.device.model] = 1;
      else acc[cur.device.model] += 1;
      return acc;
    }, {});

    return Object.keys(models).map((model) => ({ model, count: models[model] }));
  }

  public getFieldInfestation(fieldId: string): Infestation | null {
    //HERE
    const fields = this.state.monitoringReport.map((report) => report.field);
    const field = fields.filter((f) => f.fieldId == fieldId)[0];
    if (!field) return null;

    const infestation =
      field.infestation == Infestation.ZERO ? Infestation.MODERATE : field.infestation;

    return infestation;
  }

  public getFtdLevel(ftdMean?: number): Infestation | null {
    const { control, damage } = this.getMadControlLevels();
    if (!ftdMean) return null;
    else if (ftdMean >= damage) return Infestation.HIGH;
    else if (ftdMean >= control) return Infestation.MEDIUM;
    else return Infestation.MODERATE;
  }

  public getMediaMadChart() {
    const { control, damage } = this.getMadControlLevels();
    return this.state.infos.map((glebe) => ({
      area: glebe.alias ?? glebe.name,
      value: Number(glebe.ftdMean?.toFixed(2)) || 0,
      control,
      damage,
    }));
  }

  public compileWeatherData(
    glebeSelected?: Glebe | null,
    fieldSelected?: Field | null
  ): Array<WeatherChart> {
    if (!glebeSelected) return [];

    const glebeInfos = this.state.infos.filter((glebe) => glebe.id == glebeSelected.id)[0];
    const weather = glebeInfos?.weather;

    if (!weather || !this.state.evolutionChartByFields) return [];

    const weatherChartData: Array<WeatherChart> = [];
    const counts: any = {};
    if (!fieldSelected) {
      this.state.evolutionChartByFields.data.forEach((field) => {
        field.forEach((data) => {
          const date: Date = new Date(data.date);
          const tzoffset = 3 * 60 * 60000; //offset in milliseconds
          const localISOTime = new Date(date.getTime() - tzoffset);

          const dateString = localISOTime.toISOString().substring(0, 10);
          if (counts[dateString]) counts[dateString] += data.value;
          else counts[dateString] = data.value;
        });
      });
    } else {
      this.state.evolutionChartByFields.data.forEach((trap) => {
        trap.forEach((data) => {
          const date: Date = new Date(data.date);
          const tzoffset = 3 * 60 * 60000; //offset in milliseconds
          const localISOTime = new Date(date.getTime() - tzoffset);

          const dateString = localISOTime.toISOString().substring(0, 10);
          if (counts[dateString]) counts[dateString] += data.value;
          else counts[dateString] = data.value;
        });
      });
    }
    for (const d of Object.keys(counts)) {
      const w = weather.filter((item) => item.date == d)[0];
      const data: WeatherChart = {
        date: d,
        tmed: w ? w.tmed ?? (w.tmax + w.tmin) / 2 : undefined,
        precipitation: w ? w.precipitation : undefined,
        count: counts[d] ?? undefined,
      };
      weatherChartData.push(data);
    }
    return weatherChartData.sort((a, b) => a.date.localeCompare(b.date));
  }

  public formatTrapName(trap: Trap) {
    if (trap.alias) return `[${trap.device.model}] ${trap.name} (${trap.alias})`;
    return `[${trap.device.model}] ${trap.name}`;
  }

  public fieldName(fieldId: string): string | null {
    const field = this.state.fields.find((field: Field) => field.id === fieldId);

    // if (field) return field?.alias ? field.alias : field.name;
    if (field) return field.name;
    return null;
  }

  /**
   * Este método gera o título do gráfico
   * @param optionOne Objeto 1
   * @param optionTwo Objeto 2
   */
  public updateGeneralInfo(): void {
    if (!this.fieldSelected) {
      if (this.generalTab) {
        this.titleChart = this.occurrenceSelected ? this.occurrenceSelected.name : ` (Geral)`;
        this.setCardFarm();
      } else {
        this.setCardGlebe();
        this.titleChart = this.glebeSelected?.alias
          ? ` (${this.glebeSelected?.alias}) - ${this.occurrenceSelected.name}`
          : ` (${this.glebeSelected?.name}) - ${this.occurrenceSelected.name}`;
      }
    } else {
      this.setCardField();
      this.titleChart = !this.fieldNoMonitoring
        ? ` (${this.translateService.instant(this.trans.label['byTrap'])}) - ${
            this.occurrenceSelected.name
          }`
        : '';
    }
  }

  // private setAccumulatedChart(fieldId: string): void {
  //   const endDate = moment(this.state.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ss');
  //   this.store.dispatch(
  //     GET_ACCUMULATED_CHART({
  //       customerId: this.state.customerId,
  //       farmId: this.state.farmId,
  //       harvestId: this.state.harvestId,
  //       fieldId: fieldId,
  //       occurrenceId: this.occurrenceSelected.id,
  //       days: 10,
  //       endDate: new Date(endDate),
  //     })
  //   );
  // }

  // private setMadChart(glebeName: string): void {
  //   if (['CAPITATA', 'ANASTREPHA'].includes(this.occurrenceSelected.id)) {
  //     const { startDate, endDate } = this.convertPeriod(this.state.startDate, this.state.endDate);
  //     this.store.dispatch(
  //       GET_MAD_CHART({
  //         startDate: new Date(startDate),
  //         endDate: new Date(endDate),
  //         customerId: this.state.customerId,
  //         farmId: this.state.farmId,
  //         harvestId: this.state.harvestId,
  //         glebeName: glebeName,
  //       })
  //     );
  //   }
  // }

  public tabSelected(evt: any, glebe?: FieldsByGlebe): void {
    this.noOccurrence = false;

    let i, tablinks, fields: Array<Field>;

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    if (glebe) {
      fields = glebe.fields;
      this.glebeSelected = glebe.glebe;
      this.glebeSelectedInfos = this.getGlebeInfos(this.glebeSelected!.id);
      this.generalTab = false;
      let tablink = document.getElementById(this.glebeSelected.id);
      !evt ? tablink?.classList.add('active') : null;
    } else {
      fields = this.state.fields;
      this.glebeSelected = null;
      this.glebeSelectedInfos = null;
      this.generalTab = true;
    }
    this.clearCards();
    this.onSelectionChangeGlebes(fields);
    this.iconPoint();

    // Show the current tab, and add an "active" class to the button that opened the tab
    evt ? (evt.currentTarget.className += ' active') : null;
  }

  public openCaption(evt: any): void {
    // Get all elements with class="tablinks" and remove the class "active"
    let tablinks = document.getElementsByClassName('content-caption')[0] as HTMLElement;
    if (!this.stateCaption) {
      evt.currentTarget.className += ' active';
      tablinks.style.display = 'block';
    } else {
      evt.currentTarget.className = 'collapsible';
      tablinks.style.display = 'none';
    }
    this.stateCaption = !this.stateCaption;
  }
  public changeView(): void {
    this.expandView = !this.expandView;
  }

  public updateReport(): void {
    this.updateGeneralInfo();
    if (this.fieldSelected) {
      const currentEvotutionChart = (
        !this.fieldNoMonitoring
          ? this.state.evolutionChart.find(
              (evolutionChart: EvolutionChart) =>
                evolutionChart.occurrenceId === this.occurrenceSelected.id &&
                this.monitoringReportSelected?.field?.fieldId === evolutionChart.fieldId
            )
          : null
      ) as EvolutionChart | null;
      this.state.evolutionChartByFields = currentEvotutionChart;
    } else {
      if (this.lastFieldsSelected.length > 0 && this.occurrenceSelected) {
        const fieldIds = this.lastFieldsSelected.map((field: Field) => field.id);
        const occurrenceId = this.occurrenceSelected.id;
        const { startDate, endDate } = this.convertPeriod(this.state.startDate, this.state.endDate);
        this.store.dispatch(
          GET_EVOLUTION_CHART({
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            customerId: this.state.customerId,
            farmId: this.state.farmId,
            harvestId: this.state.harvestId,
            fieldIds,
            occurrenceId,
          })
        );
      }
    }
  }

  public load_polygon_centroid(): void {
    this.state.monitoringReport = this.state.monitoringReport.map(
      (monitoringReport: MonitoringReport) => {
        if (monitoringReport.points.length > 0) {
          let points = this.getPolygonPoints(monitoringReport.field?.fieldId);

          let first = points[0],
            last = points[points.length - 1];

          if (first.lng != last.lng || first.lat != last.lat) points.push(first);
          let area: number = 0,
            lng = 0,
            lat = 0,
            nPts = points.length,
            p1: { lat: number; lng: number },
            p2: { lng: number; lat: number },
            f: number;
          for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
            p1 = points[i];
            p2 = points[j];
            f =
              (p1.lat - first.lat) * (p2.lng - first.lng) -
              (p2.lat - first.lat) * (p1.lng - first.lng);
            area += f;

            lng += (p1.lng + p2.lng - 2 * first.lng) * f;
            lat += (p1.lat + p2.lat - 2 * first.lat) * f;
          }
          f = area * 3;

          return {
            ...monitoringReport,
            centroid: { lat: lat / f + first.lat, lng: lng / f + first.lng },
          };
        }
        return {
          ...monitoringReport,
          centroid: null,
        };
      }
    );
  }

  public iconPoint(): void {
    this.state.monitoringReport = this.state.monitoringReport.map(
      (monitoringReport: MonitoringReport) => {
        let points = monitoringReport.points.map((point: any) => {
          const pheromone =
            point.iconType === 'NO_COMMUNICATION' ? point.iconType : point.pheromone;
          let icon = this.getIconPheromoneSvg(pheromone, point.fieldId);
          return { ...point, icon };
        });

        return { ...monitoringReport, points };
      }
    );
  }

  //seta os valores do card com informações de fazenda
  public setCardFarm(): void {
    if (this.state.farm) {
      let farm = [];
      for (const [key, value] of Object.entries(this.state.farm)) {
        if (cardFarm.some((item: string) => item == key) && value) {
          const label = cardFarmLabel[key];
          farm.push({ label: label, content: value });
        }
      }

      this.cardFarm = {
        title: `${this.translateService.instant(
          this.labelPipe.transform(this.trans.title['infoFarm'])
        )}`,
        subTitle: this.state.farm.name,
        field: farm,
        image: '../../../assets/images/Farm.png',
      };
    }
  }

  public onZoomChange(zoom: any): void {
    if (zoom >= 14) {
      this.openTagPoint = true;
    } else {
      this.openTagPoint = false;
    }
  }

  onMouseOverMarker(infoWindow: any, gm: any) {
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }
  onMouseOutMarker(gm: any) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
  }

  public setCardGlebe(): void {
    if (this.glebeSelected) {
      let glebe = [];
      for (let [key, value] of Object.entries(this.glebeSelected)) {
        if (cardGlebe.some((item: string) => item == key) && value) {
          const label = cardGlebeLabel[key];
          glebe.push({ label: label, content: value });
        }
      }

      this.cardFarm = {
        title: `${this.translateService.instant(
          this.labelPipe.transform(this.trans.title['infoGlebe'])
        )}`,
        subTitle: this.glebeSelected.alias ? this.glebeSelected.alias : this.glebeSelected.name,
        field: glebe,
        image: '../../../assets/images/Glebe.png',
      };
    }
  }

  public setCardField(): void {
    if (this.fieldSelected) {
      let fields = [];
      for (let [key, value] of Object.entries(this.fieldSelected)) {
        if (cardField.some((item: string) => item == key) && value) {
          const label = cardFieldLabel[key];
          if (key === 'glebe') {
            fields.push({ label: label, content: value.alias ? value.alias : value.name });
          } else if (key === 'area') {
            fields.push({ label: label, content: `${value.hectares}` });
          } else {
            fields.push({ label: label, content: value });
          }
        }
      }
      this.cardFarm = {
        title: `${this.translateService.instant(
          this.labelPipe.transform(this.trans.title['infoField'])
        )}`,
        // subTitle: this.fieldSelected.alias ? this.fieldSelected.alias : this.fieldSelected.name,
        subTitle: this.fieldSelected.name,
        field: fields,
        image: '../../../assets/images/Field.png',
      };
    }
  }
  public setCardTrap(points: Array<any>): void {
    if (points.length > 0) {
      this.cardTrap = [];
      points.forEach((point: any) => {
        this.cardTrap.push({
          title: `${this.translateService.instant(this.trans.label['trap'])}`,
          subTitle: point.alias ? point.alias : point.name,
          pheromoneText: this.translateService.instant(this.trans.text['pheromone']),
          pheromone: point.pheromone,
          iconText:
            point.iconType !== 'NO_COMMUNICATION'
              ? this.translateService.instant(this.trans.text['iconPheromone'], {
                  '%0': point.pheromone,
                })
              : this.translateService.instant(this.trans.text.trapNoCommunication),
          icon: point.icon.url,
          image: '../../../assets/icons/trap.svg',
        });
      });
    } else {
      this.cardTrap = [];
    }
  }

  public clearCards(): void {
    this.cardFarm = null;
    this.cardTrap = [];
  }
  @HostListener('window:resize', ['$event'])
  public onResize() {
    if (window.innerWidth < 1280) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  public checkTheOccurrencesMonitoring(): Array<Occurrence> {
    return this.getOccurrences().filter((occurrence: Occurrence) => {
      return this.state.monitoringReport.some(
        (report: MonitoringReport) => report.occurrenceId === occurrence.id
      );
    });
  }

  public convertPeriod(startDate: string, endDate: string): { startDate: string; endDate: string } {
    const eDate = moment(endDate, 'DD-MM-YYYY');
    const period =
      eDate.diff(moment(startDate, 'DD-MM-YYYY'), 'days') <= 3
        ? 7
        : eDate.diff(moment(startDate, 'DD-MM-YYYY'), 'days');
    const endDateFormat = moment(endDate, 'DD-MM-YYYY')
      .subtract(1, 'days')
      .format('YYYY-MM-DD HH:mm:ss');
    const startDateFormat = moment(endDate, 'DD-MM-YYYY')
      .subtract(period, 'days')
      .format('YYYY-MM-DD HH:mm:ss');
    return { startDate: startDateFormat, endDate: endDateFormat };
  }

  public modalInstruction(): void {
    const isFruit = this.state.reportFruit?.occurrenceIds.some((occurrenceId) => {
      return ['CAPITATA', 'ANASTREPHA'].includes(occurrenceId);
    });

    const dialogRef = this.dialog.open(InstructionModalFruitComponent, {
      panelClass: 'my-custom-dialog-class',
      height: isFruit ? '320' : '260',
      disableClose: false,
      data: { isFruit },
    });
    const subs = dialogRef.afterClosed().subscribe((results) => {
      // this.store.dispatch(REMOVE_TRAP({ ids }));
      subs.unsubscribe();
    });
  }

  createReportTitle(): string {
    if (!this.state || !this.state.reportFruit) return '';

    if (this.state.reportFruit.alias) return ` - ${this.state.reportFruit.alias}`;
    else return ` (${this.state.reportFruit.name})`;
  }

  createGlebeTitle(glebe: Glebe): string {
    return glebe.alias ?? glebe.name;
  }

  reportHasMad(glebe?: Glebe): boolean {
    const fruitCropId = 'FRUTAS';
    if (glebe) {
      return glebe.cropId === fruitCropId;
    }

    return this.state.fields.some((field) => field.glebe?.cropId === fruitCropId);
  }

  getTestChart(args: GetWeatherChartArguments) {
    this.applicationService.getTestChart(args).subscribe({
      next: (success: any) => {
        this.testChartFields = success;
      },
      error: (error) => console.error(error),
    });
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
