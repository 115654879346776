<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loading" [excitedTime]="'1s'"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="onCloseModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span *ngIf="!data.edit" class="title">{{ trans.button.createReport | translate }}</span>
  <span *ngIf="data.edit" class="title">{{ trans.button.editReport | translate }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <mat-horizontal-stepper
    [linear]="true"
    labelPosition="bottom"
    fxLayout="column"
    fxLayoutAlign="center center"
    #stepper
  >
    <mat-step [stepControl]="firstFormGroup">
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        class="material-text-container tm-color-gray"
      >
        <h4>{{ trans.title.chooseReportFormat | translate }}:</h4>
        <p>
          <strong>{{ trans.button.xls | translate }}:</strong>
          {{ trans.text.xlsMessage | translate }}
        </p>
        <p>
          <strong>{{ trans.button.pdf | translate }}:</strong>
          {{ trans.text.pdfMessage | translate }}
        </p>
        <form class="custom-form-field" [formGroup]="firstFormGroup">
          <mat-radio-group
            fxLayout="row"
            fxLayout.gt-xs="row wrap"
            fxLayout.gt-md="row wrap"
            formControlName="type"
            class="material-radio-group"
            (change)="onChangeRadio($event)"
          >
            <mat-radio-button
              fxFlex="100%"
              fxFlex.gt-xs="0 1 50%"
              [value]="generateReportType.pdf"
              >{{ trans.button.pdf | translate }}</mat-radio-button
            >
            <mat-radio-button
              fxFlex="100%"
              fxFlex.gt-xs="0 1 50%"
              [value]="generateReportType.xls"
              >{{ trans.button.xls | translate }}</mat-radio-button
            >
          </mat-radio-group>
        </form>
      </div>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form class="custom-form-field" [formGroup]="secondFormGroup">
        <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
          <!-- name -->
          <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
            <input
              matInput
              type="text"
              id="name"
              formControlName="name"
              [appDisableControl]="true"
              required
            />
            <mat-error>
              {{ getInputMessageError('name', secondFormGroup.get('name')?.errors) | translate }}
            </mat-error>
          </mat-form-field>

          <!-- reportFormat -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="
              firstFormGroup.get('type')?.value !== generateReportType.pdf ? 'none' : 'block'
            "
          >
            <mat-label>{{ trans.label.reportFormat | translate }}</mat-label>
            <mat-select
              #materialSelect
              id="reportFormat"
              name="reportFormat"
              formControlName="reportFormat"
              [appDisableControl]="
                firstFormGroup.get('type')?.value !== generateReportType.pdf || data.edit
              "
              (ngModelChange)="onChangeReportFormat()"
              required
            >
              <mat-option
                *ngFor="let reportFormatOption of availableFormatOptions"
                [value]="toUpperCase(reportFormatOption)"
              >
                {{ trans.text[reportFormatOption] | translate }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
                getInputMessageError('reportFormat', secondFormGroup.get('reportFormat')?.errors)
                  | translate
              }}
            </mat-error>
          </mat-form-field>

          <!-- period -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.period ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.period | translate }}</mat-label>
            <mat-select
              #materialSelect
              id="period"
              name="period"
              formControlName="period"
              [appDisableControl]="!this.enableField.period"
              required="this.secondFormGroup.get('reportFormat')?.value !== 'MAD'"
            >
              <mat-option *ngFor="let period of availablePeriodOptions" [value]="period">
                {{ period }} {{ trans.text.days | translate }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
                getInputMessageError('period', secondFormGroup.get('period')?.errors) | translate
              }}
            </mat-error>
          </mat-form-field>

          <!-- startDate -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.startDate ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.startDate | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="startPicker"
              id="startDate"
              formControlName="startDate"
              [max]="moment(applicationService.getServerDate().toDate()).subtract(1, 'd').toDate()"
              [appDisableControl]="!this.enableField.startDate"
              required="this.secondFormGroup.get('reportFormat')?.value !== 'MAD'"
            />
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error>
              {{
                getInputMessageError('startDate', secondFormGroup.get('startDate')?.errors)
                  | translate
              }}
            </mat-error>
          </mat-form-field>

          <!-- endDate -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.endDate ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.endDate | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="endPicker"
              id="endDate"
              [max]="moment(applicationService.getServerDate().toDate()).subtract(1, 'd').toDate()"
              [min]="secondFormGroup.get('startDate')?.value"
              [appDisableControl]="!this.enableField.endDate"
              formControlName="endDate"
              required="this.secondFormGroup.get('reportFormat')?.value !== 'MAD'"
            />
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error>
              {{
                getInputMessageError('endDate', secondFormGroup.get('endDate')?.errors) | translate
              }}
            </mat-error>
          </mat-form-field>

          <!-- crop -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.crop ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.crop | translate }}</mat-label>
            <mat-select
              #materialSelect
              id="cropId"
              name="cropId"
              formControlName="cropId"
              [appDisableControl]="!this.enableField.crop"
              (selectionChange)="onChangeCrop()"
            >
              <mat-option [value]="'ALL'">{{ trans.label.allCrops | translate }}</mat-option>
              <mat-option *ngFor="let crop of availableCropOptions" [value]="crop.id">
                {{ crop.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ getInputMessageError('crop', secondFormGroup.get('cropId')?.errors) | translate }}
            </mat-error>
          </mat-form-field>

          <!-- glebe -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.glebes ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.glebe | label }}</mat-label>
            <mat-select
              #materialSelect
              id="glebeIds"
              name="glebeIds"
              formControlName="glebeIds"
              multiple
              (selectionChange)="onChangeGlebe()"
              [appDisableControl]="
                this.secondFormGroup.get('reportFormat')?.value === 'MAD' ||
                ((!this.secondFormGroup.get('cropId')?.value || !this.enableField.glebes) &&
                  !this.data.edit)
              "
              required="this.secondFormGroup.get('reportFormat')?.value !== 'MAD'"
            >
              <mat-option
                class="material-select-option-message"
                *ngIf="availableGlebesOptions.length == 0"
                [disabled]="true"
                >{{ trans.text.noRegisteredGlebes | label }}</mat-option
              >
              <mat-option
                *ngIf="availableGlebesOptions.length > 0 && getGlebeIdsByCrop().length > 0"
                class="selectAll"
                (click)="selectAllGlebes()"
                >{{ trans.title.selectAll | translate }}</mat-option
              >
              <mat-option
                *ngIf="availableGlebesOptions.length > 0 && getGlebeIdsByCrop().length > 0"
                class="selectAll"
                (click)="deselectAllGlebes()"
                >{{ trans.title.deselectAll | translate }}</mat-option
              >
              <mat-optgroup *ngFor="let item of cropsSelected; let i = index" [label]="item.name">
                <mat-option
                  class="material-select-option-message"
                  *ngIf="false && getGlebesByCrop(item.id).length == 0"
                  [disabled]="true"
                >
                  {{ trans.text.noRegisteredGlebes | label }}</mat-option
                >
                <mat-option *ngFor="let glebe of getGlebesByCrop(item.id)" [value]="glebe.id">
                  <span>
                    {{ glebe.name }}
                    <span *ngIf="glebe.alias && glebe.alias !== ''"
                      >( {{ glebe.alias }} )</span
                    ></span
                  >
                  <span
                    style="float: right"
                    matTooltipClass="material-tooltip-class"
                    [matTooltip]="trans.text.harvestedDate | translate"
                    [matTooltipPosition]="'left'"
                    *ngIf="glebe.harvested"
                  >
                    {{
                      moment(glebe.finalHarvestDate.toDate()).format(trans.text.date | translate)
                    }}
                  </span>
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error>
              {{ getInputMessageError('glebe', secondFormGroup.get('glebeIds')?.errors) | label }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.glebe ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.glebe | label }}</mat-label>
            <mat-select
              #materialSelect
              id="glebeIds"
              name="glebeIds"
              formControlName="glebeId"
              (selectionChange)="onChangeGlebe()"
              [appDisableControl]="
                !this.secondFormGroup.get('cropId')?.value || !this.enableField.glebe || data.edit
              "
              required="this.secondFormGroup.get('reportFormat')?.value === 'MAD'"
            >
              <mat-option
                class="material-select-option-message"
                *ngIf="availableGlebesOptions.length == 0"
                [disabled]="true"
                >{{ trans.text.noRegisteredGlebes | label }}</mat-option
              >
              <mat-optgroup *ngFor="let item of getCropByIds(); let i = index" [label]="item.name">
                <mat-option
                  class="material-select-option-message"
                  *ngIf="false && getGlebesByCrop(item.id).length == 0"
                  [disabled]="true"
                >
                  {{ trans.text.noRegisteredGlebes | label }}</mat-option
                >
                <mat-option
                  *ngFor="let glebe of getGlebesByCrop(item.id)"
                  [disabled]="registeredGlebe(glebe.id)"
                  [value]="glebe.id"
                >
                  <span>
                    {{ glebe.name }}
                    <span *ngIf="glebe.alias && glebe.alias !== ''"
                      >( {{ glebe.alias }} )</span
                    ></span
                  >
                  <span
                    style="float: right"
                    matTooltipClass="material-tooltip-class"
                    [matTooltip]="trans.text.harvestedDate | translate"
                    [matTooltipPosition]="'left'"
                    *ngIf="glebe.harvested"
                  >
                    {{
                      moment(glebe.finalHarvestDate.toDate()).format(trans.text.date | translate)
                    }}
                  </span>
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error>
              {{ getInputMessageError('glebe', secondFormGroup.get('glebeId')?.errors) | label }}
            </mat-error>
          </mat-form-field>

          <!-- field -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.field ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.field | label }}</mat-label>
            <mat-select
              #materialSelect
              id="fieldIds"
              name="fieldIds"
              formControlName="fieldIds"
              [appDisableControl]="
                this.secondFormGroup.get('reportFormat')?.value === 'MAD' ||
                ((!this.secondFormGroup.get('cropId')?.value ||
                  this.secondFormGroup.get('reportFormat')?.value === 'MAD') &&
                  !this.data.edit)
              "
              required="this.secondFormGroup.get('reportFormat')?.value !== 'MAD'"
              multiple
            >
              <mat-option
                class="material-select-option-message"
                *ngIf="getGlebesByIds().length <= 0"
                [disabled]="true"
              >
                {{ trans.text.noRegisteredFields | label }}</mat-option
              >
              <mat-option
                *ngIf="getGlebesByIds().length > 0 && getFieldIdsByGlebe().length > 0"
                class="selectAll"
                (click)="selectAllFields()"
                >{{ trans.title.selectAll | translate }}</mat-option
              >
              <mat-option
                *ngIf="getGlebesByIds().length > 0 && getFieldIdsByGlebe().length > 0"
                class="selectAll"
                (click)="deselectAllFields()"
                >{{ trans.title.deselectAll | translate }}</mat-option
              >

              <mat-optgroup
                *ngFor="let item of getGlebesByIds(); let i = index"
                [label]="item.name"
              >
                <mat-option
                  class="material-select-option-message"
                  *ngIf="getFieldsByGlebe(item.id).length <= 0"
                  [disabled]="true"
                >
                  {{ trans.text.noRegisteredFields | label }}</mat-option
                >
                <mat-option
                  *ngFor="let field of getFieldsByCropEndGlebe(item.id)"
                  [value]="field.id"
                >
                  {{ field.name }}
                  <span *ngIf="field.alias && field.alias !== ''">( {{ field.alias }} )</span>
                  <span
                    style="float: right"
                    matTooltipClass="material-tooltip-class"
                    [matTooltip]="trans.text.harvestedDate | translate"
                    [matTooltipPosition]="'left'"
                    *ngIf="field.harvested"
                  >
                    {{ moment(field.harvestDate.toDate()).format(trans.text.date | translate) }}
                  </span>
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error>
              {{ getInputMessageError('field', secondFormGroup.get('fieldIds')?.errors) | label }}
            </mat-error>
          </mat-form-field>

          <!-- occurrence -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.occurrence ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.occurrence | translate }}</mat-label>
            <mat-select
              #materialSelect
              id="occurrenceIds"
              name="occurrenceIds"
              formControlName="occurrenceIds"
              [appDisableControl]="
                this.secondFormGroup.get('reportFormat')?.value === 'MAD' ||
                ((!this.secondFormGroup.get('cropId')?.value ||
                  this.secondFormGroup.get('reportFormat')?.value === 'MAD') &&
                  !this.data.edit)
              "
              multiple
              required="this.secondFormGroup.get('reportFormat')?.value !== 'MAD'"
            >
              <mat-option
                class="material-select-option-message"
                *ngIf="availableOccurrencesOptions.length == 0"
                [disabled]="true"
              >
                {{ trans.text.noRegisteredOccurrences | translate }}</mat-option
              >
              <mat-option
                *ngFor="let occurrence of availableOccurrencesOptions"
                [value]="occurrence.id"
              >
                {{ occurrence.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
                getInputMessageError('occurrence', secondFormGroup.get('occurrenceIds')?.errors)
                  | translate
              }}
            </mat-error>
          </mat-form-field>

          <!-- infestationLevel -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.infestation ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.infestationLevel | translate }}</mat-label>
            <mat-select
              #materialSelect
              id="infestationLevel"
              name="infestationLevel"
              formControlName="infestationLevel"
              [appDisableControl]="
                !this.enableField.infestation ||
                this.secondFormGroup.get('reportFormat')?.value === 'MAD'
              "
              required="this.secondFormGroup.get('reportFormat')?.value !== 'MAD'"
            >
              <mat-option
                *ngFor="let infestationLevel of infestationOptions"
                [value]="toUpperCase(infestationLevel)"
                >{{ trans.text[infestationLevel] | translate }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
                getInputMessageError(
                  'infestationLevel',
                  secondFormGroup.get('infestationLevel')?.errors
                ) | translate
              }}
            </mat-error>
          </mat-form-field>

          <!-- users -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.users ? 'block' : 'none'"
          >
            <mat-label>{{ trans.label.sendReport | translate }}</mat-label>
            <mat-select
              #materialSelect
              id="usersIds"
              name="usersIds"
              formControlName="usersIds"
              [appDisableControl]="firstFormGroup.get('type')?.value !== generateReportType.pdf"
              multiple
              required
            >
              <mat-option
                class="material-select-option-message"
                *ngIf="state.users.length <= 0"
                [disabled]="true"
              >
                {{ trans.text.noRegisteredUsers | translate }}</mat-option
              >
              <mat-option *ngFor="let user of state.users" [value]="user.id">
                {{ user.name }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{
                getInputMessageError('users', secondFormGroup.get('usersIds')?.errors) | translate
              }}
            </mat-error>
          </mat-form-field>

          <!-- alias -->
          <mat-form-field
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.alias ? 'block' : 'none'"
          >
            <input
              matInput
              type="text"
              id="alias"
              formControlName="alias"
              placeholder="{{ trans.placeholder.name | translate }}"
              minlength="2"
              maxlength="64"
              [pattern]="regex.text"
            />
            <mat-error>
              {{ getInputMessageError('alias', secondFormGroup.get('alias')?.errors) | translate }}
            </mat-error>
          </mat-form-field>

          <!-- send data -->
          <section
            fxFlex="100%"
            fxFlex.gt-xs="0 1 50%"
            [style.display]="this.enableField.sendData ? 'block' : 'none'"
          >
            <mat-checkbox color="primary" formControlName="sendOnlyData"
              >Enviar relatório somente quando houver dados</mat-checkbox
            >
          </section>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-container *ngIf="state?.response?.status === 'COMPLETED'; else elseTemplate">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="material-text-container tm-color-gray"
          *ngIf="firstFormGroup.get('type')?.value === generateReportType.xls"
        >
          <div><i class="material-icon-size las la-cloud-download-alt"></i></div>
          <h4>{{ trans.title.xlsCompleted | translate }}</h4>
          <p>{{ trans.text.xlsCompleted | translate }}</p>
          <button
            class="material-button-padding"
            mat-flat-button
            color="primary"
            (click)="exportXls()"
          >
            {{ trans.button.xls | translate }}
          </button>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="material-text-container tm-color-gray"
          *ngIf="firstFormGroup.get('type')?.value === generateReportType.pdf"
        >
          <div><i class="material-icon-size las la-cloud-download-alt"></i></div>
          <h4>{{ trans.title.pdfCompleted | translate }}</h4>
          <p>{{ trans.text.pdfCompleted | translate }}</p>
          <button
            *ngIf="getLinkToReportPdf()"
            class="material-button-padding"
            mat-flat-button
            color="primary"
            (click)="openPdf()"
          >
            {{ trans.button.pdf | translate }}
          </button>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="material-text-container tm-color-gray"
          *ngIf="firstFormGroup.get('type')?.value === generateReportType.pdf"
        >
          <div><i class="material-icon-size las la-smile"></i></div>
          <h4>{{ trans.title.pdfCreated | translate }}</h4>
          <p>{{ trans.text.pdfCreated | translate }}</p>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="material-text-container tm-color-gray"
          *ngIf="firstFormGroup.get('type')?.value === generateReportType.xls"
        >
          <div><i class="material-icon-size las la-smile"></i></div>
          <h4>{{ trans.title.xlsCreated | translate }}</h4>
          <p>{{ trans.text.xlsCreated | translate }}</p>
        </div>
      </ng-template>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="onCloseModal()">{{ trans.button.close | translate }}</button>
  <div>
    <button
      mat-button
      color="primary"
      (click)="stepper.previous()"
      *ngIf="stepper.selectedIndex === 1 && !data.edit"
    >
      {{ trans.button.back | translate }}
    </button>
    <button mat-button color="primary" (click)="onNext(stepper)" *ngIf="stepper.selectedIndex < 1">
      {{ trans.button.next | translate }}
    </button>
    <button mat-button color="primary" (click)="onFormClick()" *ngIf="stepper.selectedIndex === 1">
      {{ trans.button.save | translate }}
    </button>
    <button mat-button color="primary" (click)="onCloseModal()" *ngIf="stepper.selectedIndex > 1">
      {{ trans.button.ok | translate }}
    </button>
  </div>
</mat-dialog-actions>
