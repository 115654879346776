import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import {
  CREATE_FIELD,
  GET_FIELDS,
  GET_GLEBES,
  GET_HAS_TRAP_LINKED_FIELD,
  GET_HIGHEST_NUMBER_REGISTERED,
  GET_SYSTEM,
  HIDE_MODAL,
  UPDATE_FIELD,
} from 'src/app/view/field/field-form/field-form.actions';
import { TranslateTypes } from 'src/app/services/translation.service';
import { Area, Field, Glebe, Polygon } from '@tarvos-ag/tarvos-firestore-models/src/interfaces';
import { BaseForm } from '../../utils/base-form/base-form';
import {
  FieldFormState,
  fieldFormStateDefault,
  firstFormGroup,
  secondFormGroup,
} from './field-form.state';
import { ApplicationService } from 'src/app/services/application.service';
import {
  CreateFieldForm,
  FieldFormData,
  UpdateFieldForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/field';
import { FormService } from 'src/app/services/form.service';
import { MyToastrService } from 'src/app/services/toastr.service';
import { FieldIndex, HarvestIndex } from '@tarvos-ag/tarvos-firestore-models/src/utils/interfaces';
import { LabelPipe } from 'src/app/pipes/label.pipe';

@Component({
  selector: 'app-field-form',
  templateUrl: './field-form.component.html',
  styleUrls: ['./field-form.component.scss'],
})
export class FieldFormComponent extends BaseForm implements OnInit, OnDestroy {
  public polygonsByGlebe: Array<any> = [];

  public fieldState$: Observable<FieldFormState>;
  public state: FieldFormState = fieldFormStateDefault;
  public subscribe!: Subscription;

  public subscribeDataSharing!: Subscription;
  constructor(
    public formBuilder: FormBuilder,
    public store: Store<any>,
    public dialogRef: MatDialogRef<FieldFormComponent>,
    public dialog: MatDialog,
    public trans: TranslateTypes,
    private applicationService: ApplicationService,
    private formService: FormService,
    private toastrService: MyToastrService,
    private labelPipe: LabelPipe,

    @Inject(MAT_DIALOG_DATA) public data: { edit: boolean; field: Field }
  ) {
    super(store, dialogRef, formBuilder, trans);
    this.fieldState$ = this.store.pipe(select('fieldFormState'));
  }

  /**
   * Este método é executado quando o componente é inicializado
   */
  public ngOnInit(): void {
    this.store.dispatch(GET_GLEBES());
    this.store.dispatch(GET_FIELDS());
    this.store.dispatch(GET_SYSTEM());
    this.store.dispatch(GET_HIGHEST_NUMBER_REGISTERED());

    this.firstFormGroup = this.formBuilder.group(_.cloneDeep(firstFormGroup));
    this.secondFormGroup = this.formBuilder.group(_.cloneDeep(secondFormGroup));

    this.subscribe = this.fieldState$.subscribe((state: FieldFormState) => {
      this.state = state;

      if (state.closeModal) {
        this.onCloseModal();
      }

      if (state.lastCount >= 0 && !this.data.edit) {
        this.generateUniqueName();
      }
    });

    if (this.data.edit) {
      this.store.dispatch(GET_HAS_TRAP_LINKED_FIELD({ id: this.data.field.id }));

      this.firstFormGroup.patchValue({ ...this.data.field, glebeId: this.data.field.glebe.id });
      this.secondFormGroup.patchValue({
        area: this.data.field.area,
        polygon: this.data.field.polygon,
      });
    }
  }

  /**
   * Este método executa quando o componentes não é mais ativo
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método fecha uma modal
   */
  public onCloseModal(): void {
    this.closeModal(HIDE_MODAL());
  }

  /**
   * Este método valida e cadastrar ou atualiza
   */
  public onFormClick(): void {
    if (this.secondFormGroup.invalid) {
      this.checkValidateForms(this.secondFormGroup);
    } else {
      const secondFormValue = this.secondFormGroup.getRawValue();
      this.firstFormGroup.patchValue({
        area: secondFormValue.area,
        polygon: secondFormValue.polygon,
      });
      let action;
      if (this.data.edit) {
        const field = this.generatesUpdateFieldForm();

        if (Object.values(field.fieldData).length <= 0) {
          return this.toastrService.error(
            this.labelPipe.transform(this.trans.text.noFieldsChanged)
          );
        }
        action = UPDATE_FIELD({ field });
      } else {
        const field = this.generatesCreateFieldForm();
        action = CREATE_FIELD({ field });
      }
      this.onSubmit(action);
    }
  }

  /**
   * Este método muda o formulário para o passo 2;
   * @param stepper Referência do component stepper
   */
  public onNext(stepper: MatStepper): void {
    /**
     * Validar o componente de forma manual pois não é um formulário nativo
     */
    if (stepper.selected?.stepControl.invalid && stepper.selectedIndex === 1) {
      this.checkValidateForms(this.secondFormGroup);
    }
    stepper.next();
  }

  /**
   * Este método gera o nome dos talhões
   */
  public generateUniqueName(): void {
    this.firstFormGroup
      .get('name')
      ?.setValue(`T${String(this.state.lastCount + 1).padStart(2, '0')}`);
  }

  /**
   * Este método é executado quando uma gleba e selecionada
   * @param glebe gleba que foi selecionada
   */
  public onSelectionChangeGlebe(glebe: Glebe): void {
    if (!this.data.edit) this.getPolygonsByGlebe(glebe.id);
  }

  /**
   * Este método posiciona um polígono a salva seu lat lng;
   * @param event latLng do polígono
   */
  public onDrawEndPolygon(event: any): void {
    const polygon = { points: event.polygon } as Polygon;
    const area: Area = { hectares: parseFloat((event.meter / 10000).toFixed(8)) };

    this.secondFormGroup.get('area')?.setValue(area);
    this.secondFormGroup.get('polygon')?.setValue(polygon);
    this.secondFormGroup.markAsDirty();
  }

  /**
   * Este método retorna uma lista com os polígonos de uma gleba;
   */
  public getPolygonsByGlebe(glebeId: string): void {
    const polygons: Array<any> = [];
    this.state.fields.forEach((field) => {
      if (glebeId === field.glebe.id) {
        polygons.push(field.polygon);
      }
    });
    this.polygonsByGlebe = polygons;
  }

  private generatesCreateFieldForm(): CreateFieldForm {
    const form: FieldFormData = this.firstFormGroup.getRawValue();
    const parameters: HarvestIndex = {
      customerId: this.applicationService.getCustomerId(),
      farmId: this.applicationService.getFarmId(),
      harvestId: this.applicationService.getHarvestId(),
    };
    return { ...parameters, fieldData: form };
  }

  private generatesUpdateFieldForm(): UpdateFieldForm {
    const form: FieldFormData = this.firstFormGroup.getRawValue();
    const parameters: FieldIndex = {
      customerId: this.applicationService.getCustomerId(),
      farmId: this.applicationService.getFarmId(),
      harvestId: this.applicationService.getHarvestId(),
      fieldId: this.data.field.id,
    };

    const fieldFormBeforeEdit = { alias: this.data.field.alias, glebeId: this.data.field.glebe.id };

    const fieldFormAfterEdit: UpdateFieldForm['fieldData'] = {
      alias: form.alias || null,
      glebeId: form.glebeId || null,
    };

    const fieldData = this.formService.getDifferences(fieldFormBeforeEdit, fieldFormAfterEdit);
    return { ...parameters, fieldData };
  }
}
