import { StateAbbreviation, StatesAndCities } from '../interfaces/StatesAndCities';

export const StatesAndCitiesConst: StatesAndCities = [
    {
        abbreviation: StateAbbreviation.GO,
        name: 'Goiás',
        code: '52',
        cities: [
            { name: 'Abadia de Goiás', code: '00050' },
            { name: 'Abadiânia', code: '00100' },
            { name: 'Acreúna', code: '00134' },
            { name: 'Adelândia', code: '00159' },
            { name: 'Água Fria de Goiás', code: '00175' },
            { name: 'Água Limpa', code: '00209' },
            { name: 'Águas Lindas de Goiás', code: '00258' },
            { name: 'Alexânia', code: '00308' },
            { name: 'Aloândia', code: '00506' },
            { name: 'Alto Horizonte', code: '00555' },
            { name: 'Alto Paraíso de Goiás', code: '00605' },
            { name: 'Alvorada do Norte', code: '00803' },
            { name: 'Amaralina', code: '00829' },
            { name: 'Americano do Brasil', code: '00852' },
            { name: 'Amorinópolis', code: '00902' },
            { name: 'Anápolis', code: '01108' },
            { name: 'Anhanguera', code: '01207' },
            { name: 'Anicuns', code: '01306' },
            { name: 'Aparecida de Goiânia', code: '01405' },
            { name: 'Aparecida do Rio Doce', code: '01454' },
            { name: 'Aporé', code: '01504' },
            { name: 'Araçu', code: '01603' },
            { name: 'Aragarças', code: '01702' },
            { name: 'Aragoiânia', code: '01801' },
            { name: 'Araguapaz', code: '02155' },
            { name: 'Arenópolis', code: '02353' },
            { name: 'Aruanã', code: '02502' },
            { name: 'Aurilândia', code: '02601' },
            { name: 'Avelinópolis', code: '02809' },
            { name: 'Baliza', code: '03104' },
            { name: 'Barro Alto', code: '03203' },
            { name: 'Bela Vista de Goiás', code: '03302' },
            { name: 'Bom Jardim de Goiás', code: '03401' },
            { name: 'Bom Jesus de Goiás', code: '03500' },
            { name: 'Bonfinópolis', code: '03559' },
            { name: 'Bonópolis', code: '03575' },
            { name: 'Brazabrantes', code: '03609' },
            { name: 'Britânia', code: '03807' },
            { name: 'Buriti Alegre', code: '03906' },
            { name: 'Buriti de Goiás', code: '03939' },
            { name: 'Buritinópolis', code: '03962' },
            { name: 'Cabeceiras', code: '04003' },
            { name: 'Cachoeira Alta', code: '04102' },
            { name: 'Cachoeira de Goiás', code: '04201' },
            { name: 'Cachoeira Dourada', code: '04250' },
            { name: 'Caçu', code: '04300' },
            { name: 'Caiapônia', code: '04409' },
            { name: 'Caldas Novas', code: '04508' },
            { name: 'Caldazinha', code: '04557' },
            { name: 'Campestre de Goiás', code: '04607' },
            { name: 'Campinaçu', code: '04656' },
            { name: 'Campinorte', code: '04706' },
            { name: 'Campo Alegre de Goiás', code: '04805' },
            { name: 'Campo Limpo de Goiás', code: '04854' },
            { name: 'Campos Belos', code: '04904' },
            { name: 'Campos Verdes', code: '04953' },
            { name: 'Carmo do Rio Verde', code: '05000' },
            { name: 'Castelândia', code: '05059' },
            { name: 'Catalão', code: '05109' },
            { name: 'Caturaí', code: '05208' },
            { name: 'Cavalcante', code: '05307' },
            { name: 'Ceres', code: '05406' },
            { name: 'Cezarina', code: '05455' },
            { name: 'Chapadão do Céu', code: '05471' },
            { name: 'Cidade Ocidental', code: '05497' },
            { name: 'Cocalzinho de Goiás', code: '05513' },
            { name: 'Colinas do Sul', code: '05521' },
            { name: 'Córrego do Ouro', code: '05703' },
            { name: 'Corumbá de Goiás', code: '05802' },
            { name: 'Corumbaíba', code: '05901' },
            { name: 'Cristalina', code: '06206' },
            { name: 'Cristianópolis', code: '06305' },
            { name: 'Crixás', code: '06404' },
            { name: 'Cromínia', code: '06503' },
            { name: 'Cumari', code: '06602' },
            { name: 'Damianópolis', code: '06701' },
            { name: 'Damolândia', code: '06800' },
            { name: 'Davinópolis', code: '06909' },
            { name: 'Diorama', code: '07105' },
            { name: 'Divinópolis de Goiás', code: '08301' },
            { name: 'Doverlândia', code: '07253' },
            { name: 'Edealina', code: '07352' },
            { name: 'Edéia', code: '07402' },
            { name: 'Estrela do Norte', code: '07501' },
            { name: 'Faina', code: '07535' },
            { name: 'Fazenda Nova', code: '07600' },
            { name: 'Firminópolis', code: '07808' },
            { name: 'Flores de Goiás', code: '07907' },
            { name: 'Formosa', code: '08004' },
            { name: 'Formoso', code: '08103' },
            { name: 'Gameleira de Goiás', code: '08152' },
            { name: 'Goianápolis', code: '08400' },
            { name: 'Goiandira', code: '08509' },
            { name: 'Goianésia', code: '08608' },
            { name: 'Goiânia', code: '08707' },
            { name: 'Goianira', code: '08806' },
            { name: 'Goiás', code: '08905' },
            { name: 'Goiatuba', code: '09101' },
            { name: 'Gouvelândia', code: '09150' },
            { name: 'Guapó', code: '09200' },
            { name: 'Guaraíta', code: '09291' },
            { name: 'Guarani de Goiás', code: '09408' },
            { name: 'Guarinos', code: '09457' },
            { name: 'Heitoraí', code: '09606' },
            { name: 'Hidrolândia', code: '09705' },
            { name: 'Hidrolina', code: '09804' },
            { name: 'Iaciara', code: '09903' },
            { name: 'Inaciolândia', code: '09937' },
            { name: 'Indiara', code: '09952' },
            { name: 'Inhumas', code: '10000' },
            { name: 'Ipameri', code: '10109' },
            { name: 'Ipiranga de Goiás', code: '10158' },
            { name: 'Iporá', code: '10208' },
            { name: 'Israelândia', code: '10307' },
            { name: 'Itaberaí', code: '10406' },
            { name: 'Itaguari', code: '10562' },
            { name: 'Itaguaru', code: '10604' },
            { name: 'Itajá', code: '10802' },
            { name: 'Itapaci', code: '10901' },
            { name: 'Itapirapuã', code: '11008' },
            { name: 'Itapuranga', code: '11206' },
            { name: 'Itarumã', code: '11305' },
            { name: 'Itauçu', code: '11404' },
            { name: 'Itumbiara', code: '11503' },
            { name: 'Ivolândia', code: '11602' },
            { name: 'Jandaia', code: '11701' },
            { name: 'Jaraguá', code: '11800' },
            { name: 'Jataí', code: '11909' },
            { name: 'Jaupaci', code: '12006' },
            { name: 'Jesúpolis', code: '12055' },
            { name: 'Joviânia', code: '12105' },
            { name: 'Jussara', code: '12204' },
            { name: 'Lagoa Santa', code: '12253' },
            { name: 'Leopoldo de Bulhões', code: '12303' },
            { name: 'Luziânia', code: '12501' },
            { name: 'Mairipotaba', code: '12600' },
            { name: 'Mambaí', code: '12709' },
            { name: 'Mara Rosa', code: '12808' },
            { name: 'Marzagão', code: '12907' },
            { name: 'Matrinchã', code: '12956' },
            { name: 'Maurilândia', code: '13004' },
            { name: 'Mimoso de Goiás', code: '13053' },
            { name: 'Minaçu', code: '13087' },
            { name: 'Mineiros', code: '13103' },
            { name: 'Moiporá', code: '13400' },
            { name: 'Monte Alegre de Goiás', code: '13509' },
            { name: 'Montes Claros de Goiás', code: '13707' },
            { name: 'Montividiu', code: '13756' },
            { name: 'Montividiu do Norte', code: '13772' },
            { name: 'Morrinhos', code: '13806' },
            { name: 'Morro Agudo de Goiás', code: '13855' },
            { name: 'Mossâmedes', code: '13905' },
            { name: 'Mozarlândia', code: '14002' },
            { name: 'Mundo Novo', code: '14051' },
            { name: 'Mutunópolis', code: '14101' },
            { name: 'Nazário', code: '14408' },
            { name: 'Nerópolis', code: '14507' },
            { name: 'Niquelândia', code: '14606' },
            { name: 'Nova América', code: '14705' },
            { name: 'Nova Aurora', code: '14804' },
            { name: 'Nova Crixás', code: '14838' },
            { name: 'Nova Glória', code: '14861' },
            { name: 'Nova Iguaçu de Goiás', code: '14879' },
            { name: 'Nova Roma', code: '14903' },
            { name: 'Nova Veneza', code: '15009' },
            { name: 'Novo Brasil', code: '15207' },
            { name: 'Novo Gama', code: '15231' },
            { name: 'Novo Planalto', code: '15256' },
            { name: 'Orizona', code: '15306' },
            { name: 'Ouro Verde de Goiás', code: '15405' },
            { name: 'Ouvidor', code: '15504' },
            { name: 'Padre Bernardo', code: '15603' },
            { name: 'Palestina de Goiás', code: '15652' },
            { name: 'Palmeiras de Goiás', code: '15702' },
            { name: 'Palmelo', code: '15801' },
            { name: 'Palminópolis', code: '15900' },
            { name: 'Panamá', code: '16007' },
            { name: 'Paranaiguara', code: '16304' },
            { name: 'Paraúna', code: '16403' },
            { name: 'Perolândia', code: '16452' },
            { name: 'Petrolina de Goiás', code: '16809' },
            { name: 'Pilar de Goiás', code: '16908' },
            { name: 'Piracanjuba', code: '17104' },
            { name: 'Piranhas', code: '17203' },
            { name: 'Pirenópolis', code: '17302' },
            { name: 'Pires do Rio', code: '17401' },
            { name: 'Planaltina', code: '17609' },
            { name: 'Pontalina', code: '17708' },
            { name: 'Porangatu', code: '18003' },
            { name: 'Porteirão', code: '18052' },
            { name: 'Portelândia', code: '18102' },
            { name: 'Posse', code: '18300' },
            { name: 'Professor Jamil', code: '18391' },
            { name: 'Quirinópolis', code: '18508' },
            { name: 'Rialma', code: '18607' },
            { name: 'Rianápolis', code: '18706' },
            { name: 'Rio Quente', code: '18789' },
            { name: 'Rio Verde', code: '18805' },
            { name: 'Rubiataba', code: '18904' },
            { name: 'Sanclerlândia', code: '19001' },
            { name: 'Santa Bárbara de Goiás', code: '19100' },
            { name: 'Santa Cruz de Goiás', code: '19209' },
            { name: 'Santa Fé de Goiás', code: '19258' },
            { name: 'Santa Helena de Goiás', code: '19308' },
            { name: 'Santa Isabel', code: '19357' },
            { name: 'Santa Rita do Araguaia', code: '19407' },
            { name: 'Santa Rita do Novo Destino', code: '19456' },
            { name: 'Santa Rosa de Goiás', code: '19506' },
            { name: 'Santa Tereza de Goiás', code: '19605' },
            { name: 'Santa Terezinha de Goiás', code: '19704' },
            { name: 'Santo Antônio da Barra', code: '19712' },
            { name: 'Santo Antônio de Goiás', code: '19738' },
            { name: 'Santo Antônio do Descoberto', code: '19753' },
            { name: 'São Domingos', code: '19803' },
            { name: 'São Francisco de Goiás', code: '19902' },
            { name: 'São João da Paraúna', code: '20058' },
            { name: "São João d'Aliança", code: '20009' },
            { name: 'São Luís de Montes Belos', code: '20108' },
            { name: 'São Luiz do Norte', code: '20157' },
            { name: 'São Miguel do Araguaia', code: '20207' },
            { name: 'São Miguel do Passa Quatro', code: '20264' },
            { name: 'São Patrício', code: '20280' },
            { name: 'São Simão', code: '20405' },
            { name: 'Senador Canedo', code: '20454' },
            { name: 'Serranópolis', code: '20504' },
            { name: 'Silvânia', code: '20603' },
            { name: 'Simolândia', code: '20686' },
            { name: "Sítio d'Abadia", code: '20702' },
            { name: 'Taquaral de Goiás', code: '21007' },
            { name: 'Teresina de Goiás', code: '21080' },
            { name: 'Terezópolis de Goiás', code: '21197' },
            { name: 'Três Ranchos', code: '21304' },
            { name: 'Trindade', code: '21403' },
            { name: 'Trombas', code: '21452' },
            { name: 'Turvânia', code: '21502' },
            { name: 'Turvelândia', code: '21551' },
            { name: 'Uirapuru', code: '21577' },
            { name: 'Uruaçu', code: '21601' },
            { name: 'Uruana', code: '21700' },
            { name: 'Urutaí', code: '21809' },
            { name: 'Valparaíso de Goiás', code: '21858' },
            { name: 'Varjão', code: '21908' },
            { name: 'Vianópolis', code: '22005' },
            { name: 'Vicentinópolis', code: '22054' },
            { name: 'Vila Boa', code: '22203' },
            { name: 'Vila Propício', code: '22302' },
        ],
    },
    {
        abbreviation: StateAbbreviation.MG,
        name: 'Minas Gerais',
        code: '31',
        cities: [
            { name: 'Abadia dos Dourados', code: '00104' },
            { name: 'Abaeté', code: '00203' },
            { name: 'Abre Campo', code: '00302' },
            { name: 'Acaiaca', code: '00401' },
            { name: 'Açucena', code: '00500' },
            { name: 'Água Boa', code: '00609' },
            { name: 'Água Comprida', code: '00708' },
            { name: 'Aguanil', code: '00807' },
            { name: 'Águas Formosas', code: '00906' },
            { name: 'Águas Vermelhas', code: '01003' },
            { name: 'Aimorés', code: '01102' },
            { name: 'Aiuruoca', code: '01201' },
            { name: 'Alagoa', code: '01300' },
            { name: 'Albertina', code: '01409' },
            { name: 'Além Paraíba', code: '01508' },
            { name: 'Alfenas', code: '01607' },
            { name: 'Alfredo Vasconcelos', code: '01631' },
            { name: 'Almenara', code: '01706' },
            { name: 'Alpercata', code: '01805' },
            { name: 'Alpinópolis', code: '01904' },
            { name: 'Alterosa', code: '02001' },
            { name: 'Alto Caparaó', code: '02050' },
            { name: 'Alto Jequitibá', code: '53509' },
            { name: 'Alto Rio Doce', code: '02100' },
            { name: 'Alvarenga', code: '02209' },
            { name: 'Alvinópolis', code: '02308' },
            { name: 'Alvorada de Minas', code: '02407' },
            { name: 'Amparo do Serra', code: '02506' },
            { name: 'Andradas', code: '02605' },
            { name: 'Andrelândia', code: '02803' },
            { name: 'Angelândia', code: '02852' },
            { name: 'Antônio Carlos', code: '02902' },
            { name: 'Antônio Dias', code: '03009' },
            { name: 'Antônio Prado de Minas', code: '03108' },
            { name: 'Araçaí', code: '03207' },
            { name: 'Aracitaba', code: '03306' },
            { name: 'Araçuaí', code: '03405' },
            { name: 'Araguari', code: '03504' },
            { name: 'Arantina', code: '03603' },
            { name: 'Araponga', code: '03702' },
            { name: 'Araporã', code: '03751' },
            { name: 'Arapuá', code: '03801' },
            { name: 'Araújos', code: '03900' },
            { name: 'Araxá', code: '04007' },
            { name: 'Arceburgo', code: '04106' },
            { name: 'Arcos', code: '04205' },
            { name: 'Areado', code: '04304' },
            { name: 'Argirita', code: '04403' },
            { name: 'Aricanduva', code: '04452' },
            { name: 'Arinos', code: '04502' },
            { name: 'Astolfo Dutra', code: '04601' },
            { name: 'Ataléia', code: '04700' },
            { name: 'Augusto de Lima', code: '04809' },
            { name: 'Baependi', code: '04908' },
            { name: 'Baldim', code: '05004' },
            { name: 'Bambuí', code: '05103' },
            { name: 'Bandeira', code: '05202' },
            { name: 'Bandeira do Sul', code: '05301' },
            { name: 'Barão de Cocais', code: '05400' },
            { name: 'Barão de Monte Alto', code: '05509' },
            { name: 'Barbacena', code: '05608' },
            { name: 'Barra Longa', code: '05707' },
            { name: 'Barroso', code: '05905' },
            { name: 'Bela Vista de Minas', code: '06002' },
            { name: 'Belmiro Braga', code: '06101' },
            { name: 'Belo Horizonte', code: '06200' },
            { name: 'Belo Oriente', code: '06309' },
            { name: 'Belo Vale', code: '06408' },
            { name: 'Berilo', code: '06507' },
            { name: 'Berizal', code: '06655' },
            { name: 'Bertópolis', code: '06606' },
            { name: 'Betim', code: '06705' },
            { name: 'Bias Fortes', code: '06804' },
            { name: 'Bicas', code: '06903' },
            { name: 'Biquinhas', code: '07000' },
            { name: 'Boa Esperança', code: '07109' },
            { name: 'Bocaina de Minas', code: '07208' },
            { name: 'Bocaiúva', code: '07307' },
            { name: 'Bom Despacho', code: '07406' },
            { name: 'Bom Jardim de Minas', code: '07505' },
            { name: 'Bom Jesus da Penha', code: '07604' },
            { name: 'Bom Jesus do Amparo', code: '07703' },
            { name: 'Bom Jesus do Galho', code: '07802' },
            { name: 'Bom Repouso', code: '07901' },
            { name: 'Bom Sucesso', code: '08008' },
            { name: 'Bonfim', code: '08107' },
            { name: 'Bonfinópolis de Minas', code: '08206' },
            { name: 'Bonito de Minas', code: '08255' },
            { name: 'Borda da Mata', code: '08305' },
            { name: 'Botelhos', code: '08404' },
            { name: 'Botumirim', code: '08503' },
            { name: 'Brás Pires', code: '08701' },
            { name: 'Brasilândia de Minas', code: '08552' },
            { name: 'Brasília de Minas', code: '08602' },
            { name: 'Braúnas', code: '08800' },
            { name: 'Brazópolis', code: '08909' },
            { name: 'Brumadinho', code: '09006' },
            { name: 'Bueno Brandão', code: '09105' },
            { name: 'Buenópolis', code: '09204' },
            { name: 'Bugre', code: '09253' },
            { name: 'Buritis', code: '09303' },
            { name: 'Buritizeiro', code: '09402' },
            { name: 'Cabeceira Grande', code: '09451' },
            { name: 'Cabo Verde', code: '09501' },
            { name: 'Cachoeira da Prata', code: '09600' },
            { name: 'Cachoeira de Minas', code: '09709' },
            { name: 'Cachoeira de Pajeú', code: '02704' },
            { name: 'Cachoeira Dourada', code: '09808' },
            { name: 'Caetanópolis', code: '09907' },
            { name: 'Caeté', code: '10004' },
            { name: 'Caiana', code: '10103' },
            { name: 'Cajuri', code: '10202' },
            { name: 'Caldas', code: '10301' },
            { name: 'Camacho', code: '10400' },
            { name: 'Camanducaia', code: '10509' },
            { name: 'Cambuí', code: '10608' },
            { name: 'Cambuquira', code: '10707' },
            { name: 'Campanário', code: '10806' },
            { name: 'Campanha', code: '10905' },
            { name: 'Campestre', code: '11002' },
            { name: 'Campina Verde', code: '11101' },
            { name: 'Campo Azul', code: '11150' },
            { name: 'Campo Belo', code: '11200' },
            { name: 'Campo do Meio', code: '11309' },
            { name: 'Campo Florido', code: '11408' },
            { name: 'Campos Altos', code: '11507' },
            { name: 'Campos Gerais', code: '11606' },
            { name: 'Cana Verde', code: '11903' },
            { name: 'Canaã', code: '11705' },
            { name: 'Canápolis', code: '11804' },
            { name: 'Candeias', code: '12000' },
            { name: 'Cantagalo', code: '12059' },
            { name: 'Caparaó', code: '12109' },
            { name: 'Capela Nova', code: '12208' },
            { name: 'Capelinha', code: '12307' },
            { name: 'Capetinga', code: '12406' },
            { name: 'Capim Branco', code: '12505' },
            { name: 'Capinópolis', code: '12604' },
            { name: 'Capitão Andrade', code: '12653' },
            { name: 'Capitão Enéas', code: '12703' },
            { name: 'Capitólio', code: '12802' },
            { name: 'Caputira', code: '12901' },
            { name: 'Caraí', code: '13008' },
            { name: 'Caranaíba', code: '13107' },
            { name: 'Carandaí', code: '13206' },
            { name: 'Carangola', code: '13305' },
            { name: 'Caratinga', code: '13404' },
            { name: 'Carbonita', code: '13503' },
            { name: 'Careaçu', code: '13602' },
            { name: 'Carlos Chagas', code: '13701' },
            { name: 'Carmésia', code: '13800' },
            { name: 'Carmo da Cachoeira', code: '13909' },
            { name: 'Carmo da Mata', code: '14006' },
            { name: 'Carmo de Minas', code: '14105' },
            { name: 'Carmo do Cajuru', code: '14204' },
            { name: 'Carmo do Paranaíba', code: '14303' },
            { name: 'Carmo do Rio Claro', code: '14402' },
            { name: 'Carmópolis de Minas', code: '14501' },
            { name: 'Carneirinho', code: '14550' },
            { name: 'Carrancas', code: '14600' },
            { name: 'Carvalhópolis', code: '14709' },
            { name: 'Carvalhos', code: '14808' },
            { name: 'Casa Grande', code: '14907' },
            { name: 'Cascalho Rico', code: '15003' },
            { name: 'Cássia', code: '15102' },
            { name: 'Cataguases', code: '15300' },
            { name: 'Catas Altas', code: '15359' },
            { name: 'Catas Altas da Noruega', code: '15409' },
            { name: 'Catuji', code: '15458' },
            { name: 'Catuti', code: '15474' },
            { name: 'Caxambu', code: '15508' },
            { name: 'Cedro do Abaeté', code: '15607' },
            { name: 'Central de Minas', code: '15706' },
            { name: 'Centralina', code: '15805' },
            { name: 'Chácara', code: '15904' },
            { name: 'Chalé', code: '16001' },
            { name: 'Chapada do Norte', code: '16100' },
            { name: 'Chapada Gaúcha', code: '16159' },
            { name: 'Chiador', code: '16209' },
            { name: 'Cipotânea', code: '16308' },
            { name: 'Claraval', code: '16407' },
            { name: 'Claro dos Poções', code: '16506' },
            { name: 'Cláudio', code: '16605' },
            { name: 'Coimbra', code: '16704' },
            { name: 'Coluna', code: '16803' },
            { name: 'Comendador Gomes', code: '16902' },
            { name: 'Comercinho', code: '17009' },
            { name: 'Conceição da Aparecida', code: '17108' },
            { name: 'Conceição da Barra de Minas', code: '15201' },
            { name: 'Conceição das Alagoas', code: '17306' },
            { name: 'Conceição das Pedras', code: '17207' },
            { name: 'Conceição de Ipanema', code: '17405' },
            { name: 'Conceição do Mato Dentro', code: '17504' },
            { name: 'Conceição do Pará', code: '17603' },
            { name: 'Conceição do Rio Verde', code: '17702' },
            { name: 'Conceição dos Ouros', code: '17801' },
            { name: 'Cônego Marinho', code: '17836' },
            { name: 'Confins', code: '17876' },
            { name: 'Congonhal', code: '17900' },
            { name: 'Congonhas', code: '18007' },
            { name: 'Congonhas do Norte', code: '18106' },
            { name: 'Conquista', code: '18205' },
            { name: 'Conselheiro Lafaiete', code: '18304' },
            { name: 'Conselheiro Pena', code: '18403' },
            { name: 'Consolação', code: '18502' },
            { name: 'Contagem', code: '18601' },
            { name: 'Coqueiral', code: '18700' },
            { name: 'Coração de Jesus', code: '18809' },
            { name: 'Cordisburgo', code: '18908' },
            { name: 'Cordislândia', code: '19005' },
            { name: 'Corinto', code: '19104' },
            { name: 'Coroaci', code: '19203' },
            { name: 'Coromandel', code: '19302' },
            { name: 'Coronel Fabriciano', code: '19401' },
            { name: 'Coronel Murta', code: '19500' },
            { name: 'Coronel Pacheco', code: '19609' },
            { name: 'Coronel Xavier Chaves', code: '19708' },
            { name: 'Córrego Danta', code: '19807' },
            { name: 'Córrego do Bom Jesus', code: '19906' },
            { name: 'Córrego Fundo', code: '19955' },
            { name: 'Córrego Novo', code: '20003' },
            { name: 'Couto de Magalhães de Minas', code: '20102' },
            { name: 'Crisólita', code: '20151' },
            { name: 'Cristais', code: '20201' },
            { name: 'Cristália', code: '20300' },
            { name: 'Cristiano Otoni', code: '20409' },
            { name: 'Cristina', code: '20508' },
            { name: 'Crucilândia', code: '20607' },
            { name: 'Cruzeiro da Fortaleza', code: '20706' },
            { name: 'Cruzília', code: '20805' },
            { name: 'Cuparaque', code: '20839' },
            { name: 'Curral de Dentro', code: '20870' },
            { name: 'Curvelo', code: '20904' },
            { name: 'Datas', code: '21001' },
            { name: 'Delfim Moreira', code: '21100' },
            { name: 'Delfinópolis', code: '21209' },
            { name: 'Delta', code: '21258' },
            { name: 'Descoberto', code: '21308' },
            { name: 'Desterro de Entre Rios', code: '21407' },
            { name: 'Desterro do Melo', code: '21506' },
            { name: 'Diamantina', code: '21605' },
            { name: 'Diogo de Vasconcelos', code: '21704' },
            { name: 'Dionísio', code: '21803' },
            { name: 'Divinésia', code: '21902' },
            { name: 'Divino', code: '22009' },
            { name: 'Divino das Laranjeiras', code: '22108' },
            { name: 'Divinolândia de Minas', code: '22207' },
            { name: 'Divinópolis', code: '22306' },
            { name: 'Divisa Alegre', code: '22355' },
            { name: 'Divisa Nova', code: '22405' },
            { name: 'Divisópolis', code: '22454' },
            { name: 'Dom Bosco', code: '22470' },
            { name: 'Dom Cavati', code: '22504' },
            { name: 'Dom Joaquim', code: '22603' },
            { name: 'Dom Silvério', code: '22702' },
            { name: 'Dom Viçoso', code: '22801' },
            { name: 'Dona Euzébia', code: '22900' },
            { name: 'Dores de Campos', code: '23007' },
            { name: 'Dores de Guanhães', code: '23106' },
            { name: 'Dores do Indaiá', code: '23205' },
            { name: 'Dores do Turvo', code: '23304' },
            { name: 'Doresópolis', code: '23403' },
            { name: 'Douradoquara', code: '23502' },
            { name: 'Durandé', code: '23528' },
            { name: 'Elói Mendes', code: '23601' },
            { name: 'Engenheiro Caldas', code: '23700' },
            { name: 'Engenheiro Navarro', code: '23809' },
            { name: 'Entre Folhas', code: '23858' },
            { name: 'Entre Rios de Minas', code: '23908' },
            { name: 'Ervália', code: '24005' },
            { name: 'Esmeraldas', code: '24104' },
            { name: 'Espera Feliz', code: '24203' },
            { name: 'Espinosa', code: '24302' },
            { name: 'Espírito Santo do Dourado', code: '24401' },
            { name: 'Estiva', code: '24500' },
            { name: 'Estrela Dalva', code: '24609' },
            { name: 'Estrela do Indaiá', code: '24708' },
            { name: 'Estrela do Sul', code: '24807' },
            { name: 'Eugenópolis', code: '24906' },
            { name: 'Ewbank da Câmara', code: '25002' },
            { name: 'Extrema', code: '25101' },
            { name: 'Fama', code: '25200' },
            { name: 'Faria Lemos', code: '25309' },
            { name: 'Felício dos Santos', code: '25408' },
            { name: 'Felisburgo', code: '25606' },
            { name: 'Felixlândia', code: '25705' },
            { name: 'Fernandes Tourinho', code: '25804' },
            { name: 'Ferros', code: '25903' },
            { name: 'Fervedouro', code: '25952' },
            { name: 'Florestal', code: '26000' },
            { name: 'Formiga', code: '26109' },
            { name: 'Formoso', code: '26208' },
            { name: 'Fortaleza de Minas', code: '26307' },
            { name: 'Fortuna de Minas', code: '26406' },
            { name: 'Francisco Badaró', code: '26505' },
            { name: 'Francisco Dumont', code: '26604' },
            { name: 'Francisco Sá', code: '26703' },
            { name: 'Franciscópolis', code: '26752' },
            { name: 'Frei Gaspar', code: '26802' },
            { name: 'Frei Inocêncio', code: '26901' },
            { name: 'Frei Lagonegro', code: '26950' },
            { name: 'Fronteira', code: '27008' },
            { name: 'Fronteira dos Vales', code: '27057' },
            { name: 'Fruta de Leite', code: '27073' },
            { name: 'Frutal', code: '27107' },
            { name: 'Funilândia', code: '27206' },
            { name: 'Galiléia', code: '27305' },
            { name: 'Gameleiras', code: '27339' },
            { name: 'Glaucilândia', code: '27354' },
            { name: 'Goiabeira', code: '27370' },
            { name: 'Goianá', code: '27388' },
            { name: 'Gonçalves', code: '27404' },
            { name: 'Gonzaga', code: '27503' },
            { name: 'Gouveia', code: '27602' },
            { name: 'Governador Valadares', code: '27701' },
            { name: 'Grão Mogol', code: '27800' },
            { name: 'Grupiara', code: '27909' },
            { name: 'Guanhães', code: '28006' },
            { name: 'Guapé', code: '28105' },
            { name: 'Guaraciaba', code: '28204' },
            { name: 'Guaraciama', code: '28253' },
            { name: 'Guaranésia', code: '28303' },
            { name: 'Guarani', code: '28402' },
            { name: 'Guarará', code: '28501' },
            { name: 'Guarda-Mor', code: '28600' },
            { name: 'Guaxupé', code: '28709' },
            { name: 'Guidoval', code: '28808' },
            { name: 'Guimarânia', code: '28907' },
            { name: 'Guiricema', code: '29004' },
            { name: 'Gurinhatã', code: '29103' },
            { name: 'Heliodora', code: '29202' },
            { name: 'Iapu', code: '29301' },
            { name: 'Ibertioga', code: '29400' },
            { name: 'Ibiá', code: '29509' },
            { name: 'Ibiaí', code: '29608' },
            { name: 'Ibiracatu', code: '29657' },
            { name: 'Ibiraci', code: '29707' },
            { name: 'Ibirité', code: '29806' },
            { name: 'Ibitiúra de Minas', code: '29905' },
            { name: 'Ibituruna', code: '30002' },
            { name: 'Icaraí de Minas', code: '30051' },
            { name: 'Igarapé', code: '30101' },
            { name: 'Igaratinga', code: '30200' },
            { name: 'Iguatama', code: '30309' },
            { name: 'Ijaci', code: '30408' },
            { name: 'Ilicínea', code: '30507' },
            { name: 'Imbé de Minas', code: '30556' },
            { name: 'Inconfidentes', code: '30606' },
            { name: 'Indaiabira', code: '30655' },
            { name: 'Indianópolis', code: '30705' },
            { name: 'Ingaí', code: '30804' },
            { name: 'Inhapim', code: '30903' },
            { name: 'Inhaúma', code: '31000' },
            { name: 'Inimutaba', code: '31109' },
            { name: 'Ipaba', code: '31158' },
            { name: 'Ipanema', code: '31208' },
            { name: 'Ipatinga', code: '31307' },
            { name: 'Ipiaçu', code: '31406' },
            { name: 'Ipuiúna', code: '31505' },
            { name: 'Iraí de Minas', code: '31604' },
            { name: 'Itabira', code: '31703' },
            { name: 'Itabirinha', code: '31802' },
            { name: 'Itabirito', code: '31901' },
            { name: 'Itacambira', code: '32008' },
            { name: 'Itacarambi', code: '32107' },
            { name: 'Itaguara', code: '32206' },
            { name: 'Itaipé', code: '32305' },
            { name: 'Itajubá', code: '32404' },
            { name: 'Itamarandiba', code: '32503' },
            { name: 'Itamarati de Minas', code: '32602' },
            { name: 'Itambacuri', code: '32701' },
            { name: 'Itambé do Mato Dentro', code: '32800' },
            { name: 'Itamogi', code: '32909' },
            { name: 'Itamonte', code: '33006' },
            { name: 'Itanhandu', code: '33105' },
            { name: 'Itanhomi', code: '33204' },
            { name: 'Itaobim', code: '33303' },
            { name: 'Itapagipe', code: '33402' },
            { name: 'Itapecerica', code: '33501' },
            { name: 'Itapeva', code: '33600' },
            { name: 'Itatiaiuçu', code: '33709' },
            { name: 'Itaú de Minas', code: '33758' },
            { name: 'Itaúna', code: '33808' },
            { name: 'Itaverava', code: '33907' },
            { name: 'Itinga', code: '34004' },
            { name: 'Itueta', code: '34103' },
            { name: 'Ituiutaba', code: '34202' },
            { name: 'Itumirim', code: '34301' },
            { name: 'Iturama', code: '34400' },
            { name: 'Itutinga', code: '34509' },
            { name: 'Jaboticatubas', code: '34608' },
            { name: 'Jacinto', code: '34707' },
            { name: 'Jacuí', code: '34806' },
            { name: 'Jacutinga', code: '34905' },
            { name: 'Jaguaraçu', code: '35001' },
            { name: 'Jaíba', code: '35050' },
            { name: 'Jampruca', code: '35076' },
            { name: 'Janaúba', code: '35100' },
            { name: 'Januária', code: '35209' },
            { name: 'Japaraíba', code: '35308' },
            { name: 'Japonvar', code: '35357' },
            { name: 'Jeceaba', code: '35407' },
            { name: 'Jenipapo de Minas', code: '35456' },
            { name: 'Jequeri', code: '35506' },
            { name: 'Jequitaí', code: '35605' },
            { name: 'Jequitibá', code: '35704' },
            { name: 'Jequitinhonha', code: '35803' },
            { name: 'Jesuânia', code: '35902' },
            { name: 'Joaíma', code: '36009' },
            { name: 'Joanésia', code: '36108' },
            { name: 'João Monlevade', code: '36207' },
            { name: 'João Pinheiro', code: '36306' },
            { name: 'Joaquim Felício', code: '36405' },
            { name: 'Jordânia', code: '36504' },
            { name: 'José Gonçalves de Minas', code: '36520' },
            { name: 'José Raydan', code: '36553' },
            { name: 'Josenópolis', code: '36579' },
            { name: 'Juatuba', code: '36652' },
            { name: 'Juiz de Fora', code: '36702' },
            { name: 'Juramento', code: '36801' },
            { name: 'Juruaia', code: '36900' },
            { name: 'Juvenília', code: '36959' },
            { name: 'Ladainha', code: '37007' },
            { name: 'Lagamar', code: '37106' },
            { name: 'Lagoa da Prata', code: '37205' },
            { name: 'Lagoa dos Patos', code: '37304' },
            { name: 'Lagoa Dourada', code: '37403' },
            { name: 'Lagoa Formosa', code: '37502' },
            { name: 'Lagoa Grande', code: '37536' },
            { name: 'Lagoa Santa', code: '37601' },
            { name: 'Lajinha', code: '37700' },
            { name: 'Lambari', code: '37809' },
            { name: 'Lamim', code: '37908' },
            { name: 'Laranjal', code: '38005' },
            { name: 'Lassance', code: '38104' },
            { name: 'Lavras', code: '38203' },
            { name: 'Leandro Ferreira', code: '38302' },
            { name: 'Leme do Prado', code: '38351' },
            { name: 'Leopoldina', code: '38401' },
            { name: 'Liberdade', code: '38500' },
            { name: 'Lima Duarte', code: '38609' },
            { name: 'Limeira do Oeste', code: '38625' },
            { name: 'Lontra', code: '38658' },
            { name: 'Luisburgo', code: '38674' },
            { name: 'Luislândia', code: '38682' },
            { name: 'Luminárias', code: '38708' },
            { name: 'Luz', code: '38807' },
            { name: 'Machacalis', code: '38906' },
            { name: 'Machado', code: '39003' },
            { name: 'Madre de Deus de Minas', code: '39102' },
            { name: 'Malacacheta', code: '39201' },
            { name: 'Mamonas', code: '39250' },
            { name: 'Manga', code: '39300' },
            { name: 'Manhuaçu', code: '39409' },
            { name: 'Manhumirim', code: '39508' },
            { name: 'Mantena', code: '39607' },
            { name: 'Mar de Espanha', code: '39805' },
            { name: 'Maravilhas', code: '39706' },
            { name: 'Maria da Fé', code: '39904' },
            { name: 'Mariana', code: '40001' },
            { name: 'Marilac', code: '40100' },
            { name: 'Mário Campos', code: '40159' },
            { name: 'Maripá de Minas', code: '40209' },
            { name: 'Marliéria', code: '40308' },
            { name: 'Marmelópolis', code: '40407' },
            { name: 'Martinho Campos', code: '40506' },
            { name: 'Martins Soares', code: '40530' },
            { name: 'Mata Verde', code: '40555' },
            { name: 'Materlândia', code: '40605' },
            { name: 'Mateus Leme', code: '40704' },
            { name: 'Mathias Lobato', code: '71501' },
            { name: 'Matias Barbosa', code: '40803' },
            { name: 'Matias Cardoso', code: '40852' },
            { name: 'Matipó', code: '40902' },
            { name: 'Mato Verde', code: '41009' },
            { name: 'Matozinhos', code: '41108' },
            { name: 'Matutina', code: '41207' },
            { name: 'Medeiros', code: '41306' },
            { name: 'Medina', code: '41405' },
            { name: 'Mendes Pimentel', code: '41504' },
            { name: 'Mercês', code: '41603' },
            { name: 'Mesquita', code: '41702' },
            { name: 'Minas Novas', code: '41801' },
            { name: 'Minduri', code: '41900' },
            { name: 'Mirabela', code: '42007' },
            { name: 'Miradouro', code: '42106' },
            { name: 'Miraí', code: '42205' },
            { name: 'Miravânia', code: '42254' },
            { name: 'Moeda', code: '42304' },
            { name: 'Moema', code: '42403' },
            { name: 'Monjolos', code: '42502' },
            { name: 'Monsenhor Paulo', code: '42601' },
            { name: 'Montalvânia', code: '42700' },
            { name: 'Monte Alegre de Minas', code: '42809' },
            { name: 'Monte Azul', code: '42908' },
            { name: 'Monte Belo', code: '43005' },
            { name: 'Monte Carmelo', code: '43104' },
            { name: 'Monte Formoso', code: '43153' },
            { name: 'Monte Santo de Minas', code: '43203' },
            { name: 'Monte Sião', code: '43401' },
            { name: 'Montes Claros', code: '43302' },
            { name: 'Montezuma', code: '43450' },
            { name: 'Morada Nova de Minas', code: '43500' },
            { name: 'Morro da Garça', code: '43609' },
            { name: 'Morro do Pilar', code: '43708' },
            { name: 'Munhoz', code: '43807' },
            { name: 'Muriaé', code: '43906' },
            { name: 'Mutum', code: '44003' },
            { name: 'Muzambinho', code: '44102' },
            { name: 'Nacip Raydan', code: '44201' },
            { name: 'Nanuque', code: '44300' },
            { name: 'Naque', code: '44359' },
            { name: 'Natalândia', code: '44375' },
            { name: 'Natércia', code: '44409' },
            { name: 'Nazareno', code: '44508' },
            { name: 'Nepomuceno', code: '44607' },
            { name: 'Ninheira', code: '44656' },
            { name: 'Nova Belém', code: '44672' },
            { name: 'Nova Era', code: '44706' },
            { name: 'Nova Lima', code: '44805' },
            { name: 'Nova Módica', code: '44904' },
            { name: 'Nova Ponte', code: '45000' },
            { name: 'Nova Porteirinha', code: '45059' },
            { name: 'Nova Resende', code: '45109' },
            { name: 'Nova Serrana', code: '45208' },
            { name: 'Nova União', code: '36603' },
            { name: 'Novo Cruzeiro', code: '45307' },
            { name: 'Novo Oriente de Minas', code: '45356' },
            { name: 'Novorizonte', code: '45372' },
            { name: 'Olaria', code: '45406' },
            { name: "Olhos-d'Água", code: '45455' },
            { name: 'Olímpio Noronha', code: '45505' },
            { name: 'Oliveira', code: '45604' },
            { name: 'Oliveira Fortes', code: '45703' },
            { name: 'Onça de Pitangui', code: '45802' },
            { name: 'Oratórios', code: '45851' },
            { name: 'Orizânia', code: '45877' },
            { name: 'Ouro Branco', code: '45901' },
            { name: 'Ouro Fino', code: '46008' },
            { name: 'Ouro Preto', code: '46107' },
            { name: 'Ouro Verde de Minas', code: '46206' },
            { name: 'Padre Carvalho', code: '46255' },
            { name: 'Padre Paraíso', code: '46305' },
            { name: 'Pai Pedro', code: '46552' },
            { name: 'Paineiras', code: '46404' },
            { name: 'Pains', code: '46503' },
            { name: 'Paiva', code: '46602' },
            { name: 'Palma', code: '46701' },
            { name: 'Palmópolis', code: '46750' },
            { name: 'Papagaios', code: '46909' },
            { name: 'Pará de Minas', code: '47105' },
            { name: 'Paracatu', code: '47006' },
            { name: 'Paraguaçu', code: '47204' },
            { name: 'Paraisópolis', code: '47303' },
            { name: 'Paraopeba', code: '47402' },
            { name: 'Passa Quatro', code: '47600' },
            { name: 'Passa Tempo', code: '47709' },
            { name: 'Passa Vinte', code: '47808' },
            { name: 'Passabém', code: '47501' },
            { name: 'Passos', code: '47907' },
            { name: 'Patis', code: '47956' },
            { name: 'Patos de Minas', code: '48004' },
            { name: 'Patrocínio', code: '48103' },
            { name: 'Patrocínio do Muriaé', code: '48202' },
            { name: 'Paula Cândido', code: '48301' },
            { name: 'Paulistas', code: '48400' },
            { name: 'Pavão', code: '48509' },
            { name: 'Peçanha', code: '48608' },
            { name: 'Pedra Azul', code: '48707' },
            { name: 'Pedra Bonita', code: '48756' },
            { name: 'Pedra do Anta', code: '48806' },
            { name: 'Pedra do Indaiá', code: '48905' },
            { name: 'Pedra Dourada', code: '49002' },
            { name: 'Pedralva', code: '49101' },
            { name: 'Pedras de Maria da Cruz', code: '49150' },
            { name: 'Pedrinópolis', code: '49200' },
            { name: 'Pedro Leopoldo', code: '49309' },
            { name: 'Pedro Teixeira', code: '49408' },
            { name: 'Pequeri', code: '49507' },
            { name: 'Pequi', code: '49606' },
            { name: 'Perdigão', code: '49705' },
            { name: 'Perdizes', code: '49804' },
            { name: 'Perdões', code: '49903' },
            { name: 'Periquito', code: '49952' },
            { name: 'Pescador', code: '50000' },
            { name: 'Piau', code: '50109' },
            { name: 'Piedade de Caratinga', code: '50158' },
            { name: 'Piedade de Ponte Nova', code: '50208' },
            { name: 'Piedade do Rio Grande', code: '50307' },
            { name: 'Piedade dos Gerais', code: '50406' },
            { name: 'Pimenta', code: '50505' },
            { name: "Pingo-d'Água", code: '50539' },
            { name: 'Pintópolis', code: '50570' },
            { name: 'Piracema', code: '50604' },
            { name: 'Pirajuba', code: '50703' },
            { name: 'Piranga', code: '50802' },
            { name: 'Piranguçu', code: '50901' },
            { name: 'Piranguinho', code: '51008' },
            { name: 'Pirapetinga', code: '51107' },
            { name: 'Pirapora', code: '51206' },
            { name: 'Piraúba', code: '51305' },
            { name: 'Pitangui', code: '51404' },
            { name: 'Piumhi', code: '51503' },
            { name: 'Planura', code: '51602' },
            { name: 'Poço Fundo', code: '51701' },
            { name: 'Poços de Caldas', code: '51800' },
            { name: 'Pocrane', code: '51909' },
            { name: 'Pompéu', code: '52006' },
            { name: 'Ponte Nova', code: '52105' },
            { name: 'Ponto Chique', code: '52131' },
            { name: 'Ponto dos Volantes', code: '52170' },
            { name: 'Porteirinha', code: '52204' },
            { name: 'Porto Firme', code: '52303' },
            { name: 'Poté', code: '52402' },
            { name: 'Pouso Alegre', code: '52501' },
            { name: 'Pouso Alto', code: '52600' },
            { name: 'Prados', code: '52709' },
            { name: 'Prata', code: '52808' },
            { name: 'Pratápolis', code: '52907' },
            { name: 'Pratinha', code: '53004' },
            { name: 'Presidente Bernardes', code: '53103' },
            { name: 'Presidente Juscelino', code: '53202' },
            { name: 'Presidente Kubitschek', code: '53301' },
            { name: 'Presidente Olegário', code: '53400' },
            { name: 'Prudente de Morais', code: '53608' },
            { name: 'Quartel Geral', code: '53707' },
            { name: 'Queluzito', code: '53806' },
            { name: 'Raposos', code: '53905' },
            { name: 'Raul Soares', code: '54002' },
            { name: 'Recreio', code: '54101' },
            { name: 'Reduto', code: '54150' },
            { name: 'Resende Costa', code: '54200' },
            { name: 'Resplendor', code: '54309' },
            { name: 'Ressaquinha', code: '54408' },
            { name: 'Riachinho', code: '54457' },
            { name: 'Riacho dos Machados', code: '54507' },
            { name: 'Ribeirão das Neves', code: '54606' },
            { name: 'Ribeirão Vermelho', code: '54705' },
            { name: 'Rio Acima', code: '54804' },
            { name: 'Rio Casca', code: '54903' },
            { name: 'Rio do Prado', code: '55108' },
            { name: 'Rio Doce', code: '55009' },
            { name: 'Rio Espera', code: '55207' },
            { name: 'Rio Manso', code: '55306' },
            { name: 'Rio Novo', code: '55405' },
            { name: 'Rio Paranaíba', code: '55504' },
            { name: 'Rio Pardo de Minas', code: '55603' },
            { name: 'Rio Piracicaba', code: '55702' },
            { name: 'Rio Pomba', code: '55801' },
            { name: 'Rio Preto', code: '55900' },
            { name: 'Rio Vermelho', code: '56007' },
            { name: 'Ritápolis', code: '56106' },
            { name: 'Rochedo de Minas', code: '56205' },
            { name: 'Rodeiro', code: '56304' },
            { name: 'Romaria', code: '56403' },
            { name: 'Rosário da Limeira', code: '56452' },
            { name: 'Rubelita', code: '56502' },
            { name: 'Rubim', code: '56601' },
            { name: 'Sabará', code: '56700' },
            { name: 'Sabinópolis', code: '56809' },
            { name: 'Sacramento', code: '56908' },
            { name: 'Salinas', code: '57005' },
            { name: 'Salto da Divisa', code: '57104' },
            { name: 'Santa Bárbara', code: '57203' },
            { name: 'Santa Bárbara do Leste', code: '57252' },
            { name: 'Santa Bárbara do Monte Verde', code: '57278' },
            { name: 'Santa Bárbara do Tugúrio', code: '57302' },
            { name: 'Santa Cruz de Minas', code: '57336' },
            { name: 'Santa Cruz de Salinas', code: '57377' },
            { name: 'Santa Cruz do Escalvado', code: '57401' },
            { name: 'Santa Efigênia de Minas', code: '57500' },
            { name: 'Santa Fé de Minas', code: '57609' },
            { name: 'Santa Helena de Minas', code: '57658' },
            { name: 'Santa Juliana', code: '57708' },
            { name: 'Santa Luzia', code: '57807' },
            { name: 'Santa Margarida', code: '57906' },
            { name: 'Santa Maria de Itabira', code: '58003' },
            { name: 'Santa Maria do Salto', code: '58102' },
            { name: 'Santa Maria do Suaçuí', code: '58201' },
            { name: 'Santa Rita de Caldas', code: '59209' },
            { name: 'Santa Rita de Ibitipoca', code: '59407' },
            { name: 'Santa Rita de Jacutinga', code: '59308' },
            { name: 'Santa Rita de Minas', code: '59357' },
            { name: 'Santa Rita do Itueto', code: '59506' },
            { name: 'Santa Rita do Sapucaí', code: '59605' },
            { name: 'Santa Rosa da Serra', code: '59704' },
            { name: 'Santa Vitória', code: '59803' },
            { name: 'Santana da Vargem', code: '58300' },
            { name: 'Santana de Cataguases', code: '58409' },
            { name: 'Santana de Pirapama', code: '58508' },
            { name: 'Santana do Deserto', code: '58607' },
            { name: 'Santana do Garambéu', code: '58706' },
            { name: 'Santana do Jacaré', code: '58805' },
            { name: 'Santana do Manhuaçu', code: '58904' },
            { name: 'Santana do Paraíso', code: '58953' },
            { name: 'Santana do Riacho', code: '59001' },
            { name: 'Santana dos Montes', code: '59100' },
            { name: 'Santo Antônio do Amparo', code: '59902' },
            { name: 'Santo Antônio do Aventureiro', code: '60009' },
            { name: 'Santo Antônio do Grama', code: '60108' },
            { name: 'Santo Antônio do Itambé', code: '60207' },
            { name: 'Santo Antônio do Jacinto', code: '60306' },
            { name: 'Santo Antônio do Monte', code: '60405' },
            { name: 'Santo Antônio do Retiro', code: '60454' },
            { name: 'Santo Antônio do Rio Abaixo', code: '60504' },
            { name: 'Santo Hipólito', code: '60603' },
            { name: 'Santos Dumont', code: '60702' },
            { name: 'São Bento Abade', code: '60801' },
            { name: 'São Brás do Suaçuí', code: '60900' },
            { name: 'São Domingos das Dores', code: '60959' },
            { name: 'São Domingos do Prata', code: '61007' },
            { name: 'São Félix de Minas', code: '61056' },
            { name: 'São Francisco', code: '61106' },
            { name: 'São Francisco de Paula', code: '61205' },
            { name: 'São Francisco de Sales', code: '61304' },
            { name: 'São Francisco do Glória', code: '61403' },
            { name: 'São Geraldo', code: '61502' },
            { name: 'São Geraldo da Piedade', code: '61601' },
            { name: 'São Geraldo do Baixio', code: '61650' },
            { name: 'São Gonçalo do Abaeté', code: '61700' },
            { name: 'São Gonçalo do Pará', code: '61809' },
            { name: 'São Gonçalo do Rio Abaixo', code: '61908' },
            { name: 'São Gonçalo do Rio Preto', code: '25507' },
            { name: 'São Gonçalo do Sapucaí', code: '62005' },
            { name: 'São Gotardo', code: '62104' },
            { name: 'São João Batista do Glória', code: '62203' },
            { name: 'São João da Lagoa', code: '62252' },
            { name: 'São João da Mata', code: '62302' },
            { name: 'São João da Ponte', code: '62401' },
            { name: 'São João das Missões', code: '62450' },
            { name: 'São João del Rei', code: '62500' },
            { name: 'São João do Manhuaçu', code: '62559' },
            { name: 'São João do Manteninha', code: '62575' },
            { name: 'São João do Oriente', code: '62609' },
            { name: 'São João do Pacuí', code: '62658' },
            { name: 'São João do Paraíso', code: '62708' },
            { name: 'São João Evangelista', code: '62807' },
            { name: 'São João Nepomuceno', code: '62906' },
            { name: 'São Joaquim de Bicas', code: '62922' },
            { name: 'São José da Barra', code: '62948' },
            { name: 'São José da Lapa', code: '62955' },
            { name: 'São José da Safira', code: '63003' },
            { name: 'São José da Varginha', code: '63102' },
            { name: 'São José do Alegre', code: '63201' },
            { name: 'São José do Divino', code: '63300' },
            { name: 'São José do Goiabal', code: '63409' },
            { name: 'São José do Jacuri', code: '63508' },
            { name: 'São José do Mantimento', code: '63607' },
            { name: 'São Lourenço', code: '63706' },
            { name: 'São Miguel do Anta', code: '63805' },
            { name: 'São Pedro da União', code: '63904' },
            { name: 'São Pedro do Suaçuí', code: '64100' },
            { name: 'São Pedro dos Ferros', code: '64001' },
            { name: 'São Romão', code: '64209' },
            { name: 'São Roque de Minas', code: '64308' },
            { name: 'São Sebastião da Bela Vista', code: '64407' },
            { name: 'São Sebastião da Vargem Alegre', code: '64431' },
            { name: 'São Sebastião do Anta', code: '64472' },
            { name: 'São Sebastião do Maranhão', code: '64506' },
            { name: 'São Sebastião do Oeste', code: '64605' },
            { name: 'São Sebastião do Paraíso', code: '64704' },
            { name: 'São Sebastião do Rio Preto', code: '64803' },
            { name: 'São Sebastião do Rio Verde', code: '64902' },
            { name: 'São Tiago', code: '65008' },
            { name: 'São Tomás de Aquino', code: '65107' },
            { name: 'São Tomé das Letras', code: '65206' },
            { name: 'São Vicente de Minas', code: '65305' },
            { name: 'Sapucaí-Mirim', code: '65404' },
            { name: 'Sardoá', code: '65503' },
            { name: 'Sarzedo', code: '65537' },
            { name: 'Sem-Peixe', code: '65560' },
            { name: 'Senador Amaral', code: '65578' },
            { name: 'Senador Cortes', code: '65602' },
            { name: 'Senador Firmino', code: '65701' },
            { name: 'Senador José Bento', code: '65800' },
            { name: 'Senador Modestino Gonçalves', code: '65909' },
            { name: 'Senhora de Oliveira', code: '66006' },
            { name: 'Senhora do Porto', code: '66105' },
            { name: 'Senhora dos Remédios', code: '66204' },
            { name: 'Sericita', code: '66303' },
            { name: 'Seritinga', code: '66402' },
            { name: 'Serra Azul de Minas', code: '66501' },
            { name: 'Serra da Saudade', code: '66600' },
            { name: 'Serra do Salitre', code: '66808' },
            { name: 'Serra dos Aimorés', code: '66709' },
            { name: 'Serrania', code: '66907' },
            { name: 'Serranópolis de Minas', code: '66956' },
            { name: 'Serranos', code: '67004' },
            { name: 'Serro', code: '67103' },
            { name: 'Sete Lagoas', code: '67202' },
            { name: 'Setubinha', code: '65552' },
            { name: 'Silveirânia', code: '67301' },
            { name: 'Silvianópolis', code: '67400' },
            { name: 'Simão Pereira', code: '67509' },
            { name: 'Simonésia', code: '67608' },
            { name: 'Sobrália', code: '67707' },
            { name: 'Soledade de Minas', code: '67806' },
            { name: 'Tabuleiro', code: '67905' },
            { name: 'Taiobeiras', code: '68002' },
            { name: 'Taparuba', code: '68051' },
            { name: 'Tapira', code: '68101' },
            { name: 'Tapiraí', code: '68200' },
            { name: 'Taquaraçu de Minas', code: '68309' },
            { name: 'Tarumirim', code: '68408' },
            { name: 'Teixeiras', code: '68507' },
            { name: 'Teófilo Otoni', code: '68606' },
            { name: 'Timóteo', code: '68705' },
            { name: 'Tiradentes', code: '68804' },
            { name: 'Tiros', code: '68903' },
            { name: 'Tocantins', code: '69000' },
            { name: 'Tocos do Moji', code: '69059' },
            { name: 'Toledo', code: '69109' },
            { name: 'Tombos', code: '69208' },
            { name: 'Três Corações', code: '69307' },
            { name: 'Três Marias', code: '69356' },
            { name: 'Três Pontas', code: '69406' },
            { name: 'Tumiritinga', code: '69505' },
            { name: 'Tupaciguara', code: '69604' },
            { name: 'Turmalina', code: '69703' },
            { name: 'Turvolândia', code: '69802' },
            { name: 'Ubá', code: '69901' },
            { name: 'Ubaí', code: '70008' },
            { name: 'Ubaporanga', code: '70057' },
            { name: 'Uberaba', code: '70107' },
            { name: 'Uberlândia', code: '70206' },
            { name: 'Umburatiba', code: '70305' },
            { name: 'Unaí', code: '70404' },
            { name: 'União de Minas', code: '70438' },
            { name: 'Uruana de Minas', code: '70479' },
            { name: 'Urucânia', code: '70503' },
            { name: 'Urucuia', code: '70529' },
            { name: 'Vargem Alegre', code: '70578' },
            { name: 'Vargem Bonita', code: '70602' },
            { name: 'Vargem Grande do Rio Pardo', code: '70651' },
            { name: 'Varginha', code: '70701' },
            { name: 'Varjão de Minas', code: '70750' },
            { name: 'Várzea da Palma', code: '70800' },
            { name: 'Varzelândia', code: '70909' },
            { name: 'Vazante', code: '71006' },
            { name: 'Verdelândia', code: '71030' },
            { name: 'Veredinha', code: '71071' },
            { name: 'Veríssimo', code: '71105' },
            { name: 'Vermelho Novo', code: '71154' },
            { name: 'Vespasiano', code: '71204' },
            { name: 'Viçosa', code: '71303' },
            { name: 'Vieiras', code: '71402' },
            { name: 'Virgem da Lapa', code: '71600' },
            { name: 'Virgínia', code: '71709' },
            { name: 'Virginópolis', code: '71808' },
            { name: 'Virgolândia', code: '71907' },
            { name: 'Visconde do Rio Branco', code: '72004' },
            { name: 'Volta Grande', code: '72103' },
            { name: 'Wenceslau Braz', code: '72202' },
        ],
    },
    {
        abbreviation: StateAbbreviation.PA,
        name: 'Pará',
        code: '15',
        cities: [
            { name: 'Abaetetuba', code: '00107' },
            { name: 'Abel Figueiredo', code: '00131' },
            { name: 'Acará', code: '00206' },
            { name: 'Afuá', code: '00305' },
            { name: 'Água Azul do Norte', code: '00347' },
            { name: 'Alenquer', code: '00404' },
            { name: 'Almeirim', code: '00503' },
            { name: 'Altamira', code: '00602' },
            { name: 'Anajás', code: '00701' },
            { name: 'Ananindeua', code: '00800' },
            { name: 'Anapu', code: '00859' },
            { name: 'Augusto Corrêa', code: '00909' },
            { name: 'Aurora do Pará', code: '00958' },
            { name: 'Aveiro', code: '01006' },
            { name: 'Bagre', code: '01105' },
            { name: 'Baião', code: '01204' },
            { name: 'Bannach', code: '01253' },
            { name: 'Barcarena', code: '01303' },
            { name: 'Belém', code: '01402' },
            { name: 'Belterra', code: '01451' },
            { name: 'Benevides', code: '01501' },
            { name: 'Bom Jesus do Tocantins', code: '01576' },
            { name: 'Bonito', code: '01600' },
            { name: 'Bragança', code: '01709' },
            { name: 'Brasil Novo', code: '01725' },
            { name: 'Brejo Grande do Araguaia', code: '01758' },
            { name: 'Breu Branco', code: '01782' },
            { name: 'Breves', code: '01808' },
            { name: 'Bujaru', code: '01907' },
            { name: 'Cachoeira do Arari', code: '02004' },
            { name: 'Cachoeira do Piriá', code: '01956' },
            { name: 'Cametá', code: '02103' },
            { name: 'Canaã dos Carajás', code: '02152' },
            { name: 'Capanema', code: '02202' },
            { name: 'Capitão Poço', code: '02301' },
            { name: 'Castanhal', code: '02400' },
            { name: 'Chaves', code: '02509' },
            { name: 'Colares', code: '02608' },
            { name: 'Conceição do Araguaia', code: '02707' },
            { name: 'Concórdia do Pará', code: '02756' },
            { name: 'Cumaru do Norte', code: '02764' },
            { name: 'Curionópolis', code: '02772' },
            { name: 'Curralinho', code: '02806' },
            { name: 'Curuá', code: '02855' },
            { name: 'Curuçá', code: '02905' },
            { name: 'Dom Eliseu', code: '02939' },
            { name: 'Eldorado do Carajás', code: '02954' },
            { name: 'Faro', code: '03002' },
            { name: 'Floresta do Araguaia', code: '03044' },
            { name: 'Garrafão do Norte', code: '03077' },
            { name: 'Goianésia do Pará', code: '03093' },
            { name: 'Gurupá', code: '03101' },
            { name: 'Igarapé-Açu', code: '03200' },
            { name: 'Igarapé-Miri', code: '03309' },
            { name: 'Inhangapi', code: '03408' },
            { name: 'Ipixuna do Pará', code: '03457' },
            { name: 'Irituia', code: '03507' },
            { name: 'Itaituba', code: '03606' },
            { name: 'Itupiranga', code: '03705' },
            { name: 'Jacareacanga', code: '03754' },
            { name: 'Jacundá', code: '03804' },
            { name: 'Juruti', code: '03903' },
            { name: 'Limoeiro do Ajuru', code: '04000' },
            { name: 'Mãe do Rio', code: '04059' },
            { name: 'Magalhães Barata', code: '04109' },
            { name: 'Marabá', code: '04208' },
            { name: 'Maracanã', code: '04307' },
            { name: 'Marapanim', code: '04406' },
            { name: 'Marituba', code: '04422' },
            { name: 'Medicilândia', code: '04455' },
            { name: 'Melgaço', code: '04505' },
            { name: 'Mocajuba', code: '04604' },
            { name: 'Moju', code: '04703' },
            { name: 'Mojuí dos Campos', code: '04752' },
            { name: 'Monte Alegre', code: '04802' },
            { name: 'Muaná', code: '04901' },
            { name: 'Nova Esperança do Piriá', code: '04950' },
            { name: 'Nova Ipixuna', code: '04976' },
            { name: 'Nova Timboteua', code: '05007' },
            { name: 'Novo Progresso', code: '05031' },
            { name: 'Novo Repartimento', code: '05064' },
            { name: 'Óbidos', code: '05106' },
            { name: 'Oeiras do Pará', code: '05205' },
            { name: 'Oriximiná', code: '05304' },
            { name: 'Ourém', code: '05403' },
            { name: 'Ourilândia do Norte', code: '05437' },
            { name: 'Pacajá', code: '05486' },
            { name: 'Palestina do Pará', code: '05494' },
            { name: 'Paragominas', code: '05502' },
            { name: 'Parauapebas', code: '05536' },
            { name: "Pau D'Arco", code: '05551' },
            { name: 'Peixe-Boi', code: '05601' },
            { name: 'Piçarra', code: '05635' },
            { name: 'Placas', code: '05650' },
            { name: 'Ponta de Pedras', code: '05700' },
            { name: 'Portel', code: '05809' },
            { name: 'Porto de Moz', code: '05908' },
            { name: 'Prainha', code: '06005' },
            { name: 'Primavera', code: '06104' },
            { name: 'Quatipuru', code: '06112' },
            { name: 'Redenção', code: '06138' },
            { name: 'Rio Maria', code: '06161' },
            { name: 'Rondon do Pará', code: '06187' },
            { name: 'Rurópolis', code: '06195' },
            { name: 'Salinópolis', code: '06203' },
            { name: 'Salvaterra', code: '06302' },
            { name: 'Santa Bárbara do Pará', code: '06351' },
            { name: 'Santa Cruz do Arari', code: '06401' },
            { name: 'Santa Izabel do Pará', code: '06500' },
            { name: 'Santa Luzia do Pará', code: '06559' },
            { name: 'Santa Maria das Barreiras', code: '06583' },
            { name: 'Santa Maria do Pará', code: '06609' },
            { name: 'Santana do Araguaia', code: '06708' },
            { name: 'Santarém', code: '06807' },
            { name: 'Santarém Novo', code: '06906' },
            { name: 'Santo Antônio do Tauá', code: '07003' },
            { name: 'São Caetano de Odivelas', code: '07102' },
            { name: 'São Domingos do Araguaia', code: '07151' },
            { name: 'São Domingos do Capim', code: '07201' },
            { name: 'São Félix do Xingu', code: '07300' },
            { name: 'São Francisco do Pará', code: '07409' },
            { name: 'São Geraldo do Araguaia', code: '07458' },
            { name: 'São João da Ponta', code: '07466' },
            { name: 'São João de Pirabas', code: '07474' },
            { name: 'São João do Araguaia', code: '07508' },
            { name: 'São Miguel do Guamá', code: '07607' },
            { name: 'São Sebastião da Boa Vista', code: '07706' },
            { name: 'Sapucaia', code: '07755' },
            { name: 'Senador José Porfírio', code: '07805' },
            { name: 'Soure', code: '07904' },
            { name: 'Tailândia', code: '07953' },
            { name: 'Terra Alta', code: '07961' },
            { name: 'Terra Santa', code: '07979' },
            { name: 'Tomé-Açu', code: '08001' },
            { name: 'Tracuateua', code: '08035' },
            { name: 'Trairão', code: '08050' },
            { name: 'Tucumã', code: '08084' },
            { name: 'Tucuruí', code: '08100' },
            { name: 'Ulianópolis', code: '08126' },
            { name: 'Uruará', code: '08159' },
            { name: 'Vigia', code: '08209' },
            { name: 'Viseu', code: '08308' },
            { name: 'Vitória do Xingu', code: '08357' },
            { name: 'Xinguara', code: '08407' },
        ],
    },
    {
        abbreviation: StateAbbreviation.CE,
        name: 'Ceará',
        code: '23',
        cities: [
            { name: 'Abaiara', code: '00101' },
            { name: 'Acarape', code: '00150' },
            { name: 'Acaraú', code: '00200' },
            { name: 'Acopiara', code: '00309' },
            { name: 'Aiuaba', code: '00408' },
            { name: 'Alcântaras', code: '00507' },
            { name: 'Altaneira', code: '00606' },
            { name: 'Alto Santo', code: '00705' },
            { name: 'Amontada', code: '00754' },
            { name: 'Antonina do Norte', code: '00804' },
            { name: 'Apuiarés', code: '00903' },
            { name: 'Aquiraz', code: '01000' },
            { name: 'Aracati', code: '01109' },
            { name: 'Aracoiaba', code: '01208' },
            { name: 'Ararendá', code: '01257' },
            { name: 'Araripe', code: '01307' },
            { name: 'Aratuba', code: '01406' },
            { name: 'Arneiroz', code: '01505' },
            { name: 'Assaré', code: '01604' },
            { name: 'Aurora', code: '01703' },
            { name: 'Baixio', code: '01802' },
            { name: 'Banabuiú', code: '01851' },
            { name: 'Barbalha', code: '01901' },
            { name: 'Barreira', code: '01950' },
            { name: 'Barro', code: '02008' },
            { name: 'Barroquinha', code: '02057' },
            { name: 'Baturité', code: '02107' },
            { name: 'Beberibe', code: '02206' },
            { name: 'Bela Cruz', code: '02305' },
            { name: 'Boa Viagem', code: '02404' },
            { name: 'Brejo Santo', code: '02503' },
            { name: 'Camocim', code: '02602' },
            { name: 'Campos Sales', code: '02701' },
            { name: 'Canindé', code: '02800' },
            { name: 'Capistrano', code: '02909' },
            { name: 'Caridade', code: '03006' },
            { name: 'Cariré', code: '03105' },
            { name: 'Caririaçu', code: '03204' },
            { name: 'Cariús', code: '03303' },
            { name: 'Carnaubal', code: '03402' },
            { name: 'Cascavel', code: '03501' },
            { name: 'Catarina', code: '03600' },
            { name: 'Catunda', code: '03659' },
            { name: 'Caucaia', code: '03709' },
            { name: 'Cedro', code: '03808' },
            { name: 'Chaval', code: '03907' },
            { name: 'Choró', code: '03931' },
            { name: 'Chorozinho', code: '03956' },
            { name: 'Coreaú', code: '04004' },
            { name: 'Crateús', code: '04103' },
            { name: 'Crato', code: '04202' },
            { name: 'Croatá', code: '04236' },
            { name: 'Cruz', code: '04251' },
            { name: 'Deputado Irapuan Pinheiro', code: '04269' },
            { name: 'Ereré', code: '04277' },
            { name: 'Eusébio', code: '04285' },
            { name: 'Farias Brito', code: '04301' },
            { name: 'Forquilha', code: '04350' },
            { name: 'Fortaleza', code: '04400' },
            { name: 'Fortim', code: '04459' },
            { name: 'Frecheirinha', code: '04509' },
            { name: 'General Sampaio', code: '04608' },
            { name: 'Graça', code: '04657' },
            { name: 'Granja', code: '04707' },
            { name: 'Granjeiro', code: '04806' },
            { name: 'Groaíras', code: '04905' },
            { name: 'Guaiúba', code: '04954' },
            { name: 'Guaraciaba do Norte', code: '05001' },
            { name: 'Guaramiranga', code: '05100' },
            { name: 'Hidrolândia', code: '05209' },
            { name: 'Horizonte', code: '05233' },
            { name: 'Ibaretama', code: '05266' },
            { name: 'Ibiapina', code: '05308' },
            { name: 'Ibicuitinga', code: '05332' },
            { name: 'Icapuí', code: '05357' },
            { name: 'Icó', code: '05407' },
            { name: 'Iguatu', code: '05506' },
            { name: 'Independência', code: '05605' },
            { name: 'Ipaporanga', code: '05654' },
            { name: 'Ipaumirim', code: '05704' },
            { name: 'Ipu', code: '05803' },
            { name: 'Ipueiras', code: '05902' },
            { name: 'Iracema', code: '06009' },
            { name: 'Irauçuba', code: '06108' },
            { name: 'Itaiçaba', code: '06207' },
            { name: 'Itaitinga', code: '06256' },
            { name: 'Itapajé', code: '06306' },
            { name: 'Itapipoca', code: '06405' },
            { name: 'Itapiúna', code: '06504' },
            { name: 'Itarema', code: '06553' },
            { name: 'Itatira', code: '06603' },
            { name: 'Jaguaretama', code: '06702' },
            { name: 'Jaguaribara', code: '06801' },
            { name: 'Jaguaribe', code: '06900' },
            { name: 'Jaguaruana', code: '07007' },
            { name: 'Jardim', code: '07106' },
            { name: 'Jati', code: '07205' },
            { name: 'Jijoca de Jericoacoara', code: '07254' },
            { name: 'Juazeiro do Norte', code: '07304' },
            { name: 'Jucás', code: '07403' },
            { name: 'Lavras da Mangabeira', code: '07502' },
            { name: 'Limoeiro do Norte', code: '07601' },
            { name: 'Madalena', code: '07635' },
            { name: 'Maracanaú', code: '07650' },
            { name: 'Maranguape', code: '07700' },
            { name: 'Marco', code: '07809' },
            { name: 'Martinópole', code: '07908' },
            { name: 'Massapê', code: '08005' },
            { name: 'Mauriti', code: '08104' },
            { name: 'Meruoca', code: '08203' },
            { name: 'Milagres', code: '08302' },
            { name: 'Milhã', code: '08351' },
            { name: 'Miraíma', code: '08377' },
            { name: 'Missão Velha', code: '08401' },
            { name: 'Mombaça', code: '08500' },
            { name: 'Monsenhor Tabosa', code: '08609' },
            { name: 'Morada Nova', code: '08708' },
            { name: 'Moraújo', code: '08807' },
            { name: 'Morrinhos', code: '08906' },
            { name: 'Mucambo', code: '09003' },
            { name: 'Mulungu', code: '09102' },
            { name: 'Nova Olinda', code: '09201' },
            { name: 'Nova Russas', code: '09300' },
            { name: 'Novo Oriente', code: '09409' },
            { name: 'Ocara', code: '09458' },
            { name: 'Orós', code: '09508' },
            { name: 'Pacajus', code: '09607' },
            { name: 'Pacatuba', code: '09706' },
            { name: 'Pacoti', code: '09805' },
            { name: 'Pacujá', code: '09904' },
            { name: 'Palhano', code: '10001' },
            { name: 'Palmácia', code: '10100' },
            { name: 'Paracuru', code: '10209' },
            { name: 'Paraipaba', code: '10258' },
            { name: 'Parambu', code: '10308' },
            { name: 'Paramoti', code: '10407' },
            { name: 'Pedra Branca', code: '10506' },
            { name: 'Penaforte', code: '10605' },
            { name: 'Pentecoste', code: '10704' },
            { name: 'Pereiro', code: '10803' },
            { name: 'Pindoretama', code: '10852' },
            { name: 'Piquet Carneiro', code: '10902' },
            { name: 'Pires Ferreira', code: '10951' },
            { name: 'Poranga', code: '11009' },
            { name: 'Porteiras', code: '11108' },
            { name: 'Potengi', code: '11207' },
            { name: 'Potiretama', code: '11231' },
            { name: 'Quiterianópolis', code: '11264' },
            { name: 'Quixadá', code: '11306' },
            { name: 'Quixelô', code: '11355' },
            { name: 'Quixeramobim', code: '11405' },
            { name: 'Quixeré', code: '11504' },
            { name: 'Redenção', code: '11603' },
            { name: 'Reriutaba', code: '11702' },
            { name: 'Russas', code: '11801' },
            { name: 'Saboeiro', code: '11900' },
            { name: 'Salitre', code: '11959' },
            { name: 'Santa Quitéria', code: '12205' },
            { name: 'Santana do Acaraú', code: '12007' },
            { name: 'Santana do Cariri', code: '12106' },
            { name: 'São Benedito', code: '12304' },
            { name: 'São Gonçalo do Amarante', code: '12403' },
            { name: 'São João do Jaguaribe', code: '12502' },
            { name: 'São Luís do Curu', code: '12601' },
            { name: 'Senador Pompeu', code: '12700' },
            { name: 'Senador Sá', code: '12809' },
            { name: 'Sobral', code: '12908' },
            { name: 'Solonópole', code: '13005' },
            { name: 'Tabuleiro do Norte', code: '13104' },
            { name: 'Tamboril', code: '13203' },
            { name: 'Tarrafas', code: '13252' },
            { name: 'Tauá', code: '13302' },
            { name: 'Tejuçuoca', code: '13351' },
            { name: 'Tianguá', code: '13401' },
            { name: 'Trairi', code: '13500' },
            { name: 'Tururu', code: '13559' },
            { name: 'Ubajara', code: '13609' },
            { name: 'Umari', code: '13708' },
            { name: 'Umirim', code: '13757' },
            { name: 'Uruburetama', code: '13807' },
            { name: 'Uruoca', code: '13906' },
            { name: 'Varjota', code: '13955' },
            { name: 'Várzea Alegre', code: '14003' },
            { name: 'Viçosa do Ceará', code: '14102' },
        ],
    },
    {
        abbreviation: StateAbbreviation.BA,
        name: 'Bahia',
        code: '29',
        cities: [
            { name: 'Abaíra', code: '00108' },
            { name: 'Abaré', code: '00207' },
            { name: 'Acajutiba', code: '00306' },
            { name: 'Adustina', code: '00355' },
            { name: 'Água Fria', code: '00405' },
            { name: 'Aiquara', code: '00603' },
            { name: 'Alagoinhas', code: '00702' },
            { name: 'Alcobaça', code: '00801' },
            { name: 'Almadina', code: '00900' },
            { name: 'Amargosa', code: '01007' },
            { name: 'Amélia Rodrigues', code: '01106' },
            { name: 'América Dourada', code: '01155' },
            { name: 'Anagé', code: '01205' },
            { name: 'Andaraí', code: '01304' },
            { name: 'Andorinha', code: '01353' },
            { name: 'Angical', code: '01403' },
            { name: 'Anguera', code: '01502' },
            { name: 'Antas', code: '01601' },
            { name: 'Antônio Cardoso', code: '01700' },
            { name: 'Antônio Gonçalves', code: '01809' },
            { name: 'Aporá', code: '01908' },
            { name: 'Apuarema', code: '01957' },
            { name: 'Araçás', code: '02054' },
            { name: 'Aracatu', code: '02005' },
            { name: 'Araci', code: '02104' },
            { name: 'Aramari', code: '02203' },
            { name: 'Arataca', code: '02252' },
            { name: 'Aratuípe', code: '02302' },
            { name: 'Aurelino Leal', code: '02401' },
            { name: 'Baianópolis', code: '02500' },
            { name: 'Baixa Grande', code: '02609' },
            { name: 'Banzaê', code: '02658' },
            { name: 'Barra', code: '02708' },
            { name: 'Barra da Estiva', code: '02807' },
            { name: 'Barra do Choça', code: '02906' },
            { name: 'Barra do Mendes', code: '03003' },
            { name: 'Barra do Rocha', code: '03102' },
            { name: 'Barreiras', code: '03201' },
            { name: 'Barro Alto', code: '03235' },
            { name: 'Barro Preto', code: '03300' },
            { name: 'Barrocas', code: '03276' },
            { name: 'Belmonte', code: '03409' },
            { name: 'Belo Campo', code: '03508' },
            { name: 'Biritinga', code: '03607' },
            { name: 'Boa Nova', code: '03706' },
            { name: 'Boa Vista do Tupim', code: '03805' },
            { name: 'Bom Jesus da Lapa', code: '03904' },
            { name: 'Bom Jesus da Serra', code: '03953' },
            { name: 'Boninal', code: '04001' },
            { name: 'Bonito', code: '04050' },
            { name: 'Boquira', code: '04100' },
            { name: 'Botuporã', code: '04209' },
            { name: 'Brejões', code: '04308' },
            { name: 'Brejolândia', code: '04407' },
            { name: 'Brotas de Macaúbas', code: '04506' },
            { name: 'Brumado', code: '04605' },
            { name: 'Buerarema', code: '04704' },
            { name: 'Buritirama', code: '04753' },
            { name: 'Caatiba', code: '04803' },
            { name: 'Cabaceiras do Paraguaçu', code: '04852' },
            { name: 'Cachoeira', code: '04902' },
            { name: 'Caculé', code: '05008' },
            { name: 'Caém', code: '05107' },
            { name: 'Caetanos', code: '05156' },
            { name: 'Caetité', code: '05206' },
            { name: 'Cafarnaum', code: '05305' },
            { name: 'Cairu', code: '05404' },
            { name: 'Caldeirão Grande', code: '05503' },
            { name: 'Camacan', code: '05602' },
            { name: 'Camaçari', code: '05701' },
            { name: 'Camamu', code: '05800' },
            { name: 'Campo Alegre de Lourdes', code: '05909' },
            { name: 'Campo Formoso', code: '06006' },
            { name: 'Canápolis', code: '06105' },
            { name: 'Canarana', code: '06204' },
            { name: 'Canavieiras', code: '06303' },
            { name: 'Candeal', code: '06402' },
            { name: 'Candeias', code: '06501' },
            { name: 'Candiba', code: '06600' },
            { name: 'Cândido Sales', code: '06709' },
            { name: 'Cansanção', code: '06808' },
            { name: 'Canudos', code: '06824' },
            { name: 'Capela do Alto Alegre', code: '06857' },
            { name: 'Capim Grosso', code: '06873' },
            { name: 'Caraíbas', code: '06899' },
            { name: 'Caravelas', code: '06907' },
            { name: 'Cardeal da Silva', code: '07004' },
            { name: 'Carinhanha', code: '07103' },
            { name: 'Casa Nova', code: '07202' },
            { name: 'Castro Alves', code: '07301' },
            { name: 'Catolândia', code: '07400' },
            { name: 'Catu', code: '07509' },
            { name: 'Caturama', code: '07558' },
            { name: 'Central', code: '07608' },
            { name: 'Chorrochó', code: '07707' },
            { name: 'Cícero Dantas', code: '07806' },
            { name: 'Cipó', code: '07905' },
            { name: 'Coaraci', code: '08002' },
            { name: 'Cocos', code: '08101' },
            { name: 'Conceição da Feira', code: '08200' },
            { name: 'Conceição do Almeida', code: '08309' },
            { name: 'Conceição do Coité', code: '08408' },
            { name: 'Conceição do Jacuípe', code: '08507' },
            { name: 'Conde', code: '08606' },
            { name: 'Condeúba', code: '08705' },
            { name: 'Contendas do Sincorá', code: '08804' },
            { name: 'Coração de Maria', code: '08903' },
            { name: 'Cordeiros', code: '09000' },
            { name: 'Coribe', code: '09109' },
            { name: 'Coronel João Sá', code: '09208' },
            { name: 'Correntina', code: '09307' },
            { name: 'Cotegipe', code: '09406' },
            { name: 'Cravolândia', code: '09505' },
            { name: 'Crisópolis', code: '09604' },
            { name: 'Cristópolis', code: '09703' },
            { name: 'Cruz das Almas', code: '09802' },
            { name: 'Curaçá', code: '09901' },
            { name: 'Dário Meira', code: '10008' },
            { name: "Dias d'Ávila", code: '10057' },
            { name: 'Dom Basílio', code: '10107' },
            { name: 'Dom Macedo Costa', code: '10206' },
            { name: 'Elísio Medrado', code: '10305' },
            { name: 'Encruzilhada', code: '10404' },
            { name: 'Entre Rios', code: '10503' },
            { name: 'Érico Cardoso', code: '00504' },
            { name: 'Esplanada', code: '10602' },
            { name: 'Euclides da Cunha', code: '10701' },
            { name: 'Eunápolis', code: '10727' },
            { name: 'Fátima', code: '10750' },
            { name: 'Feira da Mata', code: '10776' },
            { name: 'Feira de Santana', code: '10800' },
            { name: 'Filadélfia', code: '10859' },
            { name: 'Firmino Alves', code: '10909' },
            { name: 'Floresta Azul', code: '11006' },
            { name: 'Formosa do Rio Preto', code: '11105' },
            { name: 'Gandu', code: '11204' },
            { name: 'Gavião', code: '11253' },
            { name: 'Gentio do Ouro', code: '11303' },
            { name: 'Glória', code: '11402' },
            { name: 'Gongogi', code: '11501' },
            { name: 'Governador Mangabeira', code: '11600' },
            { name: 'Guajeru', code: '11659' },
            { name: 'Guanambi', code: '11709' },
            { name: 'Guaratinga', code: '11808' },
            { name: 'Heliópolis', code: '11857' },
            { name: 'Iaçu', code: '11907' },
            { name: 'Ibiassucê', code: '12004' },
            { name: 'Ibicaraí', code: '12103' },
            { name: 'Ibicoara', code: '12202' },
            { name: 'Ibicuí', code: '12301' },
            { name: 'Ibipeba', code: '12400' },
            { name: 'Ibipitanga', code: '12509' },
            { name: 'Ibiquera', code: '12608' },
            { name: 'Ibirapitanga', code: '12707' },
            { name: 'Ibirapuã', code: '12806' },
            { name: 'Ibirataia', code: '12905' },
            { name: 'Ibitiara', code: '13002' },
            { name: 'Ibititá', code: '13101' },
            { name: 'Ibotirama', code: '13200' },
            { name: 'Ichu', code: '13309' },
            { name: 'Igaporã', code: '13408' },
            { name: 'Igrapiúna', code: '13457' },
            { name: 'Iguaí', code: '13507' },
            { name: 'Ilhéus', code: '13606' },
            { name: 'Inhambupe', code: '13705' },
            { name: 'Ipecaetá', code: '13804' },
            { name: 'Ipiaú', code: '13903' },
            { name: 'Ipirá', code: '14000' },
            { name: 'Ipupiara', code: '14109' },
            { name: 'Irajuba', code: '14208' },
            { name: 'Iramaia', code: '14307' },
            { name: 'Iraquara', code: '14406' },
            { name: 'Irará', code: '14505' },
            { name: 'Irecê', code: '14604' },
            { name: 'Itabela', code: '14653' },
            { name: 'Itaberaba', code: '14703' },
            { name: 'Itabuna', code: '14802' },
            { name: 'Itacaré', code: '14901' },
            { name: 'Itaeté', code: '15007' },
            { name: 'Itagi', code: '15106' },
            { name: 'Itagibá', code: '15205' },
            { name: 'Itagimirim', code: '15304' },
            { name: 'Itaguaçu da Bahia', code: '15353' },
            { name: 'Itaju do Colônia', code: '15403' },
            { name: 'Itajuípe', code: '15502' },
            { name: 'Itamaraju', code: '15601' },
            { name: 'Itamari', code: '15700' },
            { name: 'Itambé', code: '15809' },
            { name: 'Itanagra', code: '15908' },
            { name: 'Itanhém', code: '16005' },
            { name: 'Itaparica', code: '16104' },
            { name: 'Itapé', code: '16203' },
            { name: 'Itapebi', code: '16302' },
            { name: 'Itapetinga', code: '16401' },
            { name: 'Itapicuru', code: '16500' },
            { name: 'Itapitanga', code: '16609' },
            { name: 'Itaquara', code: '16708' },
            { name: 'Itarantim', code: '16807' },
            { name: 'Itatim', code: '16856' },
            { name: 'Itiruçu', code: '16906' },
            { name: 'Itiúba', code: '17003' },
            { name: 'Itororó', code: '17102' },
            { name: 'Ituaçu', code: '17201' },
            { name: 'Ituberá', code: '17300' },
            { name: 'Iuiu', code: '17334' },
            { name: 'Jaborandi', code: '17359' },
            { name: 'Jacaraci', code: '17409' },
            { name: 'Jacobina', code: '17508' },
            { name: 'Jaguaquara', code: '17607' },
            { name: 'Jaguarari', code: '17706' },
            { name: 'Jaguaripe', code: '17805' },
            { name: 'Jandaíra', code: '17904' },
            { name: 'Jequié', code: '18001' },
            { name: 'Jeremoabo', code: '18100' },
            { name: 'Jiquiriçá', code: '18209' },
            { name: 'Jitaúna', code: '18308' },
            { name: 'João Dourado', code: '18357' },
            { name: 'Juazeiro', code: '18407' },
            { name: 'Jucuruçu', code: '18456' },
            { name: 'Jussara', code: '18506' },
            { name: 'Jussari', code: '18555' },
            { name: 'Jussiape', code: '18605' },
            { name: 'Lafaiete Coutinho', code: '18704' },
            { name: 'Lagoa Real', code: '18753' },
            { name: 'Laje', code: '18803' },
            { name: 'Lajedão', code: '18902' },
            { name: 'Lajedinho', code: '19009' },
            { name: 'Lajedo do Tabocal', code: '19058' },
            { name: 'Lamarão', code: '19108' },
            { name: 'Lapão', code: '19157' },
            { name: 'Lauro de Freitas', code: '19207' },
            { name: 'Lençóis', code: '19306' },
            { name: 'Licínio de Almeida', code: '19405' },
            { name: 'Livramento de Nossa Senhora', code: '19504' },
            { name: 'Luís Eduardo Magalhães', code: '19553' },
            { name: 'Macajuba', code: '19603' },
            { name: 'Macarani', code: '19702' },
            { name: 'Macaúbas', code: '19801' },
            { name: 'Macururé', code: '19900' },
            { name: 'Madre de Deus', code: '19926' },
            { name: 'Maetinga', code: '19959' },
            { name: 'Maiquinique', code: '20007' },
            { name: 'Mairi', code: '20106' },
            { name: 'Malhada', code: '20205' },
            { name: 'Malhada de Pedras', code: '20304' },
            { name: 'Manoel Vitorino', code: '20403' },
            { name: 'Mansidão', code: '20452' },
            { name: 'Maracás', code: '20502' },
            { name: 'Maragogipe', code: '20601' },
            { name: 'Maraú', code: '20700' },
            { name: 'Marcionílio Souza', code: '20809' },
            { name: 'Mascote', code: '20908' },
            { name: 'Mata de São João', code: '21005' },
            { name: 'Matina', code: '21054' },
            { name: 'Medeiros Neto', code: '21104' },
            { name: 'Miguel Calmon', code: '21203' },
            { name: 'Milagres', code: '21302' },
            { name: 'Mirangaba', code: '21401' },
            { name: 'Mirante', code: '21450' },
            { name: 'Monte Santo', code: '21500' },
            { name: 'Morpará', code: '21609' },
            { name: 'Morro do Chapéu', code: '21708' },
            { name: 'Mortugaba', code: '21807' },
            { name: 'Mucugê', code: '21906' },
            { name: 'Mucuri', code: '22003' },
            { name: 'Mulungu do Morro', code: '22052' },
            { name: 'Mundo Novo', code: '22102' },
            { name: 'Muniz Ferreira', code: '22201' },
            { name: 'Muquém do São Francisco', code: '22250' },
            { name: 'Muritiba', code: '22300' },
            { name: 'Mutuípe', code: '22409' },
            { name: 'Nazaré', code: '22508' },
            { name: 'Nilo Peçanha', code: '22607' },
            { name: 'Nordestina', code: '22656' },
            { name: 'Nova Canaã', code: '22706' },
            { name: 'Nova Fátima', code: '22730' },
            { name: 'Nova Ibiá', code: '22755' },
            { name: 'Nova Itarana', code: '22805' },
            { name: 'Nova Redenção', code: '22854' },
            { name: 'Nova Soure', code: '22904' },
            { name: 'Nova Viçosa', code: '23001' },
            { name: 'Novo Horizonte', code: '23035' },
            { name: 'Novo Triunfo', code: '23050' },
            { name: 'Olindina', code: '23100' },
            { name: 'Oliveira dos Brejinhos', code: '23209' },
            { name: 'Ouriçangas', code: '23308' },
            { name: 'Ourolândia', code: '23357' },
            { name: 'Palmas de Monte Alto', code: '23407' },
            { name: 'Palmeiras', code: '23506' },
            { name: 'Paramirim', code: '23605' },
            { name: 'Paratinga', code: '23704' },
            { name: 'Paripiranga', code: '23803' },
            { name: 'Pau Brasil', code: '23902' },
            { name: 'Paulo Afonso', code: '24009' },
            { name: 'Pé de Serra', code: '24058' },
            { name: 'Pedrão', code: '24108' },
            { name: 'Pedro Alexandre', code: '24207' },
            { name: 'Piatã', code: '24306' },
            { name: 'Pilão Arcado', code: '24405' },
            { name: 'Pindaí', code: '24504' },
            { name: 'Pindobaçu', code: '24603' },
            { name: 'Pintadas', code: '24652' },
            { name: 'Piraí do Norte', code: '24678' },
            { name: 'Piripá', code: '24702' },
            { name: 'Piritiba', code: '24801' },
            { name: 'Planaltino', code: '24900' },
            { name: 'Planalto', code: '25006' },
            { name: 'Poções', code: '25105' },
            { name: 'Pojuca', code: '25204' },
            { name: 'Ponto Novo', code: '25253' },
            { name: 'Porto Seguro', code: '25303' },
            { name: 'Potiraguá', code: '25402' },
            { name: 'Prado', code: '25501' },
            { name: 'Presidente Dutra', code: '25600' },
            { name: 'Presidente Jânio Quadros', code: '25709' },
            { name: 'Presidente Tancredo Neves', code: '25758' },
            { name: 'Queimadas', code: '25808' },
            { name: 'Quijingue', code: '25907' },
            { name: 'Quixabeira', code: '25931' },
            { name: 'Rafael Jambeiro', code: '25956' },
            { name: 'Remanso', code: '26004' },
            { name: 'Retirolândia', code: '26103' },
            { name: 'Riachão das Neves', code: '26202' },
            { name: 'Riachão do Jacuípe', code: '26301' },
            { name: 'Riacho de Santana', code: '26400' },
            { name: 'Ribeira do Amparo', code: '26509' },
            { name: 'Ribeira do Pombal', code: '26608' },
            { name: 'Ribeirão do Largo', code: '26657' },
            { name: 'Rio de Contas', code: '26707' },
            { name: 'Rio do Antônio', code: '26806' },
            { name: 'Rio do Pires', code: '26905' },
            { name: 'Rio Real', code: '27002' },
            { name: 'Rodelas', code: '27101' },
            { name: 'Ruy Barbosa', code: '27200' },
            { name: 'Salinas da Margarida', code: '27309' },
            { name: 'Salvador', code: '27408' },
            { name: 'Santa Bárbara', code: '27507' },
            { name: 'Santa Brígida', code: '27606' },
            { name: 'Santa Cruz Cabrália', code: '27705' },
            { name: 'Santa Cruz da Vitória', code: '27804' },
            { name: 'Santa Inês', code: '27903' },
            { name: 'Santa Luzia', code: '28059' },
            { name: 'Santa Maria da Vitória', code: '28109' },
            { name: 'Santa Rita de Cássia', code: '28406' },
            { name: 'Santa Terezinha', code: '28505' },
            { name: 'Santaluz', code: '28000' },
            { name: 'Santana', code: '28208' },
            { name: 'Santanópolis', code: '28307' },
            { name: 'Santo Amaro', code: '28604' },
            { name: 'Santo Antônio de Jesus', code: '28703' },
            { name: 'Santo Estêvão', code: '28802' },
            { name: 'São Desidério', code: '28901' },
            { name: 'São Domingos', code: '28950' },
            { name: 'São Felipe', code: '29107' },
            { name: 'São Félix', code: '29008' },
            { name: 'São Félix do Coribe', code: '29057' },
            { name: 'São Francisco do Conde', code: '29206' },
            { name: 'São Gabriel', code: '29255' },
            { name: 'São Gonçalo dos Campos', code: '29305' },
            { name: 'São José da Vitória', code: '29354' },
            { name: 'São José do Jacuípe', code: '29370' },
            { name: 'São Miguel das Matas', code: '29404' },
            { name: 'São Sebastião do Passé', code: '29503' },
            { name: 'Sapeaçu', code: '29602' },
            { name: 'Sátiro Dias', code: '29701' },
            { name: 'Saubara', code: '29750' },
            { name: 'Saúde', code: '29800' },
            { name: 'Seabra', code: '29909' },
            { name: 'Sebastião Laranjeiras', code: '30006' },
            { name: 'Senhor do Bonfim', code: '30105' },
            { name: 'Sento Sé', code: '30204' },
            { name: 'Serra do Ramalho', code: '30154' },
            { name: 'Serra Dourada', code: '30303' },
            { name: 'Serra Preta', code: '30402' },
            { name: 'Serrinha', code: '30501' },
            { name: 'Serrolândia', code: '30600' },
            { name: 'Simões Filho', code: '30709' },
            { name: 'Sítio do Mato', code: '30758' },
            { name: 'Sítio do Quinto', code: '30766' },
            { name: 'Sobradinho', code: '30774' },
            { name: 'Souto Soares', code: '30808' },
            { name: 'Tabocas do Brejo Velho', code: '30907' },
            { name: 'Tanhaçu', code: '31004' },
            { name: 'Tanque Novo', code: '31053' },
            { name: 'Tanquinho', code: '31103' },
            { name: 'Taperoá', code: '31202' },
            { name: 'Tapiramutá', code: '31301' },
            { name: 'Teixeira de Freitas', code: '31350' },
            { name: 'Teodoro Sampaio', code: '31400' },
            { name: 'Teofilândia', code: '31509' },
            { name: 'Teolândia', code: '31608' },
            { name: 'Terra Nova', code: '31707' },
            { name: 'Tremedal', code: '31806' },
            { name: 'Tucano', code: '31905' },
            { name: 'Uauá', code: '32002' },
            { name: 'Ubaíra', code: '32101' },
            { name: 'Ubaitaba', code: '32200' },
            { name: 'Ubatã', code: '32309' },
            { name: 'Uibaí', code: '32408' },
            { name: 'Umburanas', code: '32457' },
            { name: 'Una', code: '32507' },
            { name: 'Urandi', code: '32606' },
            { name: 'Uruçuca', code: '32705' },
            { name: 'Utinga', code: '32804' },
            { name: 'Valença', code: '32903' },
            { name: 'Valente', code: '33000' },
            { name: 'Várzea da Roça', code: '33059' },
            { name: 'Várzea do Poço', code: '33109' },
            { name: 'Várzea Nova', code: '33158' },
            { name: 'Varzedo', code: '33174' },
            { name: 'Vera Cruz', code: '33208' },
            { name: 'Vereda', code: '33257' },
            { name: 'Vitória da Conquista', code: '33307' },
            { name: 'Wagner', code: '33406' },
            { name: 'Wanderley', code: '33455' },
            { name: 'Wenceslau Guimarães', code: '33505' },
            { name: 'Xique-Xique', code: '33604' },
        ],
    },
    {
        abbreviation: StateAbbreviation.PR,
        name: 'Paraná',
        code: '41',
        cities: [
            { name: 'Abatiá', code: '00103' },
            { name: 'Adrianópolis', code: '00202' },
            { name: 'Agudos do Sul', code: '00301' },
            { name: 'Almirante Tamandaré', code: '00400' },
            { name: 'Altamira do Paraná', code: '00459' },
            { name: 'Alto Paraíso', code: '28625' },
            { name: 'Alto Paraná', code: '00608' },
            { name: 'Alto Piquiri', code: '00707' },
            { name: 'Altônia', code: '00509' },
            { name: 'Alvorada do Sul', code: '00806' },
            { name: 'Amaporã', code: '00905' },
            { name: 'Ampére', code: '01002' },
            { name: 'Anahy', code: '01051' },
            { name: 'Andirá', code: '01101' },
            { name: 'Ângulo', code: '01150' },
            { name: 'Antonina', code: '01200' },
            { name: 'Antônio Olinto', code: '01309' },
            { name: 'Apucarana', code: '01408' },
            { name: 'Arapongas', code: '01507' },
            { name: 'Arapoti', code: '01606' },
            { name: 'Arapuã', code: '01655' },
            { name: 'Araruna', code: '01705' },
            { name: 'Araucária', code: '01804' },
            { name: 'Ariranha do Ivaí', code: '01853' },
            { name: 'Assaí', code: '01903' },
            { name: 'Assis Chateaubriand', code: '02000' },
            { name: 'Astorga', code: '02109' },
            { name: 'Atalaia', code: '02208' },
            { name: 'Balsa Nova', code: '02307' },
            { name: 'Bandeirantes', code: '02406' },
            { name: 'Barbosa Ferraz', code: '02505' },
            { name: 'Barra do Jacaré', code: '02703' },
            { name: 'Barracão', code: '02604' },
            { name: 'Bela Vista da Caroba', code: '02752' },
            { name: 'Bela Vista do Paraíso', code: '02802' },
            { name: 'Bituruna', code: '02901' },
            { name: 'Boa Esperança', code: '03008' },
            { name: 'Boa Esperança do Iguaçu', code: '03024' },
            { name: 'Boa Ventura de São Roque', code: '03040' },
            { name: 'Boa Vista da Aparecida', code: '03057' },
            { name: 'Bocaiúva do Sul', code: '03107' },
            { name: 'Bom Jesus do Sul', code: '03156' },
            { name: 'Bom Sucesso', code: '03206' },
            { name: 'Bom Sucesso do Sul', code: '03222' },
            { name: 'Borrazópolis', code: '03305' },
            { name: 'Braganey', code: '03354' },
            { name: 'Brasilândia do Sul', code: '03370' },
            { name: 'Cafeara', code: '03404' },
            { name: 'Cafelândia', code: '03453' },
            { name: 'Cafezal do Sul', code: '03479' },
            { name: 'Califórnia', code: '03503' },
            { name: 'Cambará', code: '03602' },
            { name: 'Cambé', code: '03701' },
            { name: 'Cambira', code: '03800' },
            { name: 'Campina da Lagoa', code: '03909' },
            { name: 'Campina do Simão', code: '03958' },
            { name: 'Campina Grande do Sul', code: '04006' },
            { name: 'Campo Bonito', code: '04055' },
            { name: 'Campo do Tenente', code: '04105' },
            { name: 'Campo Largo', code: '04204' },
            { name: 'Campo Magro', code: '04253' },
            { name: 'Campo Mourão', code: '04303' },
            { name: 'Cândido de Abreu', code: '04402' },
            { name: 'Candói', code: '04428' },
            { name: 'Cantagalo', code: '04451' },
            { name: 'Capanema', code: '04501' },
            { name: 'Capitão Leônidas Marques', code: '04600' },
            { name: 'Carambeí', code: '04659' },
            { name: 'Carlópolis', code: '04709' },
            { name: 'Cascavel', code: '04808' },
            { name: 'Castro', code: '04907' },
            { name: 'Catanduvas', code: '05003' },
            { name: 'Centenário do Sul', code: '05102' },
            { name: 'Cerro Azul', code: '05201' },
            { name: 'Céu Azul', code: '05300' },
            { name: 'Chopinzinho', code: '05409' },
            { name: 'Cianorte', code: '05508' },
            { name: 'Cidade Gaúcha', code: '05607' },
            { name: 'Clevelândia', code: '05706' },
            { name: 'Colombo', code: '05805' },
            { name: 'Colorado', code: '05904' },
            { name: 'Congonhinhas', code: '06001' },
            { name: 'Conselheiro Mairinck', code: '06100' },
            { name: 'Contenda', code: '06209' },
            { name: 'Corbélia', code: '06308' },
            { name: 'Cornélio Procópio', code: '06407' },
            { name: 'Coronel Domingos Soares', code: '06456' },
            { name: 'Coronel Vivida', code: '06506' },
            { name: 'Corumbataí do Sul', code: '06555' },
            { name: 'Cruz Machado', code: '06803' },
            { name: 'Cruzeiro do Iguaçu', code: '06571' },
            { name: 'Cruzeiro do Oeste', code: '06605' },
            { name: 'Cruzeiro do Sul', code: '06704' },
            { name: 'Cruzmaltina', code: '06852' },
            { name: 'Curitiba', code: '06902' },
            { name: 'Curiúva', code: '07009' },
            { name: 'Diamante do Norte', code: '07108' },
            { name: 'Diamante do Sul', code: '07124' },
            { name: "Diamante D'Oeste", code: '07157' },
            { name: 'Dois Vizinhos', code: '07207' },
            { name: 'Douradina', code: '07256' },
            { name: 'Doutor Camargo', code: '07306' },
            { name: 'Doutor Ulysses', code: '28633' },
            { name: 'Enéas Marques', code: '07405' },
            { name: 'Engenheiro Beltrão', code: '07504' },
            { name: 'Entre Rios do Oeste', code: '07538' },
            { name: 'Esperança Nova', code: '07520' },
            { name: 'Espigão Alto do Iguaçu', code: '07546' },
            { name: 'Farol', code: '07553' },
            { name: 'Faxinal', code: '07603' },
            { name: 'Fazenda Rio Grande', code: '07652' },
            { name: 'Fênix', code: '07702' },
            { name: 'Fernandes Pinheiro', code: '07736' },
            { name: 'Figueira', code: '07751' },
            { name: 'Flor da Serra do Sul', code: '07850' },
            { name: 'Floraí', code: '07801' },
            { name: 'Floresta', code: '07900' },
            { name: 'Florestópolis', code: '08007' },
            { name: 'Flórida', code: '08106' },
            { name: 'Formosa do Oeste', code: '08205' },
            { name: 'Foz do Iguaçu', code: '08304' },
            { name: 'Foz do Jordão', code: '08452' },
            { name: 'Francisco Alves', code: '08320' },
            { name: 'Francisco Beltrão', code: '08403' },
            { name: 'General Carneiro', code: '08502' },
            { name: 'Godoy Moreira', code: '08551' },
            { name: 'Goioerê', code: '08601' },
            { name: 'Goioxim', code: '08650' },
            { name: 'Grandes Rios', code: '08700' },
            { name: 'Guaíra', code: '08809' },
            { name: 'Guairaçá', code: '08908' },
            { name: 'Guamiranga', code: '08957' },
            { name: 'Guapirama', code: '09005' },
            { name: 'Guaporema', code: '09104' },
            { name: 'Guaraci', code: '09203' },
            { name: 'Guaraniaçu', code: '09302' },
            { name: 'Guarapuava', code: '09401' },
            { name: 'Guaraqueçaba', code: '09500' },
            { name: 'Guaratuba', code: '09609' },
            { name: 'Honório Serpa', code: '09658' },
            { name: 'Ibaiti', code: '09708' },
            { name: 'Ibema', code: '09757' },
            { name: 'Ibiporã', code: '09807' },
            { name: 'Icaraíma', code: '09906' },
            { name: 'Iguaraçu', code: '10003' },
            { name: 'Iguatu', code: '10052' },
            { name: 'Imbaú', code: '10078' },
            { name: 'Imbituva', code: '10102' },
            { name: 'Inácio Martins', code: '10201' },
            { name: 'Inajá', code: '10300' },
            { name: 'Indianópolis', code: '10409' },
            { name: 'Ipiranga', code: '10508' },
            { name: 'Iporã', code: '10607' },
            { name: 'Iracema do Oeste', code: '10656' },
            { name: 'Irati', code: '10706' },
            { name: 'Iretama', code: '10805' },
            { name: 'Itaguajé', code: '10904' },
            { name: 'Itaipulândia', code: '10953' },
            { name: 'Itambaracá', code: '11001' },
            { name: 'Itambé', code: '11100' },
            { name: "Itapejara d'Oeste", code: '11209' },
            { name: 'Itaperuçu', code: '11258' },
            { name: 'Itaúna do Sul', code: '11308' },
            { name: 'Ivaí', code: '11407' },
            { name: 'Ivaiporã', code: '11506' },
            { name: 'Ivaté', code: '11555' },
            { name: 'Ivatuba', code: '11605' },
            { name: 'Jaboti', code: '11704' },
            { name: 'Jacarezinho', code: '11803' },
            { name: 'Jaguapitã', code: '11902' },
            { name: 'Jaguariaíva', code: '12009' },
            { name: 'Jandaia do Sul', code: '12108' },
            { name: 'Janiópolis', code: '12207' },
            { name: 'Japira', code: '12306' },
            { name: 'Japurá', code: '12405' },
            { name: 'Jardim Alegre', code: '12504' },
            { name: 'Jardim Olinda', code: '12603' },
            { name: 'Jataizinho', code: '12702' },
            { name: 'Jesuítas', code: '12751' },
            { name: 'Joaquim Távora', code: '12801' },
            { name: 'Jundiaí do Sul', code: '12900' },
            { name: 'Juranda', code: '12959' },
            { name: 'Jussara', code: '13007' },
            { name: 'Kaloré', code: '13106' },
            { name: 'Lapa', code: '13205' },
            { name: 'Laranjal', code: '13254' },
            { name: 'Laranjeiras do Sul', code: '13304' },
            { name: 'Leópolis', code: '13403' },
            { name: 'Lidianópolis', code: '13429' },
            { name: 'Lindoeste', code: '13452' },
            { name: 'Loanda', code: '13502' },
            { name: 'Lobato', code: '13601' },
            { name: 'Londrina', code: '13700' },
            { name: 'Luiziana', code: '13734' },
            { name: 'Lunardelli', code: '13759' },
            { name: 'Lupionópolis', code: '13809' },
            { name: 'Mallet', code: '13908' },
            { name: 'Mamborê', code: '14005' },
            { name: 'Mandaguaçu', code: '14104' },
            { name: 'Mandaguari', code: '14203' },
            { name: 'Mandirituba', code: '14302' },
            { name: 'Manfrinópolis', code: '14351' },
            { name: 'Mangueirinha', code: '14401' },
            { name: 'Manoel Ribas', code: '14500' },
            { name: 'Marechal Cândido Rondon', code: '14609' },
            { name: 'Maria Helena', code: '14708' },
            { name: 'Marialva', code: '14807' },
            { name: 'Marilândia do Sul', code: '14906' },
            { name: 'Marilena', code: '15002' },
            { name: 'Mariluz', code: '15101' },
            { name: 'Maringá', code: '15200' },
            { name: 'Mariópolis', code: '15309' },
            { name: 'Maripá', code: '15358' },
            { name: 'Marmeleiro', code: '15408' },
            { name: 'Marquinho', code: '15457' },
            { name: 'Marumbi', code: '15507' },
            { name: 'Matelândia', code: '15606' },
            { name: 'Matinhos', code: '15705' },
            { name: 'Mato Rico', code: '15739' },
            { name: 'Mauá da Serra', code: '15754' },
            { name: 'Medianeira', code: '15804' },
            { name: 'Mercedes', code: '15853' },
            { name: 'Mirador', code: '15903' },
            { name: 'Miraselva', code: '16000' },
            { name: 'Missal', code: '16059' },
            { name: 'Moreira Sales', code: '16109' },
            { name: 'Morretes', code: '16208' },
            { name: 'Munhoz de Melo', code: '16307' },
            { name: 'Nossa Senhora das Graças', code: '16406' },
            { name: 'Nova Aliança do Ivaí', code: '16505' },
            { name: 'Nova América da Colina', code: '16604' },
            { name: 'Nova Aurora', code: '16703' },
            { name: 'Nova Cantu', code: '16802' },
            { name: 'Nova Esperança', code: '16901' },
            { name: 'Nova Esperança do Sudoeste', code: '16950' },
            { name: 'Nova Fátima', code: '17008' },
            { name: 'Nova Laranjeiras', code: '17057' },
            { name: 'Nova Londrina', code: '17107' },
            { name: 'Nova Olímpia', code: '17206' },
            { name: 'Nova Prata do Iguaçu', code: '17255' },
            { name: 'Nova Santa Bárbara', code: '17214' },
            { name: 'Nova Santa Rosa', code: '17222' },
            { name: 'Nova Tebas', code: '17271' },
            { name: 'Novo Itacolomi', code: '17297' },
            { name: 'Ortigueira', code: '17305' },
            { name: 'Ourizona', code: '17404' },
            { name: 'Ouro Verde do Oeste', code: '17453' },
            { name: 'Paiçandu', code: '17503' },
            { name: 'Palmas', code: '17602' },
            { name: 'Palmeira', code: '17701' },
            { name: 'Palmital', code: '17800' },
            { name: 'Palotina', code: '17909' },
            { name: 'Paraíso do Norte', code: '18006' },
            { name: 'Paranacity', code: '18105' },
            { name: 'Paranaguá', code: '18204' },
            { name: 'Paranapoema', code: '18303' },
            { name: 'Paranavaí', code: '18402' },
            { name: 'Pato Bragado', code: '18451' },
            { name: 'Pato Branco', code: '18501' },
            { name: 'Paula Freitas', code: '18600' },
            { name: 'Paulo Frontin', code: '18709' },
            { name: 'Peabiru', code: '18808' },
            { name: 'Perobal', code: '18857' },
            { name: 'Pérola', code: '18907' },
            { name: "Pérola d'Oeste", code: '19004' },
            { name: 'Piên', code: '19103' },
            { name: 'Pinhais', code: '19152' },
            { name: 'Pinhal de São Bento', code: '19251' },
            { name: 'Pinhalão', code: '19202' },
            { name: 'Pinhão', code: '19301' },
            { name: 'Piraí do Sul', code: '19400' },
            { name: 'Piraquara', code: '19509' },
            { name: 'Pitanga', code: '19608' },
            { name: 'Pitangueiras', code: '19657' },
            { name: 'Planaltina do Paraná', code: '19707' },
            { name: 'Planalto', code: '19806' },
            { name: 'Ponta Grossa', code: '19905' },
            { name: 'Pontal do Paraná', code: '19954' },
            { name: 'Porecatu', code: '20002' },
            { name: 'Porto Amazonas', code: '20101' },
            { name: 'Porto Barreiro', code: '20150' },
            { name: 'Porto Rico', code: '20200' },
            { name: 'Porto Vitória', code: '20309' },
            { name: 'Prado Ferreira', code: '20333' },
            { name: 'Pranchita', code: '20358' },
            { name: 'Presidente Castelo Branco', code: '20408' },
            { name: 'Primeiro de Maio', code: '20507' },
            { name: 'Prudentópolis', code: '20606' },
            { name: 'Quarto Centenário', code: '20655' },
            { name: 'Quatiguá', code: '20705' },
            { name: 'Quatro Barras', code: '20804' },
            { name: 'Quatro Pontes', code: '20853' },
            { name: 'Quedas do Iguaçu', code: '20903' },
            { name: 'Querência do Norte', code: '21000' },
            { name: 'Quinta do Sol', code: '21109' },
            { name: 'Quitandinha', code: '21208' },
            { name: 'Ramilândia', code: '21257' },
            { name: 'Rancho Alegre', code: '21307' },
            { name: "Rancho Alegre D'Oeste", code: '21356' },
            { name: 'Realeza', code: '21406' },
            { name: 'Rebouças', code: '21505' },
            { name: 'Renascença', code: '21604' },
            { name: 'Reserva', code: '21703' },
            { name: 'Reserva do Iguaçu', code: '21752' },
            { name: 'Ribeirão Claro', code: '21802' },
            { name: 'Ribeirão do Pinhal', code: '21901' },
            { name: 'Rio Azul', code: '22008' },
            { name: 'Rio Bom', code: '22107' },
            { name: 'Rio Bonito do Iguaçu', code: '22156' },
            { name: 'Rio Branco do Ivaí', code: '22172' },
            { name: 'Rio Branco do Sul', code: '22206' },
            { name: 'Rio Negro', code: '22305' },
            { name: 'Rolândia', code: '22404' },
            { name: 'Roncador', code: '22503' },
            { name: 'Rondon', code: '22602' },
            { name: 'Rosário do Ivaí', code: '22651' },
            { name: 'Sabáudia', code: '22701' },
            { name: 'Salgado Filho', code: '22800' },
            { name: 'Salto do Itararé', code: '22909' },
            { name: 'Salto do Lontra', code: '23006' },
            { name: 'Santa Amélia', code: '23105' },
            { name: 'Santa Cecília do Pavão', code: '23204' },
            { name: 'Santa Cruz de Monte Castelo', code: '23303' },
            { name: 'Santa Fé', code: '23402' },
            { name: 'Santa Helena', code: '23501' },
            { name: 'Santa Inês', code: '23600' },
            { name: 'Santa Isabel do Ivaí', code: '23709' },
            { name: 'Santa Izabel do Oeste', code: '23808' },
            { name: 'Santa Lúcia', code: '23824' },
            { name: 'Santa Maria do Oeste', code: '23857' },
            { name: 'Santa Mariana', code: '23907' },
            { name: 'Santa Mônica', code: '23956' },
            { name: 'Santa Tereza do Oeste', code: '24020' },
            { name: 'Santa Terezinha de Itaipu', code: '24053' },
            { name: 'Santana do Itararé', code: '24004' },
            { name: 'Santo Antônio da Platina', code: '24103' },
            { name: 'Santo Antônio do Caiuá', code: '24202' },
            { name: 'Santo Antônio do Paraíso', code: '24301' },
            { name: 'Santo Antônio do Sudoeste', code: '24400' },
            { name: 'Santo Inácio', code: '24509' },
            { name: 'São Carlos do Ivaí', code: '24608' },
            { name: 'São Jerônimo da Serra', code: '24707' },
            { name: 'São João', code: '24806' },
            { name: 'São João do Caiuá', code: '24905' },
            { name: 'São João do Ivaí', code: '25001' },
            { name: 'São João do Triunfo', code: '25100' },
            { name: 'São Jorge do Ivaí', code: '25308' },
            { name: 'São Jorge do Patrocínio', code: '25357' },
            { name: "São Jorge d'Oeste", code: '25209' },
            { name: 'São José da Boa Vista', code: '25407' },
            { name: 'São José das Palmeiras', code: '25456' },
            { name: 'São José dos Pinhais', code: '25506' },
            { name: 'São Manoel do Paraná', code: '25555' },
            { name: 'São Mateus do Sul', code: '25605' },
            { name: 'São Miguel do Iguaçu', code: '25704' },
            { name: 'São Pedro do Iguaçu', code: '25753' },
            { name: 'São Pedro do Ivaí', code: '25803' },
            { name: 'São Pedro do Paraná', code: '25902' },
            { name: 'São Sebastião da Amoreira', code: '26009' },
            { name: 'São Tomé', code: '26108' },
            { name: 'Sapopema', code: '26207' },
            { name: 'Sarandi', code: '26256' },
            { name: 'Saudade do Iguaçu', code: '26272' },
            { name: 'Sengés', code: '26306' },
            { name: 'Serranópolis do Iguaçu', code: '26355' },
            { name: 'Sertaneja', code: '26405' },
            { name: 'Sertanópolis', code: '26504' },
            { name: 'Siqueira Campos', code: '26603' },
            { name: 'Sulina', code: '26652' },
            { name: 'Tamarana', code: '26678' },
            { name: 'Tamboara', code: '26702' },
            { name: 'Tapejara', code: '26801' },
            { name: 'Tapira', code: '26900' },
            { name: 'Teixeira Soares', code: '27007' },
            { name: 'Telêmaco Borba', code: '27106' },
            { name: 'Terra Boa', code: '27205' },
            { name: 'Terra Rica', code: '27304' },
            { name: 'Terra Roxa', code: '27403' },
            { name: 'Tibagi', code: '27502' },
            { name: 'Tijucas do Sul', code: '27601' },
            { name: 'Toledo', code: '27700' },
            { name: 'Tomazina', code: '27809' },
            { name: 'Três Barras do Paraná', code: '27858' },
            { name: 'Tunas do Paraná', code: '27882' },
            { name: 'Tuneiras do Oeste', code: '27908' },
            { name: 'Tupãssi', code: '27957' },
            { name: 'Turvo', code: '27965' },
            { name: 'Ubiratã', code: '28005' },
            { name: 'Umuarama', code: '28104' },
            { name: 'União da Vitória', code: '28203' },
            { name: 'Uniflor', code: '28302' },
            { name: 'Uraí', code: '28401' },
            { name: 'Ventania', code: '28534' },
            { name: 'Vera Cruz do Oeste', code: '28559' },
            { name: 'Verê', code: '28609' },
            { name: 'Virmond', code: '28658' },
            { name: 'Vitorino', code: '28708' },
            { name: 'Wenceslau Braz', code: '28500' },
            { name: 'Xambrê', code: '28807' },
        ],
    },
    {
        abbreviation: StateAbbreviation.SC,
        name: 'Santa Catarina',
        code: '42',
        cities: [
            { name: 'Abdon Batista', code: '00051' },
            { name: 'Abelardo Luz', code: '00101' },
            { name: 'Agrolândia', code: '00200' },
            { name: 'Agronômica', code: '00309' },
            { name: 'Água Doce', code: '00408' },
            { name: 'Águas de Chapecó', code: '00507' },
            { name: 'Águas Frias', code: '00556' },
            { name: 'Águas Mornas', code: '00606' },
            { name: 'Alfredo Wagner', code: '00705' },
            { name: 'Alto Bela Vista', code: '00754' },
            { name: 'Anchieta', code: '00804' },
            { name: 'Angelina', code: '00903' },
            { name: 'Anita Garibaldi', code: '01000' },
            { name: 'Anitápolis', code: '01109' },
            { name: 'Antônio Carlos', code: '01208' },
            { name: 'Apiúna', code: '01257' },
            { name: 'Arabutã', code: '01273' },
            { name: 'Araquari', code: '01307' },
            { name: 'Araranguá', code: '01406' },
            { name: 'Armazém', code: '01505' },
            { name: 'Arroio Trinta', code: '01604' },
            { name: 'Arvoredo', code: '01653' },
            { name: 'Ascurra', code: '01703' },
            { name: 'Atalanta', code: '01802' },
            { name: 'Aurora', code: '01901' },
            { name: 'Balneário Arroio do Silva', code: '01950' },
            { name: 'Balneário Barra do Sul', code: '02057' },
            { name: 'Balneário Camboriú', code: '02008' },
            { name: 'Balneário Gaivota', code: '02073' },
            { name: 'Balneário Piçarras', code: '12809' },
            { name: 'Balneário Rincão', code: '20000' },
            { name: 'Bandeirante', code: '02081' },
            { name: 'Barra Bonita', code: '02099' },
            { name: 'Barra Velha', code: '02107' },
            { name: 'Bela Vista do Toldo', code: '02131' },
            { name: 'Belmonte', code: '02156' },
            { name: 'Benedito Novo', code: '02206' },
            { name: 'Biguaçu', code: '02305' },
            { name: 'Blumenau', code: '02404' },
            { name: 'Bocaina do Sul', code: '02438' },
            { name: 'Bom Jardim da Serra', code: '02503' },
            { name: 'Bom Jesus', code: '02537' },
            { name: 'Bom Jesus do Oeste', code: '02578' },
            { name: 'Bom Retiro', code: '02602' },
            { name: 'Bombinhas', code: '02453' },
            { name: 'Botuverá', code: '02701' },
            { name: 'Braço do Norte', code: '02800' },
            { name: 'Braço do Trombudo', code: '02859' },
            { name: 'Brunópolis', code: '02875' },
            { name: 'Brusque', code: '02909' },
            { name: 'Caçador', code: '03006' },
            { name: 'Caibi', code: '03105' },
            { name: 'Calmon', code: '03154' },
            { name: 'Camboriú', code: '03204' },
            { name: 'Campo Alegre', code: '03303' },
            { name: 'Campo Belo do Sul', code: '03402' },
            { name: 'Campo Erê', code: '03501' },
            { name: 'Campos Novos', code: '03600' },
            { name: 'Canelinha', code: '03709' },
            { name: 'Canoinhas', code: '03808' },
            { name: 'Capão Alto', code: '03253' },
            { name: 'Capinzal', code: '03907' },
            { name: 'Capivari de Baixo', code: '03956' },
            { name: 'Catanduvas', code: '04004' },
            { name: 'Caxambu do Sul', code: '04103' },
            { name: 'Celso Ramos', code: '04152' },
            { name: 'Cerro Negro', code: '04178' },
            { name: 'Chapadão do Lageado', code: '04194' },
            { name: 'Chapecó', code: '04202' },
            { name: 'Cocal do Sul', code: '04251' },
            { name: 'Concórdia', code: '04301' },
            { name: 'Cordilheira Alta', code: '04350' },
            { name: 'Coronel Freitas', code: '04400' },
            { name: 'Coronel Martins', code: '04459' },
            { name: 'Correia Pinto', code: '04558' },
            { name: 'Corupá', code: '04509' },
            { name: 'Criciúma', code: '04608' },
            { name: 'Cunha Porã', code: '04707' },
            { name: 'Cunhataí', code: '04756' },
            { name: 'Curitibanos', code: '04806' },
            { name: 'Descanso', code: '04905' },
            { name: 'Dionísio Cerqueira', code: '05001' },
            { name: 'Dona Emma', code: '05100' },
            { name: 'Doutor Pedrinho', code: '05159' },
            { name: 'Entre Rios', code: '05175' },
            { name: 'Ermo', code: '05191' },
            { name: 'Erval Velho', code: '05209' },
            { name: 'Faxinal dos Guedes', code: '05308' },
            { name: 'Flor do Sertão', code: '05357' },
            { name: 'Florianópolis', code: '05407' },
            { name: 'Formosa do Sul', code: '05431' },
            { name: 'Forquilhinha', code: '05456' },
            { name: 'Fraiburgo', code: '05506' },
            { name: 'Frei Rogério', code: '05555' },
            { name: 'Galvão', code: '05605' },
            { name: 'Garopaba', code: '05704' },
            { name: 'Garuva', code: '05803' },
            { name: 'Gaspar', code: '05902' },
            { name: 'Governador Celso Ramos', code: '06009' },
            { name: 'Grão-Pará', code: '06108' },
            { name: 'Gravatal', code: '06207' },
            { name: 'Guabiruba', code: '06306' },
            { name: 'Guaraciaba', code: '06405' },
            { name: 'Guaramirim', code: '06504' },
            { name: 'Guarujá do Sul', code: '06603' },
            { name: 'Guatambú', code: '06652' },
            { name: "Herval d'Oeste", code: '06702' },
            { name: 'Ibiam', code: '06751' },
            { name: 'Ibicaré', code: '06801' },
            { name: 'Ibirama', code: '06900' },
            { name: 'Içara', code: '07007' },
            { name: 'Ilhota', code: '07106' },
            { name: 'Imaruí', code: '07205' },
            { name: 'Imbituba', code: '07304' },
            { name: 'Imbuia', code: '07403' },
            { name: 'Indaial', code: '07502' },
            { name: 'Iomerê', code: '07577' },
            { name: 'Ipira', code: '07601' },
            { name: 'Iporã do Oeste', code: '07650' },
            { name: 'Ipuaçu', code: '07684' },
            { name: 'Ipumirim', code: '07700' },
            { name: 'Iraceminha', code: '07759' },
            { name: 'Irani', code: '07809' },
            { name: 'Irati', code: '07858' },
            { name: 'Irineópolis', code: '07908' },
            { name: 'Itá', code: '08005' },
            { name: 'Itaiópolis', code: '08104' },
            { name: 'Itajaí', code: '08203' },
            { name: 'Itapema', code: '08302' },
            { name: 'Itapiranga', code: '08401' },
            { name: 'Itapoá', code: '08450' },
            { name: 'Ituporanga', code: '08500' },
            { name: 'Jaborá', code: '08609' },
            { name: 'Jacinto Machado', code: '08708' },
            { name: 'Jaguaruna', code: '08807' },
            { name: 'Jaraguá do Sul', code: '08906' },
            { name: 'Jardinópolis', code: '08955' },
            { name: 'Joaçaba', code: '09003' },
            { name: 'Joinville', code: '09102' },
            { name: 'José Boiteux', code: '09151' },
            { name: 'Jupiá', code: '09177' },
            { name: 'Lacerdópolis', code: '09201' },
            { name: 'Lages', code: '09300' },
            { name: 'Laguna', code: '09409' },
            { name: 'Lajeado Grande', code: '09458' },
            { name: 'Laurentino', code: '09508' },
            { name: 'Lauro Müller', code: '09607' },
            { name: 'Lebon Régis', code: '09706' },
            { name: 'Leoberto Leal', code: '09805' },
            { name: 'Lindóia do Sul', code: '09854' },
            { name: 'Lontras', code: '09904' },
            { name: 'Luiz Alves', code: '10001' },
            { name: 'Luzerna', code: '10035' },
            { name: 'Macieira', code: '10050' },
            { name: 'Mafra', code: '10100' },
            { name: 'Major Gercino', code: '10209' },
            { name: 'Major Vieira', code: '10308' },
            { name: 'Maracajá', code: '10407' },
            { name: 'Maravilha', code: '10506' },
            { name: 'Marema', code: '10555' },
            { name: 'Massaranduba', code: '10605' },
            { name: 'Matos Costa', code: '10704' },
            { name: 'Meleiro', code: '10803' },
            { name: 'Mirim Doce', code: '10852' },
            { name: 'Modelo', code: '10902' },
            { name: 'Mondaí', code: '11009' },
            { name: 'Monte Carlo', code: '11058' },
            { name: 'Monte Castelo', code: '11108' },
            { name: 'Morro da Fumaça', code: '11207' },
            { name: 'Morro Grande', code: '11256' },
            { name: 'Navegantes', code: '11306' },
            { name: 'Nova Erechim', code: '11405' },
            { name: 'Nova Itaberaba', code: '11454' },
            { name: 'Nova Trento', code: '11504' },
            { name: 'Nova Veneza', code: '11603' },
            { name: 'Novo Horizonte', code: '11652' },
            { name: 'Orleans', code: '11702' },
            { name: 'Otacílio Costa', code: '11751' },
            { name: 'Ouro', code: '11801' },
            { name: 'Ouro Verde', code: '11850' },
            { name: 'Paial', code: '11876' },
            { name: 'Painel', code: '11892' },
            { name: 'Palhoça', code: '11900' },
            { name: 'Palma Sola', code: '12007' },
            { name: 'Palmeira', code: '12056' },
            { name: 'Palmitos', code: '12106' },
            { name: 'Papanduva', code: '12205' },
            { name: 'Paraíso', code: '12239' },
            { name: 'Passo de Torres', code: '12254' },
            { name: 'Passos Maia', code: '12270' },
            { name: 'Paulo Lopes', code: '12304' },
            { name: 'Pedras Grandes', code: '12403' },
            { name: 'Penha', code: '12502' },
            { name: 'Peritiba', code: '12601' },
            { name: 'Pescaria Brava', code: '12650' },
            { name: 'Petrolândia', code: '12700' },
            { name: 'Pinhalzinho', code: '12908' },
            { name: 'Pinheiro Preto', code: '13005' },
            { name: 'Piratuba', code: '13104' },
            { name: 'Planalto Alegre', code: '13153' },
            { name: 'Pomerode', code: '13203' },
            { name: 'Ponte Alta', code: '13302' },
            { name: 'Ponte Alta do Norte', code: '13351' },
            { name: 'Ponte Serrada', code: '13401' },
            { name: 'Porto Belo', code: '13500' },
            { name: 'Porto União', code: '13609' },
            { name: 'Pouso Redondo', code: '13708' },
            { name: 'Praia Grande', code: '13807' },
            { name: 'Presidente Castello Branco', code: '13906' },
            { name: 'Presidente Getúlio', code: '14003' },
            { name: 'Presidente Nereu', code: '14102' },
            { name: 'Princesa', code: '14151' },
            { name: 'Quilombo', code: '14201' },
            { name: 'Rancho Queimado', code: '14300' },
            { name: 'Rio das Antas', code: '14409' },
            { name: 'Rio do Campo', code: '14508' },
            { name: 'Rio do Oeste', code: '14607' },
            { name: 'Rio do Sul', code: '14805' },
            { name: 'Rio dos Cedros', code: '14706' },
            { name: 'Rio Fortuna', code: '14904' },
            { name: 'Rio Negrinho', code: '15000' },
            { name: 'Rio Rufino', code: '15059' },
            { name: 'Riqueza', code: '15075' },
            { name: 'Rodeio', code: '15109' },
            { name: 'Romelândia', code: '15208' },
            { name: 'Salete', code: '15307' },
            { name: 'Saltinho', code: '15356' },
            { name: 'Salto Veloso', code: '15406' },
            { name: 'Sangão', code: '15455' },
            { name: 'Santa Cecília', code: '15505' },
            { name: 'Santa Helena', code: '15554' },
            { name: 'Santa Rosa de Lima', code: '15604' },
            { name: 'Santa Rosa do Sul', code: '15653' },
            { name: 'Santa Terezinha', code: '15679' },
            { name: 'Santa Terezinha do Progresso', code: '15687' },
            { name: 'Santiago do Sul', code: '15695' },
            { name: 'Santo Amaro da Imperatriz', code: '15703' },
            { name: 'São Bento do Sul', code: '15802' },
            { name: 'São Bernardino', code: '15752' },
            { name: 'São Bonifácio', code: '15901' },
            { name: 'São Carlos', code: '16008' },
            { name: 'São Cristóvão do Sul', code: '16057' },
            { name: 'São Domingos', code: '16107' },
            { name: 'São Francisco do Sul', code: '16206' },
            { name: 'São João Batista', code: '16305' },
            { name: 'São João do Itaperiú', code: '16354' },
            { name: 'São João do Oeste', code: '16255' },
            { name: 'São João do Sul', code: '16404' },
            { name: 'São Joaquim', code: '16503' },
            { name: 'São José', code: '16602' },
            { name: 'São José do Cedro', code: '16701' },
            { name: 'São José do Cerrito', code: '16800' },
            { name: 'São Lourenço do Oeste', code: '16909' },
            { name: 'São Ludgero', code: '17006' },
            { name: 'São Martinho', code: '17105' },
            { name: 'São Miguel da Boa Vista', code: '17154' },
            { name: 'São Miguel do Oeste', code: '17204' },
            { name: 'São Pedro de Alcântara', code: '17253' },
            { name: 'Saudades', code: '17303' },
            { name: 'Schroeder', code: '17402' },
            { name: 'Seara', code: '17501' },
            { name: 'Serra Alta', code: '17550' },
            { name: 'Siderópolis', code: '17600' },
            { name: 'Sombrio', code: '17709' },
            { name: 'Sul Brasil', code: '17758' },
            { name: 'Taió', code: '17808' },
            { name: 'Tangará', code: '17907' },
            { name: 'Tigrinhos', code: '17956' },
            { name: 'Tijucas', code: '18004' },
            { name: 'Timbé do Sul', code: '18103' },
            { name: 'Timbó', code: '18202' },
            { name: 'Timbó Grande', code: '18251' },
            { name: 'Três Barras', code: '18301' },
            { name: 'Treviso', code: '18350' },
            { name: 'Treze de Maio', code: '18400' },
            { name: 'Treze Tílias', code: '18509' },
            { name: 'Trombudo Central', code: '18608' },
            { name: 'Tubarão', code: '18707' },
            { name: 'Tunápolis', code: '18756' },
            { name: 'Turvo', code: '18806' },
            { name: 'União do Oeste', code: '18855' },
            { name: 'Urubici', code: '18905' },
            { name: 'Urupema', code: '18954' },
            { name: 'Urussanga', code: '19002' },
            { name: 'Vargeão', code: '19101' },
            { name: 'Vargem', code: '19150' },
            { name: 'Vargem Bonita', code: '19176' },
            { name: 'Vidal Ramos', code: '19200' },
            { name: 'Videira', code: '19309' },
            { name: 'Vitor Meireles', code: '19358' },
            { name: 'Witmarsum', code: '19408' },
            { name: 'Xanxerê', code: '19507' },
            { name: 'Xavantina', code: '19606' },
            { name: 'Xaxim', code: '19705' },
            { name: 'Zortéa', code: '19853' },
        ],
    },
    {
        abbreviation: StateAbbreviation.PE,
        name: 'Pernambuco',
        code: '26',
        cities: [
            { name: 'Abreu e Lima', code: '00054' },
            { name: 'Afogados da Ingazeira', code: '00104' },
            { name: 'Afrânio', code: '00203' },
            { name: 'Agrestina', code: '00302' },
            { name: 'Água Preta', code: '00401' },
            { name: 'Águas Belas', code: '00500' },
            { name: 'Alagoinha', code: '00609' },
            { name: 'Aliança', code: '00708' },
            { name: 'Altinho', code: '00807' },
            { name: 'Amaraji', code: '00906' },
            { name: 'Angelim', code: '01003' },
            { name: 'Araçoiaba', code: '01052' },
            { name: 'Araripina', code: '01102' },
            { name: 'Arcoverde', code: '01201' },
            { name: 'Barra de Guabiraba', code: '01300' },
            { name: 'Barreiros', code: '01409' },
            { name: 'Belém de Maria', code: '01508' },
            { name: 'Belém do São Francisco', code: '01607' },
            { name: 'Belo Jardim', code: '01706' },
            { name: 'Betânia', code: '01805' },
            { name: 'Bezerros', code: '01904' },
            { name: 'Bodocó', code: '02001' },
            { name: 'Bom Conselho', code: '02100' },
            { name: 'Bom Jardim', code: '02209' },
            { name: 'Bonito', code: '02308' },
            { name: 'Brejão', code: '02407' },
            { name: 'Brejinho', code: '02506' },
            { name: 'Brejo da Madre de Deus', code: '02605' },
            { name: 'Buenos Aires', code: '02704' },
            { name: 'Buíque', code: '02803' },
            { name: 'Cabo de Santo Agostinho', code: '02902' },
            { name: 'Cabrobó', code: '03009' },
            { name: 'Cachoeirinha', code: '03108' },
            { name: 'Caetés', code: '03207' },
            { name: 'Calçado', code: '03306' },
            { name: 'Calumbi', code: '03405' },
            { name: 'Camaragibe', code: '03454' },
            { name: 'Camocim de São Félix', code: '03504' },
            { name: 'Camutanga', code: '03603' },
            { name: 'Canhotinho', code: '03702' },
            { name: 'Capoeiras', code: '03801' },
            { name: 'Carnaíba', code: '03900' },
            { name: 'Carnaubeira da Penha', code: '03926' },
            { name: 'Carpina', code: '04007' },
            { name: 'Caruaru', code: '04106' },
            { name: 'Casinhas', code: '04155' },
            { name: 'Catende', code: '04205' },
            { name: 'Cedro', code: '04304' },
            { name: 'Chã de Alegria', code: '04403' },
            { name: 'Chã Grande', code: '04502' },
            { name: 'Condado', code: '04601' },
            { name: 'Correntes', code: '04700' },
            { name: 'Cortês', code: '04809' },
            { name: 'Cumaru', code: '04908' },
            { name: 'Cupira', code: '05004' },
            { name: 'Custódia', code: '05103' },
            { name: 'Dormentes', code: '05152' },
            { name: 'Escada', code: '05202' },
            { name: 'Exu', code: '05301' },
            { name: 'Feira Nova', code: '05400' },
            { name: 'Fernando de Noronha', code: '05459' },
            { name: 'Ferreiros', code: '05509' },
            { name: 'Flores', code: '05608' },
            { name: 'Floresta', code: '05707' },
            { name: 'Frei Miguelinho', code: '05806' },
            { name: 'Gameleira', code: '05905' },
            { name: 'Garanhuns', code: '06002' },
            { name: 'Glória do Goitá', code: '06101' },
            { name: 'Goiana', code: '06200' },
            { name: 'Granito', code: '06309' },
            { name: 'Gravatá', code: '06408' },
            { name: 'Iati', code: '06507' },
            { name: 'Ibimirim', code: '06606' },
            { name: 'Ibirajuba', code: '06705' },
            { name: 'Igarassu', code: '06804' },
            { name: 'Iguaracy', code: '06903' },
            { name: 'Ilha de Itamaracá', code: '07604' },
            { name: 'Inajá', code: '07000' },
            { name: 'Ingazeira', code: '07109' },
            { name: 'Ipojuca', code: '07208' },
            { name: 'Ipubi', code: '07307' },
            { name: 'Itacuruba', code: '07406' },
            { name: 'Itaíba', code: '07505' },
            { name: 'Itambé', code: '07653' },
            { name: 'Itapetim', code: '07703' },
            { name: 'Itapissuma', code: '07752' },
            { name: 'Itaquitinga', code: '07802' },
            { name: 'Jaboatão dos Guararapes', code: '07901' },
            { name: 'Jaqueira', code: '07950' },
            { name: 'Jataúba', code: '08008' },
            { name: 'Jatobá', code: '08057' },
            { name: 'João Alfredo', code: '08107' },
            { name: 'Joaquim Nabuco', code: '08206' },
            { name: 'Jucati', code: '08255' },
            { name: 'Jupi', code: '08305' },
            { name: 'Jurema', code: '08404' },
            { name: 'Lagoa de Itaenga', code: '08503' },
            { name: 'Lagoa do Carro', code: '08453' },
            { name: 'Lagoa do Ouro', code: '08602' },
            { name: 'Lagoa dos Gatos', code: '08701' },
            { name: 'Lagoa Grande', code: '08750' },
            { name: 'Lajedo', code: '08800' },
            { name: 'Limoeiro', code: '08909' },
            { name: 'Macaparana', code: '09006' },
            { name: 'Machados', code: '09105' },
            { name: 'Manari', code: '09154' },
            { name: 'Maraial', code: '09204' },
            { name: 'Mirandiba', code: '09303' },
            { name: 'Moreilândia', code: '14303' },
            { name: 'Moreno', code: '09402' },
            { name: 'Nazaré da Mata', code: '09501' },
            { name: 'Olinda', code: '09600' },
            { name: 'Orobó', code: '09709' },
            { name: 'Orocó', code: '09808' },
            { name: 'Ouricuri', code: '09907' },
            { name: 'Palmares', code: '10004' },
            { name: 'Palmeirina', code: '10103' },
            { name: 'Panelas', code: '10202' },
            { name: 'Paranatama', code: '10301' },
            { name: 'Parnamirim', code: '10400' },
            { name: 'Passira', code: '10509' },
            { name: 'Paudalho', code: '10608' },
            { name: 'Paulista', code: '10707' },
            { name: 'Pedra', code: '10806' },
            { name: 'Pesqueira', code: '10905' },
            { name: 'Petrolândia', code: '11002' },
            { name: 'Petrolina', code: '11101' },
            { name: 'Poção', code: '11200' },
            { name: 'Pombos', code: '11309' },
            { name: 'Primavera', code: '11408' },
            { name: 'Quipapá', code: '11507' },
            { name: 'Quixaba', code: '11533' },
            { name: 'Recife', code: '11606' },
            { name: 'Riacho das Almas', code: '11705' },
            { name: 'Ribeirão', code: '11804' },
            { name: 'Rio Formoso', code: '11903' },
            { name: 'Sairé', code: '12000' },
            { name: 'Salgadinho', code: '12109' },
            { name: 'Salgueiro', code: '12208' },
            { name: 'Saloá', code: '12307' },
            { name: 'Sanharó', code: '12406' },
            { name: 'Santa Cruz', code: '12455' },
            { name: 'Santa Cruz da Baixa Verde', code: '12471' },
            { name: 'Santa Cruz do Capibaribe', code: '12505' },
            { name: 'Santa Filomena', code: '12554' },
            { name: 'Santa Maria da Boa Vista', code: '12604' },
            { name: 'Santa Maria do Cambucá', code: '12703' },
            { name: 'Santa Terezinha', code: '12802' },
            { name: 'São Benedito do Sul', code: '12901' },
            { name: 'São Bento do Una', code: '13008' },
            { name: 'São Caitano', code: '13107' },
            { name: 'São João', code: '13206' },
            { name: 'São Joaquim do Monte', code: '13305' },
            { name: 'São José da Coroa Grande', code: '13404' },
            { name: 'São José do Belmonte', code: '13503' },
            { name: 'São José do Egito', code: '13602' },
            { name: 'São Lourenço da Mata', code: '13701' },
            { name: 'São Vicente Férrer', code: '13800' },
            { name: 'Serra Talhada', code: '13909' },
            { name: 'Serrita', code: '14006' },
            { name: 'Sertânia', code: '14105' },
            { name: 'Sirinhaém', code: '14204' },
            { name: 'Solidão', code: '14402' },
            { name: 'Surubim', code: '14501' },
            { name: 'Tabira', code: '14600' },
            { name: 'Tacaimbó', code: '14709' },
            { name: 'Tacaratu', code: '14808' },
            { name: 'Tamandaré', code: '14857' },
            { name: 'Taquaritinga do Norte', code: '15003' },
            { name: 'Terezinha', code: '15102' },
            { name: 'Terra Nova', code: '15201' },
            { name: 'Timbaúba', code: '15300' },
            { name: 'Toritama', code: '15409' },
            { name: 'Tracunhaém', code: '15508' },
            { name: 'Trindade', code: '15607' },
            { name: 'Triunfo', code: '15706' },
            { name: 'Tupanatinga', code: '15805' },
            { name: 'Tuparetama', code: '15904' },
            { name: 'Venturosa', code: '16001' },
            { name: 'Verdejante', code: '16100' },
            { name: 'Vertente do Lério', code: '16183' },
            { name: 'Vertentes', code: '16209' },
            { name: 'Vicência', code: '16308' },
            { name: 'Vitória de Santo Antão', code: '16407' },
            { name: 'Xexéu', code: '16506' },
        ],
    },
    {
        abbreviation: StateAbbreviation.TO,
        name: 'Tocantins',
        code: '17',
        cities: [
            { name: 'Abreulândia', code: '00251' },
            { name: 'Aguiarnópolis', code: '00301' },
            { name: 'Aliança do Tocantins', code: '00350' },
            { name: 'Almas', code: '00400' },
            { name: 'Alvorada', code: '00707' },
            { name: 'Ananás', code: '01002' },
            { name: 'Angico', code: '01051' },
            { name: 'Aparecida do Rio Negro', code: '01101' },
            { name: 'Aragominas', code: '01309' },
            { name: 'Araguacema', code: '01903' },
            { name: 'Araguaçu', code: '02000' },
            { name: 'Araguaína', code: '02109' },
            { name: 'Araguanã', code: '02158' },
            { name: 'Araguatins', code: '02208' },
            { name: 'Arapoema', code: '02307' },
            { name: 'Arraias', code: '02406' },
            { name: 'Augustinópolis', code: '02554' },
            { name: 'Aurora do Tocantins', code: '02703' },
            { name: 'Axixá do Tocantins', code: '02901' },
            { name: 'Babaçulândia', code: '03008' },
            { name: 'Bandeirantes do Tocantins', code: '03057' },
            { name: 'Barra do Ouro', code: '03073' },
            { name: 'Barrolândia', code: '03107' },
            { name: 'Bernardo Sayão', code: '03206' },
            { name: 'Bom Jesus do Tocantins', code: '03305' },
            { name: 'Brasilândia do Tocantins', code: '03602' },
            { name: 'Brejinho de Nazaré', code: '03701' },
            { name: 'Buriti do Tocantins', code: '03800' },
            { name: 'Cachoeirinha', code: '03826' },
            { name: 'Campos Lindos', code: '03842' },
            { name: 'Cariri do Tocantins', code: '03867' },
            { name: 'Carmolândia', code: '03883' },
            { name: 'Carrasco Bonito', code: '03891' },
            { name: 'Caseara', code: '03909' },
            { name: 'Centenário', code: '04105' },
            { name: 'Chapada da Natividade', code: '05102' },
            { name: 'Chapada de Areia', code: '04600' },
            { name: 'Colinas do Tocantins', code: '05508' },
            { name: 'Colméia', code: '16703' },
            { name: 'Combinado', code: '05557' },
            { name: 'Conceição do Tocantins', code: '05607' },
            { name: 'Couto Magalhães', code: '06001' },
            { name: 'Cristalândia', code: '06100' },
            { name: 'Crixás do Tocantins', code: '06258' },
            { name: 'Darcinópolis', code: '06506' },
            { name: 'Dianópolis', code: '07009' },
            { name: 'Divinópolis do Tocantins', code: '07108' },
            { name: 'Dois Irmãos do Tocantins', code: '07207' },
            { name: 'Dueré', code: '07306' },
            { name: 'Esperantina', code: '07405' },
            { name: 'Fátima', code: '07553' },
            { name: 'Figueirópolis', code: '07652' },
            { name: 'Filadélfia', code: '07702' },
            { name: 'Formoso do Araguaia', code: '08205' },
            { name: 'Goianorte', code: '08304' },
            { name: 'Goiatins', code: '09005' },
            { name: 'Guaraí', code: '09302' },
            { name: 'Gurupi', code: '09500' },
            { name: 'Ipueiras', code: '09807' },
            { name: 'Itacajá', code: '10508' },
            { name: 'Itaguatins', code: '10706' },
            { name: 'Itapiratins', code: '10904' },
            { name: 'Itaporã do Tocantins', code: '11100' },
            { name: 'Jaú do Tocantins', code: '11506' },
            { name: 'Juarina', code: '11803' },
            { name: 'Lagoa da Confusão', code: '11902' },
            { name: 'Lagoa do Tocantins', code: '11951' },
            { name: 'Lajeado', code: '12009' },
            { name: 'Lavandeira', code: '12157' },
            { name: 'Lizarda', code: '12405' },
            { name: 'Luzinópolis', code: '12454' },
            { name: 'Marianópolis do Tocantins', code: '12504' },
            { name: 'Mateiros', code: '12702' },
            { name: 'Maurilândia do Tocantins', code: '12801' },
            { name: 'Miracema do Tocantins', code: '13205' },
            { name: 'Miranorte', code: '13304' },
            { name: 'Monte do Carmo', code: '13601' },
            { name: 'Monte Santo do Tocantins', code: '13700' },
            { name: 'Muricilândia', code: '13957' },
            { name: 'Natividade', code: '14203' },
            { name: 'Nazaré', code: '14302' },
            { name: 'Nova Olinda', code: '14880' },
            { name: 'Nova Rosalândia', code: '15002' },
            { name: 'Novo Acordo', code: '15101' },
            { name: 'Novo Alegre', code: '15150' },
            { name: 'Novo Jardim', code: '15259' },
            { name: 'Oliveira de Fátima', code: '15507' },
            { name: 'Palmas', code: '21000' },
            { name: 'Palmeirante', code: '15705' },
            { name: 'Palmeiras do Tocantins', code: '13809' },
            { name: 'Palmeirópolis', code: '15754' },
            { name: 'Paraíso do Tocantins', code: '16109' },
            { name: 'Paranã', code: '16208' },
            { name: "Pau D'Arco", code: '16307' },
            { name: 'Pedro Afonso', code: '16505' },
            { name: 'Peixe', code: '16604' },
            { name: 'Pequizeiro', code: '16653' },
            { name: 'Pindorama do Tocantins', code: '17008' },
            { name: 'Piraquê', code: '17206' },
            { name: 'Pium', code: '17503' },
            { name: 'Ponte Alta do Bom Jesus', code: '17800' },
            { name: 'Ponte Alta do Tocantins', code: '17909' },
            { name: 'Porto Alegre do Tocantins', code: '18006' },
            { name: 'Porto Nacional', code: '18204' },
            { name: 'Praia Norte', code: '18303' },
            { name: 'Presidente Kennedy', code: '18402' },
            { name: 'Pugmil', code: '18451' },
            { name: 'Recursolândia', code: '18501' },
            { name: 'Riachinho', code: '18550' },
            { name: 'Rio da Conceição', code: '18659' },
            { name: 'Rio dos Bois', code: '18709' },
            { name: 'Rio Sono', code: '18758' },
            { name: 'Sampaio', code: '18808' },
            { name: 'Sandolândia', code: '18840' },
            { name: 'Santa Fé do Araguaia', code: '18865' },
            { name: 'Santa Maria do Tocantins', code: '18881' },
            { name: 'Santa Rita do Tocantins', code: '18899' },
            { name: 'Santa Rosa do Tocantins', code: '18907' },
            { name: 'Santa Tereza do Tocantins', code: '19004' },
            { name: 'Santa Terezinha do Tocantins', code: '20002' },
            { name: 'São Bento do Tocantins', code: '20101' },
            { name: 'São Félix do Tocantins', code: '20150' },
            { name: 'São Miguel do Tocantins', code: '20200' },
            { name: 'São Salvador do Tocantins', code: '20259' },
            { name: 'São Sebastião do Tocantins', code: '20309' },
            { name: 'São Valério', code: '20499' },
            { name: 'Silvanópolis', code: '20655' },
            { name: 'Sítio Novo do Tocantins', code: '20804' },
            { name: 'Sucupira', code: '20853' },
            { name: 'Tabocão', code: '08254' },
            { name: 'Taguatinga', code: '20903' },
            { name: 'Taipas do Tocantins', code: '20937' },
            { name: 'Talismã', code: '20978' },
            { name: 'Tocantínia', code: '21109' },
            { name: 'Tocantinópolis', code: '21208' },
            { name: 'Tupirama', code: '21257' },
            { name: 'Tupiratins', code: '21307' },
            { name: 'Wanderlândia', code: '22081' },
            { name: 'Xambioá', code: '22107' },
        ],
    },
    {
        abbreviation: StateAbbreviation.MA,
        name: 'Maranhão',
        code: '21',
        cities: [
            { name: 'Açailândia', code: '00055' },
            { name: 'Afonso Cunha', code: '00105' },
            { name: 'Água Doce do Maranhão', code: '00154' },
            { name: 'Alcântara', code: '00204' },
            { name: 'Aldeias Altas', code: '00303' },
            { name: 'Altamira do Maranhão', code: '00402' },
            { name: 'Alto Alegre do Maranhão', code: '00436' },
            { name: 'Alto Alegre do Pindaré', code: '00477' },
            { name: 'Alto Parnaíba', code: '00501' },
            { name: 'Amapá do Maranhão', code: '00550' },
            { name: 'Amarante do Maranhão', code: '00600' },
            { name: 'Anajatuba', code: '00709' },
            { name: 'Anapurus', code: '00808' },
            { name: 'Apicum-Açu', code: '00832' },
            { name: 'Araguanã', code: '00873' },
            { name: 'Araioses', code: '00907' },
            { name: 'Arame', code: '00956' },
            { name: 'Arari', code: '01004' },
            { name: 'Axixá', code: '01103' },
            { name: 'Bacabal', code: '01202' },
            { name: 'Bacabeira', code: '01251' },
            { name: 'Bacuri', code: '01301' },
            { name: 'Bacurituba', code: '01350' },
            { name: 'Balsas', code: '01400' },
            { name: 'Barão de Grajaú', code: '01509' },
            { name: 'Barra do Corda', code: '01608' },
            { name: 'Barreirinhas', code: '01707' },
            { name: 'Bela Vista do Maranhão', code: '01772' },
            { name: 'Belágua', code: '01731' },
            { name: 'Benedito Leite', code: '01806' },
            { name: 'Bequimão', code: '01905' },
            { name: 'Bernardo do Mearim', code: '01939' },
            { name: 'Boa Vista do Gurupi', code: '01970' },
            { name: 'Bom Jardim', code: '02002' },
            { name: 'Bom Jesus das Selvas', code: '02036' },
            { name: 'Bom Lugar', code: '02077' },
            { name: 'Brejo', code: '02101' },
            { name: 'Brejo de Areia', code: '02150' },
            { name: 'Buriti', code: '02200' },
            { name: 'Buriti Bravo', code: '02309' },
            { name: 'Buriticupu', code: '02325' },
            { name: 'Buritirana', code: '02358' },
            { name: 'Cachoeira Grande', code: '02374' },
            { name: 'Cajapió', code: '02408' },
            { name: 'Cajari', code: '02507' },
            { name: 'Campestre do Maranhão', code: '02556' },
            { name: 'Cândido Mendes', code: '02606' },
            { name: 'Cantanhede', code: '02705' },
            { name: 'Capinzal do Norte', code: '02754' },
            { name: 'Carolina', code: '02804' },
            { name: 'Carutapera', code: '02903' },
            { name: 'Caxias', code: '03000' },
            { name: 'Cedral', code: '03109' },
            { name: 'Central do Maranhão', code: '03125' },
            { name: 'Centro do Guilherme', code: '03158' },
            { name: 'Centro Novo do Maranhão', code: '03174' },
            { name: 'Chapadinha', code: '03208' },
            { name: 'Cidelândia', code: '03257' },
            { name: 'Codó', code: '03307' },
            { name: 'Coelho Neto', code: '03406' },
            { name: 'Colinas', code: '03505' },
            { name: 'Conceição do Lago-Açu', code: '03554' },
            { name: 'Coroatá', code: '03604' },
            { name: 'Cururupu', code: '03703' },
            { name: 'Davinópolis', code: '03752' },
            { name: 'Dom Pedro', code: '03802' },
            { name: 'Duque Bacelar', code: '03901' },
            { name: 'Esperantinópolis', code: '04008' },
            { name: 'Estreito', code: '04057' },
            { name: 'Feira Nova do Maranhão', code: '04073' },
            { name: 'Fernando Falcão', code: '04081' },
            { name: 'Formosa da Serra Negra', code: '04099' },
            { name: 'Fortaleza dos Nogueiras', code: '04107' },
            { name: 'Fortuna', code: '04206' },
            { name: 'Godofredo Viana', code: '04305' },
            { name: 'Gonçalves Dias', code: '04404' },
            { name: 'Governador Archer', code: '04503' },
            { name: 'Governador Edison Lobão', code: '04552' },
            { name: 'Governador Eugênio Barros', code: '04602' },
            { name: 'Governador Luiz Rocha', code: '04628' },
            { name: 'Governador Newton Bello', code: '04651' },
            { name: 'Governador Nunes Freire', code: '04677' },
            { name: 'Graça Aranha', code: '04701' },
            { name: 'Grajaú', code: '04800' },
            { name: 'Guimarães', code: '04909' },
            { name: 'Humberto de Campos', code: '05005' },
            { name: 'Icatu', code: '05104' },
            { name: 'Igarapé do Meio', code: '05153' },
            { name: 'Igarapé Grande', code: '05203' },
            { name: 'Imperatriz', code: '05302' },
            { name: 'Itaipava do Grajaú', code: '05351' },
            { name: 'Itapecuru Mirim', code: '05401' },
            { name: 'Itinga do Maranhão', code: '05427' },
            { name: 'Jatobá', code: '05450' },
            { name: 'Jenipapo dos Vieiras', code: '05476' },
            { name: 'João Lisboa', code: '05500' },
            { name: 'Joselândia', code: '05609' },
            { name: 'Junco do Maranhão', code: '05658' },
            { name: 'Lago da Pedra', code: '05708' },
            { name: 'Lago do Junco', code: '05807' },
            { name: 'Lago dos Rodrigues', code: '05948' },
            { name: 'Lago Verde', code: '05906' },
            { name: 'Lagoa do Mato', code: '05922' },
            { name: 'Lagoa Grande do Maranhão', code: '05963' },
            { name: 'Lajeado Novo', code: '05989' },
            { name: 'Lima Campos', code: '06003' },
            { name: 'Loreto', code: '06102' },
            { name: 'Luís Domingues', code: '06201' },
            { name: 'Magalhães de Almeida', code: '06300' },
            { name: 'Maracaçumé', code: '06326' },
            { name: 'Marajá do Sena', code: '06359' },
            { name: 'Maranhãozinho', code: '06375' },
            { name: 'Mata Roma', code: '06409' },
            { name: 'Matinha', code: '06508' },
            { name: 'Matões', code: '06607' },
            { name: 'Matões do Norte', code: '06631' },
            { name: 'Milagres do Maranhão', code: '06672' },
            { name: 'Mirador', code: '06706' },
            { name: 'Miranda do Norte', code: '06755' },
            { name: 'Mirinzal', code: '06805' },
            { name: 'Monção', code: '06904' },
            { name: 'Montes Altos', code: '07001' },
            { name: 'Morros', code: '07100' },
            { name: 'Nina Rodrigues', code: '07209' },
            { name: 'Nova Colinas', code: '07258' },
            { name: 'Nova Iorque', code: '07308' },
            { name: 'Nova Olinda do Maranhão', code: '07357' },
            { name: "Olho d'Água das Cunhãs", code: '07407' },
            { name: 'Olinda Nova do Maranhão', code: '07456' },
            { name: 'Paço do Lumiar', code: '07506' },
            { name: 'Palmeirândia', code: '07605' },
            { name: 'Paraibano', code: '07704' },
            { name: 'Parnarama', code: '07803' },
            { name: 'Passagem Franca', code: '07902' },
            { name: 'Pastos Bons', code: '08009' },
            { name: 'Paulino Neves', code: '08058' },
            { name: 'Paulo Ramos', code: '08108' },
            { name: 'Pedreiras', code: '08207' },
            { name: 'Pedro do Rosário', code: '08256' },
            { name: 'Penalva', code: '08306' },
            { name: 'Peri Mirim', code: '08405' },
            { name: 'Peritoró', code: '08454' },
            { name: 'Pindaré-Mirim', code: '08504' },
            { name: 'Pinheiro', code: '08603' },
            { name: 'Pio XII', code: '08702' },
            { name: 'Pirapemas', code: '08801' },
            { name: 'Poção de Pedras', code: '08900' },
            { name: 'Porto Franco', code: '09007' },
            { name: 'Porto Rico do Maranhão', code: '09056' },
            { name: 'Presidente Dutra', code: '09106' },
            { name: 'Presidente Juscelino', code: '09205' },
            { name: 'Presidente Médici', code: '09239' },
            { name: 'Presidente Sarney', code: '09270' },
            { name: 'Presidente Vargas', code: '09304' },
            { name: 'Primeira Cruz', code: '09403' },
            { name: 'Raposa', code: '09452' },
            { name: 'Riachão', code: '09502' },
            { name: 'Ribamar Fiquene', code: '09551' },
            { name: 'Rosário', code: '09601' },
            { name: 'Sambaíba', code: '09700' },
            { name: 'Santa Filomena do Maranhão', code: '09759' },
            { name: 'Santa Helena', code: '09809' },
            { name: 'Santa Inês', code: '09908' },
            { name: 'Santa Luzia', code: '10005' },
            { name: 'Santa Luzia do Paruá', code: '10039' },
            { name: 'Santa Quitéria do Maranhão', code: '10104' },
            { name: 'Santa Rita', code: '10203' },
            { name: 'Santana do Maranhão', code: '10237' },
            { name: 'Santo Amaro do Maranhão', code: '10278' },
            { name: 'Santo Antônio dos Lopes', code: '10302' },
            { name: 'São Benedito do Rio Preto', code: '10401' },
            { name: 'São Bento', code: '10500' },
            { name: 'São Bernardo', code: '10609' },
            { name: 'São Domingos do Azeitão', code: '10658' },
            { name: 'São Domingos do Maranhão', code: '10708' },
            { name: 'São Félix de Balsas', code: '10807' },
            { name: 'São Francisco do Brejão', code: '10856' },
            { name: 'São Francisco do Maranhão', code: '10906' },
            { name: 'São João Batista', code: '11003' },
            { name: 'São João do Carú', code: '11029' },
            { name: 'São João do Paraíso', code: '11052' },
            { name: 'São João do Soter', code: '11078' },
            { name: 'São João dos Patos', code: '11102' },
            { name: 'São José de Ribamar', code: '11201' },
            { name: 'São José dos Basílios', code: '11250' },
            { name: 'São Luís', code: '11300' },
            { name: 'São Luís Gonzaga do Maranhão', code: '11409' },
            { name: 'São Mateus do Maranhão', code: '11508' },
            { name: 'São Pedro da Água Branca', code: '11532' },
            { name: 'São Pedro dos Crentes', code: '11573' },
            { name: 'São Raimundo das Mangabeiras', code: '11607' },
            { name: 'São Raimundo do Doca Bezerra', code: '11631' },
            { name: 'São Roberto', code: '11672' },
            { name: 'São Vicente Ferrer', code: '11706' },
            { name: 'Satubinha', code: '11722' },
            { name: 'Senador Alexandre Costa', code: '11748' },
            { name: 'Senador La Rocque', code: '11763' },
            { name: 'Serrano do Maranhão', code: '11789' },
            { name: 'Sítio Novo', code: '11805' },
            { name: 'Sucupira do Norte', code: '11904' },
            { name: 'Sucupira do Riachão', code: '11953' },
            { name: 'Tasso Fragoso', code: '12001' },
            { name: 'Timbiras', code: '12100' },
            { name: 'Timon', code: '12209' },
            { name: 'Trizidela do Vale', code: '12233' },
            { name: 'Tufilândia', code: '12274' },
            { name: 'Tuntum', code: '12308' },
            { name: 'Turiaçu', code: '12407' },
            { name: 'Turilândia', code: '12456' },
            { name: 'Tutóia', code: '12506' },
            { name: 'Urbano Santos', code: '12605' },
            { name: 'Vargem Grande', code: '12704' },
            { name: 'Viana', code: '12803' },
            { name: 'Vila Nova dos Martírios', code: '12852' },
            { name: 'Vitória do Mearim', code: '12902' },
            { name: 'Vitorino Freire', code: '13009' },
            { name: 'Zé Doca', code: '14007' },
        ],
    },
    {
        abbreviation: StateAbbreviation.RN,
        name: 'Rio Grande do Norte',
        code: '24',
        cities: [
            { name: 'Acari', code: '00109' },
            { name: 'Açu', code: '00208' },
            { name: 'Afonso Bezerra', code: '00307' },
            { name: 'Água Nova', code: '00406' },
            { name: 'Alexandria', code: '00505' },
            { name: 'Almino Afonso', code: '00604' },
            { name: 'Alto do Rodrigues', code: '00703' },
            { name: 'Angicos', code: '00802' },
            { name: 'Antônio Martins', code: '00901' },
            { name: 'Apodi', code: '01008' },
            { name: 'Areia Branca', code: '01107' },
            { name: 'Arês', code: '01206' },
            { name: 'Baía Formosa', code: '01404' },
            { name: 'Baraúna', code: '01453' },
            { name: 'Barcelona', code: '01503' },
            { name: 'Bento Fernandes', code: '01602' },
            { name: 'Bodó', code: '01651' },
            { name: 'Bom Jesus', code: '01701' },
            { name: 'Brejinho', code: '01800' },
            { name: 'Caiçara do Norte', code: '01859' },
            { name: 'Caiçara do Rio do Vento', code: '01909' },
            { name: 'Caicó', code: '02006' },
            { name: 'Campo Grande', code: '01305' },
            { name: 'Campo Redondo', code: '02105' },
            { name: 'Canguaretama', code: '02204' },
            { name: 'Caraúbas', code: '02303' },
            { name: 'Carnaúba dos Dantas', code: '02402' },
            { name: 'Carnaubais', code: '02501' },
            { name: 'Ceará-Mirim', code: '02600' },
            { name: 'Cerro Corá', code: '02709' },
            { name: 'Coronel Ezequiel', code: '02808' },
            { name: 'Coronel João Pessoa', code: '02907' },
            { name: 'Cruzeta', code: '03004' },
            { name: 'Currais Novos', code: '03103' },
            { name: 'Doutor Severiano', code: '03202' },
            { name: 'Encanto', code: '03301' },
            { name: 'Equador', code: '03400' },
            { name: 'Espírito Santo', code: '03509' },
            { name: 'Extremoz', code: '03608' },
            { name: 'Felipe Guerra', code: '03707' },
            { name: 'Fernando Pedroza', code: '03756' },
            { name: 'Florânia', code: '03806' },
            { name: 'Francisco Dantas', code: '03905' },
            { name: 'Frutuoso Gomes', code: '04002' },
            { name: 'Galinhos', code: '04101' },
            { name: 'Goianinha', code: '04200' },
            { name: 'Governador Dix-Sept Rosado', code: '04309' },
            { name: 'Grossos', code: '04408' },
            { name: 'Guamaré', code: '04507' },
            { name: 'Ielmo Marinho', code: '04606' },
            { name: 'Ipanguaçu', code: '04705' },
            { name: 'Ipueira', code: '04804' },
            { name: 'Itajá', code: '04853' },
            { name: 'Itaú', code: '04903' },
            { name: 'Jaçanã', code: '05009' },
            { name: 'Jandaíra', code: '05108' },
            { name: 'Janduís', code: '05207' },
            { name: 'Januário Cicco', code: '05306' },
            { name: 'Japi', code: '05405' },
            { name: 'Jardim de Angicos', code: '05504' },
            { name: 'Jardim de Piranhas', code: '05603' },
            { name: 'Jardim do Seridó', code: '05702' },
            { name: 'João Câmara', code: '05801' },
            { name: 'João Dias', code: '05900' },
            { name: 'José da Penha', code: '06007' },
            { name: 'Jucurutu', code: '06106' },
            { name: 'Jundiá', code: '06155' },
            { name: "Lagoa d'Anta", code: '06205' },
            { name: 'Lagoa de Pedras', code: '06304' },
            { name: 'Lagoa de Velhos', code: '06403' },
            { name: 'Lagoa Nova', code: '06502' },
            { name: 'Lagoa Salgada', code: '06601' },
            { name: 'Lajes', code: '06700' },
            { name: 'Lajes Pintadas', code: '06809' },
            { name: 'Lucrécia', code: '06908' },
            { name: 'Luís Gomes', code: '07005' },
            { name: 'Macaíba', code: '07104' },
            { name: 'Macau', code: '07203' },
            { name: 'Major Sales', code: '07252' },
            { name: 'Marcelino Vieira', code: '07302' },
            { name: 'Martins', code: '07401' },
            { name: 'Maxaranguape', code: '07500' },
            { name: 'Messias Targino', code: '07609' },
            { name: 'Montanhas', code: '07708' },
            { name: 'Monte Alegre', code: '07807' },
            { name: 'Monte das Gameleiras', code: '07906' },
            { name: 'Mossoró', code: '08003' },
            { name: 'Natal', code: '08102' },
            { name: 'Nísia Floresta', code: '08201' },
            { name: 'Nova Cruz', code: '08300' },
            { name: "Olho d'Água do Borges", code: '08409' },
            { name: 'Ouro Branco', code: '08508' },
            { name: 'Paraná', code: '08607' },
            { name: 'Paraú', code: '08706' },
            { name: 'Parazinho', code: '08805' },
            { name: 'Parelhas', code: '08904' },
            { name: 'Parnamirim', code: '03251' },
            { name: 'Passa e Fica', code: '09100' },
            { name: 'Passagem', code: '09209' },
            { name: 'Patu', code: '09308' },
            { name: 'Pau dos Ferros', code: '09407' },
            { name: 'Pedra Grande', code: '09506' },
            { name: 'Pedra Preta', code: '09605' },
            { name: 'Pedro Avelino', code: '09704' },
            { name: 'Pedro Velho', code: '09803' },
            { name: 'Pendências', code: '09902' },
            { name: 'Pilões', code: '10009' },
            { name: 'Poço Branco', code: '10108' },
            { name: 'Portalegre', code: '10207' },
            { name: 'Porto do Mangue', code: '10256' },
            { name: 'Pureza', code: '10405' },
            { name: 'Rafael Fernandes', code: '10504' },
            { name: 'Rafael Godeiro', code: '10603' },
            { name: 'Riacho da Cruz', code: '10702' },
            { name: 'Riacho de Santana', code: '10801' },
            { name: 'Riachuelo', code: '10900' },
            { name: 'Rio do Fogo', code: '08953' },
            { name: 'Rodolfo Fernandes', code: '11007' },
            { name: 'Ruy Barbosa', code: '11106' },
            { name: 'Santa Cruz', code: '11205' },
            { name: 'Santa Maria', code: '09332' },
            { name: 'Santana do Matos', code: '11403' },
            { name: 'Santana do Seridó', code: '11429' },
            { name: 'Santo Antônio', code: '11502' },
            { name: 'São Bento do Norte', code: '11601' },
            { name: 'São Bento do Trairí', code: '11700' },
            { name: 'São Fernando', code: '11809' },
            { name: 'São Francisco do Oeste', code: '11908' },
            { name: 'São Gonçalo do Amarante', code: '12005' },
            { name: 'São João do Sabugi', code: '12104' },
            { name: 'São José de Mipibu', code: '12203' },
            { name: 'São José do Campestre', code: '12302' },
            { name: 'São José do Seridó', code: '12401' },
            { name: 'São Miguel', code: '12500' },
            { name: 'São Miguel do Gostoso', code: '12559' },
            { name: 'São Paulo do Potengi', code: '12609' },
            { name: 'São Pedro', code: '12708' },
            { name: 'São Rafael', code: '12807' },
            { name: 'São Tomé', code: '12906' },
            { name: 'São Vicente', code: '13003' },
            { name: 'Senador Elói de Souza', code: '13102' },
            { name: 'Senador Georgino Avelino', code: '13201' },
            { name: 'Serra Caiada', code: '10306' },
            { name: 'Serra de São Bento', code: '13300' },
            { name: 'Serra do Mel', code: '13359' },
            { name: 'Serra Negra do Norte', code: '13409' },
            { name: 'Serrinha', code: '13508' },
            { name: 'Serrinha dos Pintos', code: '13557' },
            { name: 'Severiano Melo', code: '13607' },
            { name: 'Sítio Novo', code: '13706' },
            { name: 'Taboleiro Grande', code: '13805' },
            { name: 'Taipu', code: '13904' },
            { name: 'Tangará', code: '14001' },
            { name: 'Tenente Ananias', code: '14100' },
            { name: 'Tenente Laurentino Cruz', code: '14159' },
            { name: 'Tibau', code: '11056' },
            { name: 'Tibau do Sul', code: '14209' },
            { name: 'Timbaúba dos Batistas', code: '14308' },
            { name: 'Touros', code: '14407' },
            { name: 'Triunfo Potiguar', code: '14456' },
            { name: 'Umarizal', code: '14506' },
            { name: 'Upanema', code: '14605' },
            { name: 'Várzea', code: '14704' },
            { name: 'Venha-Ver', code: '14753' },
            { name: 'Vera Cruz', code: '14803' },
            { name: 'Viçosa', code: '14902' },
            { name: 'Vila Flor', code: '15008' },
        ],
    },
    {
        abbreviation: StateAbbreviation.PI,
        name: 'Piauí',
        code: '22',
        cities: [
            { name: 'Acauã', code: '00053' },
            { name: 'Agricolândia', code: '00103' },
            { name: 'Água Branca', code: '00202' },
            { name: 'Alagoinha do Piauí', code: '00251' },
            { name: 'Alegrete do Piauí', code: '00277' },
            { name: 'Alto Longá', code: '00301' },
            { name: 'Altos', code: '00400' },
            { name: 'Alvorada do Gurguéia', code: '00459' },
            { name: 'Amarante', code: '00509' },
            { name: 'Angical do Piauí', code: '00608' },
            { name: 'Anísio de Abreu', code: '00707' },
            { name: 'Antônio Almeida', code: '00806' },
            { name: 'Aroazes', code: '00905' },
            { name: 'Aroeiras do Itaim', code: '00954' },
            { name: 'Arraial', code: '01002' },
            { name: 'Assunção do Piauí', code: '01051' },
            { name: 'Avelino Lopes', code: '01101' },
            { name: 'Baixa Grande do Ribeiro', code: '01150' },
            { name: "Barra D'Alcântara", code: '01176' },
            { name: 'Barras', code: '01200' },
            { name: 'Barreiras do Piauí', code: '01309' },
            { name: 'Barro Duro', code: '01408' },
            { name: 'Batalha', code: '01507' },
            { name: 'Bela Vista do Piauí', code: '01556' },
            { name: 'Belém do Piauí', code: '01572' },
            { name: 'Beneditinos', code: '01606' },
            { name: 'Bertolínia', code: '01705' },
            { name: 'Betânia do Piauí', code: '01739' },
            { name: 'Boa Hora', code: '01770' },
            { name: 'Bocaina', code: '01804' },
            { name: 'Bom Jesus', code: '01903' },
            { name: 'Bom Princípio do Piauí', code: '01919' },
            { name: 'Bonfim do Piauí', code: '01929' },
            { name: 'Boqueirão do Piauí', code: '01945' },
            { name: 'Brasileira', code: '01960' },
            { name: 'Brejo do Piauí', code: '01988' },
            { name: 'Buriti dos Lopes', code: '02000' },
            { name: 'Buriti dos Montes', code: '02026' },
            { name: 'Cabeceiras do Piauí', code: '02059' },
            { name: 'Cajazeiras do Piauí', code: '02075' },
            { name: 'Cajueiro da Praia', code: '02083' },
            { name: 'Caldeirão Grande do Piauí', code: '02091' },
            { name: 'Campinas do Piauí', code: '02109' },
            { name: 'Campo Alegre do Fidalgo', code: '02117' },
            { name: 'Campo Grande do Piauí', code: '02133' },
            { name: 'Campo Largo do Piauí', code: '02174' },
            { name: 'Campo Maior', code: '02208' },
            { name: 'Canavieira', code: '02251' },
            { name: 'Canto do Buriti', code: '02307' },
            { name: 'Capitão de Campos', code: '02406' },
            { name: 'Capitão Gervásio Oliveira', code: '02455' },
            { name: 'Caracol', code: '02505' },
            { name: 'Caraúbas do Piauí', code: '02539' },
            { name: 'Caridade do Piauí', code: '02554' },
            { name: 'Castelo do Piauí', code: '02604' },
            { name: 'Caxingó', code: '02653' },
            { name: 'Cocal', code: '02703' },
            { name: 'Cocal de Telha', code: '02711' },
            { name: 'Cocal dos Alves', code: '02729' },
            { name: 'Coivaras', code: '02737' },
            { name: 'Colônia do Gurguéia', code: '02752' },
            { name: 'Colônia do Piauí', code: '02778' },
            { name: 'Conceição do Canindé', code: '02802' },
            { name: 'Coronel José Dias', code: '02851' },
            { name: 'Corrente', code: '02901' },
            { name: 'Cristalândia do Piauí', code: '03008' },
            { name: 'Cristino Castro', code: '03107' },
            { name: 'Curimatá', code: '03206' },
            { name: 'Currais', code: '03230' },
            { name: 'Curral Novo do Piauí', code: '03271' },
            { name: 'Curralinhos', code: '03255' },
            { name: 'Demerval Lobão', code: '03305' },
            { name: 'Dirceu Arcoverde', code: '03354' },
            { name: 'Dom Expedito Lopes', code: '03404' },
            { name: 'Dom Inocêncio', code: '03453' },
            { name: 'Domingos Mourão', code: '03420' },
            { name: 'Elesbão Veloso', code: '03503' },
            { name: 'Eliseu Martins', code: '03602' },
            { name: 'Esperantina', code: '03701' },
            { name: 'Fartura do Piauí', code: '03750' },
            { name: 'Flores do Piauí', code: '03800' },
            { name: 'Floresta do Piauí', code: '03859' },
            { name: 'Floriano', code: '03909' },
            { name: 'Francinópolis', code: '04006' },
            { name: 'Francisco Ayres', code: '04105' },
            { name: 'Francisco Macedo', code: '04154' },
            { name: 'Francisco Santos', code: '04204' },
            { name: 'Fronteiras', code: '04303' },
            { name: 'Geminiano', code: '04352' },
            { name: 'Gilbués', code: '04402' },
            { name: 'Guadalupe', code: '04501' },
            { name: 'Guaribas', code: '04550' },
            { name: 'Hugo Napoleão', code: '04600' },
            { name: 'Ilha Grande', code: '04659' },
            { name: 'Inhuma', code: '04709' },
            { name: 'Ipiranga do Piauí', code: '04808' },
            { name: 'Isaías Coelho', code: '04907' },
            { name: 'Itainópolis', code: '05003' },
            { name: 'Itaueira', code: '05102' },
            { name: 'Jacobina do Piauí', code: '05151' },
            { name: 'Jaicós', code: '05201' },
            { name: 'Jardim do Mulato', code: '05250' },
            { name: 'Jatobá do Piauí', code: '05276' },
            { name: 'Jerumenha', code: '05300' },
            { name: 'João Costa', code: '05359' },
            { name: 'Joaquim Pires', code: '05409' },
            { name: 'Joca Marques', code: '05458' },
            { name: 'José de Freitas', code: '05508' },
            { name: 'Juazeiro do Piauí', code: '05516' },
            { name: 'Júlio Borges', code: '05524' },
            { name: 'Jurema', code: '05532' },
            { name: 'Lagoa Alegre', code: '05557' },
            { name: 'Lagoa de São Francisco', code: '05573' },
            { name: 'Lagoa do Barro do Piauí', code: '05565' },
            { name: 'Lagoa do Piauí', code: '05581' },
            { name: 'Lagoa do Sítio', code: '05599' },
            { name: 'Lagoinha do Piauí', code: '05540' },
            { name: 'Landri Sales', code: '05607' },
            { name: 'Luís Correia', code: '05706' },
            { name: 'Luzilândia', code: '05805' },
            { name: 'Madeiro', code: '05854' },
            { name: 'Manoel Emídio', code: '05904' },
            { name: 'Marcolândia', code: '05953' },
            { name: 'Marcos Parente', code: '06001' },
            { name: 'Massapê do Piauí', code: '06050' },
            { name: 'Matias Olímpio', code: '06100' },
            { name: 'Miguel Alves', code: '06209' },
            { name: 'Miguel Leão', code: '06308' },
            { name: 'Milton Brandão', code: '06357' },
            { name: 'Monsenhor Gil', code: '06407' },
            { name: 'Monsenhor Hipólito', code: '06506' },
            { name: 'Monte Alegre do Piauí', code: '06605' },
            { name: 'Morro Cabeça no Tempo', code: '06654' },
            { name: 'Morro do Chapéu do Piauí', code: '06670' },
            { name: 'Murici dos Portelas', code: '06696' },
            { name: 'Nazaré do Piauí', code: '06704' },
            { name: 'Nazária', code: '06720' },
            { name: 'Nossa Senhora de Nazaré', code: '06753' },
            { name: 'Nossa Senhora dos Remédios', code: '06803' },
            { name: 'Nova Santa Rita', code: '07959' },
            { name: 'Novo Oriente do Piauí', code: '06902' },
            { name: 'Novo Santo Antônio', code: '06951' },
            { name: 'Oeiras', code: '07009' },
            { name: "Olho D'Água do Piauí", code: '07108' },
            { name: 'Padre Marcos', code: '07207' },
            { name: 'Paes Landim', code: '07306' },
            { name: 'Pajeú do Piauí', code: '07355' },
            { name: 'Palmeira do Piauí', code: '07405' },
            { name: 'Palmeirais', code: '07504' },
            { name: 'Paquetá', code: '07553' },
            { name: 'Parnaguá', code: '07603' },
            { name: 'Parnaíba', code: '07702' },
            { name: 'Passagem Franca do Piauí', code: '07751' },
            { name: 'Patos do Piauí', code: '07777' },
            { name: "Pau D'Arco do Piauí", code: '07793' },
            { name: 'Paulistana', code: '07801' },
            { name: 'Pavussu', code: '07850' },
            { name: 'Pedro II', code: '07900' },
            { name: 'Pedro Laurentino', code: '07934' },
            { name: 'Picos', code: '08007' },
            { name: 'Pimenteiras', code: '08106' },
            { name: 'Pio IX', code: '08205' },
            { name: 'Piracuruca', code: '08304' },
            { name: 'Piripiri', code: '08403' },
            { name: 'Porto', code: '08502' },
            { name: 'Porto Alegre do Piauí', code: '08551' },
            { name: 'Prata do Piauí', code: '08601' },
            { name: 'Queimada Nova', code: '08650' },
            { name: 'Redenção do Gurguéia', code: '08700' },
            { name: 'Regeneração', code: '08809' },
            { name: 'Riacho Frio', code: '08858' },
            { name: 'Ribeira do Piauí', code: '08874' },
            { name: 'Ribeiro Gonçalves', code: '08908' },
            { name: 'Rio Grande do Piauí', code: '09005' },
            { name: 'Santa Cruz do Piauí', code: '09104' },
            { name: 'Santa Cruz dos Milagres', code: '09153' },
            { name: 'Santa Filomena', code: '09203' },
            { name: 'Santa Luz', code: '09302' },
            { name: 'Santa Rosa do Piauí', code: '09377' },
            { name: 'Santana do Piauí', code: '09351' },
            { name: 'Santo Antônio de Lisboa', code: '09401' },
            { name: 'Santo Antônio dos Milagres', code: '09450' },
            { name: 'Santo Inácio do Piauí', code: '09500' },
            { name: 'São Braz do Piauí', code: '09559' },
            { name: 'São Félix do Piauí', code: '09609' },
            { name: 'São Francisco de Assis do Piauí', code: '09658' },
            { name: 'São Francisco do Piauí', code: '09708' },
            { name: 'São Gonçalo do Gurguéia', code: '09757' },
            { name: 'São Gonçalo do Piauí', code: '09807' },
            { name: 'São João da Canabrava', code: '09856' },
            { name: 'São João da Fronteira', code: '09872' },
            { name: 'São João da Serra', code: '09906' },
            { name: 'São João da Varjota', code: '09955' },
            { name: 'São João do Arraial', code: '09971' },
            { name: 'São João do Piauí', code: '10003' },
            { name: 'São José do Divino', code: '10052' },
            { name: 'São José do Peixe', code: '10102' },
            { name: 'São José do Piauí', code: '10201' },
            { name: 'São Julião', code: '10300' },
            { name: 'São Lourenço do Piauí', code: '10359' },
            { name: 'São Luis do Piauí', code: '10375' },
            { name: 'São Miguel da Baixa Grande', code: '10383' },
            { name: 'São Miguel do Fidalgo', code: '10391' },
            { name: 'São Miguel do Tapuio', code: '10409' },
            { name: 'São Pedro do Piauí', code: '10508' },
            { name: 'São Raimundo Nonato', code: '10607' },
            { name: 'Sebastião Barros', code: '10623' },
            { name: 'Sebastião Leal', code: '10631' },
            { name: 'Sigefredo Pacheco', code: '10656' },
            { name: 'Simões', code: '10706' },
            { name: 'Simplício Mendes', code: '10805' },
            { name: 'Socorro do Piauí', code: '10904' },
            { name: 'Sussuapara', code: '10938' },
            { name: 'Tamboril do Piauí', code: '10953' },
            { name: 'Tanque do Piauí', code: '10979' },
            { name: 'Teresina', code: '11001' },
            { name: 'União', code: '11100' },
            { name: 'Uruçuí', code: '11209' },
            { name: 'Valença do Piauí', code: '11308' },
            { name: 'Várzea Branca', code: '11357' },
            { name: 'Várzea Grande', code: '11407' },
            { name: 'Vera Mendes', code: '11506' },
            { name: 'Vila Nova do Piauí', code: '11605' },
            { name: 'Wall Ferraz', code: '11704' },
        ],
    },
    {
        abbreviation: StateAbbreviation.RS,
        name: 'Rio Grande do Sul',
        code: '43',
        cities: [
            { name: 'Aceguá', code: '00034' },
            { name: 'Água Santa', code: '00059' },
            { name: 'Agudo', code: '00109' },
            { name: 'Ajuricaba', code: '00208' },
            { name: 'Alecrim', code: '00307' },
            { name: 'Alegrete', code: '00406' },
            { name: 'Alegria', code: '00455' },
            { name: 'Almirante Tamandaré do Sul', code: '00471' },
            { name: 'Alpestre', code: '00505' },
            { name: 'Alto Alegre', code: '00554' },
            { name: 'Alto Feliz', code: '00570' },
            { name: 'Alvorada', code: '00604' },
            { name: 'Amaral Ferrador', code: '00638' },
            { name: 'Ametista do Sul', code: '00646' },
            { name: 'André da Rocha', code: '00661' },
            { name: 'Anta Gorda', code: '00703' },
            { name: 'Antônio Prado', code: '00802' },
            { name: 'Arambaré', code: '00851' },
            { name: 'Araricá', code: '00877' },
            { name: 'Aratiba', code: '00901' },
            { name: 'Arroio do Meio', code: '01008' },
            { name: 'Arroio do Padre', code: '01073' },
            { name: 'Arroio do Sal', code: '01057' },
            { name: 'Arroio do Tigre', code: '01206' },
            { name: 'Arroio dos Ratos', code: '01107' },
            { name: 'Arroio Grande', code: '01305' },
            { name: 'Arvorezinha', code: '01404' },
            { name: 'Augusto Pestana', code: '01503' },
            { name: 'Áurea', code: '01552' },
            { name: 'Bagé', code: '01602' },
            { name: 'Balneário Pinhal', code: '01636' },
            { name: 'Barão', code: '01651' },
            { name: 'Barão de Cotegipe', code: '01701' },
            { name: 'Barão do Triunfo', code: '01750' },
            { name: 'Barra do Guarita', code: '01859' },
            { name: 'Barra do Quaraí', code: '01875' },
            { name: 'Barra do Ribeiro', code: '01909' },
            { name: 'Barra do Rio Azul', code: '01925' },
            { name: 'Barra Funda', code: '01958' },
            { name: 'Barracão', code: '01800' },
            { name: 'Barros Cassal', code: '02006' },
            { name: 'Benjamin Constant do Sul', code: '02055' },
            { name: 'Bento Gonçalves', code: '02105' },
            { name: 'Boa Vista das Missões', code: '02154' },
            { name: 'Boa Vista do Buricá', code: '02204' },
            { name: 'Boa Vista do Cadeado', code: '02220' },
            { name: 'Boa Vista do Incra', code: '02238' },
            { name: 'Boa Vista do Sul', code: '02253' },
            { name: 'Bom Jesus', code: '02303' },
            { name: 'Bom Princípio', code: '02352' },
            { name: 'Bom Progresso', code: '02378' },
            { name: 'Bom Retiro do Sul', code: '02402' },
            { name: 'Boqueirão do Leão', code: '02451' },
            { name: 'Bossoroca', code: '02501' },
            { name: 'Bozano', code: '02584' },
            { name: 'Braga', code: '02600' },
            { name: 'Brochier', code: '02659' },
            { name: 'Butiá', code: '02709' },
            { name: 'Caçapava do Sul', code: '02808' },
            { name: 'Cacequi', code: '02907' },
            { name: 'Cachoeira do Sul', code: '03004' },
            { name: 'Cachoeirinha', code: '03103' },
            { name: 'Cacique Doble', code: '03202' },
            { name: 'Caibaté', code: '03301' },
            { name: 'Caiçara', code: '03400' },
            { name: 'Camaquã', code: '03509' },
            { name: 'Camargo', code: '03558' },
            { name: 'Cambará do Sul', code: '03608' },
            { name: 'Campestre da Serra', code: '03673' },
            { name: 'Campina das Missões', code: '03707' },
            { name: 'Campinas do Sul', code: '03806' },
            { name: 'Campo Bom', code: '03905' },
            { name: 'Campo Novo', code: '04002' },
            { name: 'Campos Borges', code: '04101' },
            { name: 'Candelária', code: '04200' },
            { name: 'Cândido Godói', code: '04309' },
            { name: 'Candiota', code: '04358' },
            { name: 'Canela', code: '04408' },
            { name: 'Canguçu', code: '04507' },
            { name: 'Canoas', code: '04606' },
            { name: 'Canudos do Vale', code: '04614' },
            { name: 'Capão Bonito do Sul', code: '04622' },
            { name: 'Capão da Canoa', code: '04630' },
            { name: 'Capão do Cipó', code: '04655' },
            { name: 'Capão do Leão', code: '04663' },
            { name: 'Capela de Santana', code: '04689' },
            { name: 'Capitão', code: '04697' },
            { name: 'Capivari do Sul', code: '04671' },
            { name: 'Caraá', code: '04713' },
            { name: 'Carazinho', code: '04705' },
            { name: 'Carlos Barbosa', code: '04804' },
            { name: 'Carlos Gomes', code: '04853' },
            { name: 'Casca', code: '04903' },
            { name: 'Caseiros', code: '04952' },
            { name: 'Catuípe', code: '05009' },
            { name: 'Caxias do Sul', code: '05108' },
            { name: 'Centenário', code: '05116' },
            { name: 'Cerrito', code: '05124' },
            { name: 'Cerro Branco', code: '05132' },
            { name: 'Cerro Grande', code: '05157' },
            { name: 'Cerro Grande do Sul', code: '05173' },
            { name: 'Cerro Largo', code: '05207' },
            { name: 'Chapada', code: '05306' },
            { name: 'Charqueadas', code: '05355' },
            { name: 'Charrua', code: '05371' },
            { name: 'Chiapetta', code: '05405' },
            { name: 'Chuí', code: '05439' },
            { name: 'Chuvisca', code: '05447' },
            { name: 'Cidreira', code: '05454' },
            { name: 'Ciríaco', code: '05504' },
            { name: 'Colinas', code: '05587' },
            { name: 'Colorado', code: '05603' },
            { name: 'Condor', code: '05702' },
            { name: 'Constantina', code: '05801' },
            { name: 'Coqueiro Baixo', code: '05835' },
            { name: 'Coqueiros do Sul', code: '05850' },
            { name: 'Coronel Barros', code: '05871' },
            { name: 'Coronel Bicaco', code: '05900' },
            { name: 'Coronel Pilar', code: '05934' },
            { name: 'Cotiporã', code: '05959' },
            { name: 'Coxilha', code: '05975' },
            { name: 'Crissiumal', code: '06007' },
            { name: 'Cristal', code: '06056' },
            { name: 'Cristal do Sul', code: '06072' },
            { name: 'Cruz Alta', code: '06106' },
            { name: 'Cruzaltense', code: '06130' },
            { name: 'Cruzeiro do Sul', code: '06205' },
            { name: 'David Canabarro', code: '06304' },
            { name: 'Derrubadas', code: '06320' },
            { name: 'Dezesseis de Novembro', code: '06353' },
            { name: 'Dilermando de Aguiar', code: '06379' },
            { name: 'Dois Irmãos', code: '06403' },
            { name: 'Dois Irmãos das Missões', code: '06429' },
            { name: 'Dois Lajeados', code: '06452' },
            { name: 'Dom Feliciano', code: '06502' },
            { name: 'Dom Pedrito', code: '06601' },
            { name: 'Dom Pedro de Alcântara', code: '06551' },
            { name: 'Dona Francisca', code: '06700' },
            { name: 'Doutor Maurício Cardoso', code: '06734' },
            { name: 'Doutor Ricardo', code: '06759' },
            { name: 'Eldorado do Sul', code: '06767' },
            { name: 'Encantado', code: '06809' },
            { name: 'Encruzilhada do Sul', code: '06908' },
            { name: 'Engenho Velho', code: '06924' },
            { name: 'Entre Rios do Sul', code: '06957' },
            { name: 'Entre-Ijuís', code: '06932' },
            { name: 'Erebango', code: '06973' },
            { name: 'Erechim', code: '07005' },
            { name: 'Ernestina', code: '07054' },
            { name: 'Erval Grande', code: '07203' },
            { name: 'Erval Seco', code: '07302' },
            { name: 'Esmeralda', code: '07401' },
            { name: 'Esperança do Sul', code: '07450' },
            { name: 'Espumoso', code: '07500' },
            { name: 'Estação', code: '07559' },
            { name: 'Estância Velha', code: '07609' },
            { name: 'Esteio', code: '07708' },
            { name: 'Estrela', code: '07807' },
            { name: 'Estrela Velha', code: '07815' },
            { name: 'Eugênio de Castro', code: '07831' },
            { name: 'Fagundes Varela', code: '07864' },
            { name: 'Farroupilha', code: '07906' },
            { name: 'Faxinal do Soturno', code: '08003' },
            { name: 'Faxinalzinho', code: '08052' },
            { name: 'Fazenda Vilanova', code: '08078' },
            { name: 'Feliz', code: '08102' },
            { name: 'Flores da Cunha', code: '08201' },
            { name: 'Floriano Peixoto', code: '08250' },
            { name: 'Fontoura Xavier', code: '08300' },
            { name: 'Formigueiro', code: '08409' },
            { name: 'Forquetinha', code: '08433' },
            { name: 'Fortaleza dos Valos', code: '08458' },
            { name: 'Frederico Westphalen', code: '08508' },
            { name: 'Garibaldi', code: '08607' },
            { name: 'Garruchos', code: '08656' },
            { name: 'Gaurama', code: '08706' },
            { name: 'General Câmara', code: '08805' },
            { name: 'Gentil', code: '08854' },
            { name: 'Getúlio Vargas', code: '08904' },
            { name: 'Giruá', code: '09001' },
            { name: 'Glorinha', code: '09050' },
            { name: 'Gramado', code: '09100' },
            { name: 'Gramado dos Loureiros', code: '09126' },
            { name: 'Gramado Xavier', code: '09159' },
            { name: 'Gravataí', code: '09209' },
            { name: 'Guabiju', code: '09258' },
            { name: 'Guaíba', code: '09308' },
            { name: 'Guaporé', code: '09407' },
            { name: 'Guarani das Missões', code: '09506' },
            { name: 'Harmonia', code: '09555' },
            { name: 'Herval', code: '07104' },
            { name: 'Herveiras', code: '09571' },
            { name: 'Horizontina', code: '09605' },
            { name: 'Hulha Negra', code: '09654' },
            { name: 'Humaitá', code: '09704' },
            { name: 'Ibarama', code: '09753' },
            { name: 'Ibiaçá', code: '09803' },
            { name: 'Ibiraiaras', code: '09902' },
            { name: 'Ibirapuitã', code: '09951' },
            { name: 'Ibirubá', code: '10009' },
            { name: 'Igrejinha', code: '10108' },
            { name: 'Ijuí', code: '10207' },
            { name: 'Ilópolis', code: '10306' },
            { name: 'Imbé', code: '10330' },
            { name: 'Imigrante', code: '10363' },
            { name: 'Independência', code: '10405' },
            { name: 'Inhacorá', code: '10413' },
            { name: 'Ipê', code: '10439' },
            { name: 'Ipiranga do Sul', code: '10462' },
            { name: 'Iraí', code: '10504' },
            { name: 'Itaara', code: '10538' },
            { name: 'Itacurubi', code: '10553' },
            { name: 'Itapuca', code: '10579' },
            { name: 'Itaqui', code: '10603' },
            { name: 'Itati', code: '10652' },
            { name: 'Itatiba do Sul', code: '10702' },
            { name: 'Ivorá', code: '10751' },
            { name: 'Ivoti', code: '10801' },
            { name: 'Jaboticaba', code: '10850' },
            { name: 'Jacuizinho', code: '10876' },
            { name: 'Jacutinga', code: '10900' },
            { name: 'Jaguarão', code: '11007' },
            { name: 'Jaguari', code: '11106' },
            { name: 'Jaquirana', code: '11122' },
            { name: 'Jari', code: '11130' },
            { name: 'Jóia', code: '11155' },
            { name: 'Júlio de Castilhos', code: '11205' },
            { name: 'Lagoa Bonita do Sul', code: '11239' },
            { name: 'Lagoa dos Três Cantos', code: '11270' },
            { name: 'Lagoa Vermelha', code: '11304' },
            { name: 'Lagoão', code: '11254' },
            { name: 'Lajeado', code: '11403' },
            { name: 'Lajeado do Bugre', code: '11429' },
            { name: 'Lavras do Sul', code: '11502' },
            { name: 'Liberato Salzano', code: '11601' },
            { name: 'Lindolfo Collor', code: '11627' },
            { name: 'Linha Nova', code: '11643' },
            { name: 'Maçambará', code: '11718' },
            { name: 'Machadinho', code: '11700' },
            { name: 'Mampituba', code: '11734' },
            { name: 'Manoel Viana', code: '11759' },
            { name: 'Maquiné', code: '11775' },
            { name: 'Maratá', code: '11791' },
            { name: 'Marau', code: '11809' },
            { name: 'Marcelino Ramos', code: '11908' },
            { name: 'Mariana Pimentel', code: '11981' },
            { name: 'Mariano Moro', code: '12005' },
            { name: 'Marques de Souza', code: '12054' },
            { name: 'Mata', code: '12104' },
            { name: 'Mato Castelhano', code: '12138' },
            { name: 'Mato Leitão', code: '12153' },
            { name: 'Mato Queimado', code: '12179' },
            { name: 'Maximiliano de Almeida', code: '12203' },
            { name: 'Minas do Leão', code: '12252' },
            { name: 'Miraguaí', code: '12302' },
            { name: 'Montauri', code: '12351' },
            { name: 'Monte Alegre dos Campos', code: '12377' },
            { name: 'Monte Belo do Sul', code: '12385' },
            { name: 'Montenegro', code: '12401' },
            { name: 'Mormaço', code: '12427' },
            { name: 'Morrinhos do Sul', code: '12443' },
            { name: 'Morro Redondo', code: '12450' },
            { name: 'Morro Reuter', code: '12476' },
            { name: 'Mostardas', code: '12500' },
            { name: 'Muçum', code: '12609' },
            { name: 'Muitos Capões', code: '12617' },
            { name: 'Muliterno', code: '12625' },
            { name: 'Não-Me-Toque', code: '12658' },
            { name: 'Nicolau Vergueiro', code: '12674' },
            { name: 'Nonoai', code: '12708' },
            { name: 'Nova Alvorada', code: '12757' },
            { name: 'Nova Araçá', code: '12807' },
            { name: 'Nova Bassano', code: '12906' },
            { name: 'Nova Boa Vista', code: '12955' },
            { name: 'Nova Bréscia', code: '13003' },
            { name: 'Nova Candelária', code: '13011' },
            { name: 'Nova Esperança do Sul', code: '13037' },
            { name: 'Nova Hartz', code: '13060' },
            { name: 'Nova Pádua', code: '13086' },
            { name: 'Nova Palma', code: '13102' },
            { name: 'Nova Petrópolis', code: '13201' },
            { name: 'Nova Prata', code: '13300' },
            { name: 'Nova Ramada', code: '13334' },
            { name: 'Nova Roma do Sul', code: '13359' },
            { name: 'Nova Santa Rita', code: '13375' },
            { name: 'Novo Barreiro', code: '13490' },
            { name: 'Novo Cabrais', code: '13391' },
            { name: 'Novo Hamburgo', code: '13409' },
            { name: 'Novo Machado', code: '13425' },
            { name: 'Novo Tiradentes', code: '13441' },
            { name: 'Novo Xingu', code: '13466' },
            { name: 'Osório', code: '13508' },
            { name: 'Paim Filho', code: '13607' },
            { name: 'Palmares do Sul', code: '13656' },
            { name: 'Palmeira das Missões', code: '13706' },
            { name: 'Palmitinho', code: '13805' },
            { name: 'Panambi', code: '13904' },
            { name: 'Pantano Grande', code: '13953' },
            { name: 'Paraí', code: '14001' },
            { name: 'Paraíso do Sul', code: '14027' },
            { name: 'Pareci Novo', code: '14035' },
            { name: 'Parobé', code: '14050' },
            { name: 'Passa Sete', code: '14068' },
            { name: 'Passo do Sobrado', code: '14076' },
            { name: 'Passo Fundo', code: '14100' },
            { name: 'Paulo Bento', code: '14134' },
            { name: 'Paverama', code: '14159' },
            { name: 'Pedras Altas', code: '14175' },
            { name: 'Pedro Osório', code: '14209' },
            { name: 'Pejuçara', code: '14308' },
            { name: 'Pelotas', code: '14407' },
            { name: 'Picada Café', code: '14423' },
            { name: 'Pinhal', code: '14456' },
            { name: 'Pinhal da Serra', code: '14464' },
            { name: 'Pinhal Grande', code: '14472' },
            { name: 'Pinheirinho do Vale', code: '14498' },
            { name: 'Pinheiro Machado', code: '14506' },
            { name: 'Pinto Bandeira', code: '14548' },
            { name: 'Pirapó', code: '14555' },
            { name: 'Piratini', code: '14605' },
            { name: 'Planalto', code: '14704' },
            { name: 'Poço das Antas', code: '14753' },
            { name: 'Pontão', code: '14779' },
            { name: 'Ponte Preta', code: '14787' },
            { name: 'Portão', code: '14803' },
            { name: 'Porto Alegre', code: '14902' },
            { name: 'Porto Lucena', code: '15008' },
            { name: 'Porto Mauá', code: '15057' },
            { name: 'Porto Vera Cruz', code: '15073' },
            { name: 'Porto Xavier', code: '15107' },
            { name: 'Pouso Novo', code: '15131' },
            { name: 'Presidente Lucena', code: '15149' },
            { name: 'Progresso', code: '15156' },
            { name: 'Protásio Alves', code: '15172' },
            { name: 'Putinga', code: '15206' },
            { name: 'Quaraí', code: '15305' },
            { name: 'Quatro Irmãos', code: '15313' },
            { name: 'Quevedos', code: '15321' },
            { name: 'Quinze de Novembro', code: '15354' },
            { name: 'Redentora', code: '15404' },
            { name: 'Relvado', code: '15453' },
            { name: 'Restinga Sêca', code: '15503' },
            { name: 'Rio dos Índios', code: '15552' },
            { name: 'Rio Grande', code: '15602' },
            { name: 'Rio Pardo', code: '15701' },
            { name: 'Riozinho', code: '15750' },
            { name: 'Roca Sales', code: '15800' },
            { name: 'Rodeio Bonito', code: '15909' },
            { name: 'Rolador', code: '15958' },
            { name: 'Rolante', code: '16006' },
            { name: 'Ronda Alta', code: '16105' },
            { name: 'Rondinha', code: '16204' },
            { name: 'Roque Gonzales', code: '16303' },
            { name: 'Rosário do Sul', code: '16402' },
            { name: 'Sagrada Família', code: '16428' },
            { name: 'Saldanha Marinho', code: '16436' },
            { name: 'Salto do Jacuí', code: '16451' },
            { name: 'Salvador das Missões', code: '16477' },
            { name: 'Salvador do Sul', code: '16501' },
            { name: 'Sananduva', code: '16600' },
            { name: 'Santa Bárbara do Sul', code: '16709' },
            { name: 'Santa Cecília do Sul', code: '16733' },
            { name: 'Santa Clara do Sul', code: '16758' },
            { name: 'Santa Cruz do Sul', code: '16808' },
            { name: 'Santa Margarida do Sul', code: '16972' },
            { name: 'Santa Maria', code: '16907' },
            { name: 'Santa Maria do Herval', code: '16956' },
            { name: 'Santa Rosa', code: '17202' },
            { name: 'Santa Tereza', code: '17251' },
            { name: 'Santa Vitória do Palmar', code: '17301' },
            { name: 'Santana da Boa Vista', code: '17004' },
            { name: "Sant'Ana do Livramento", code: '17103' },
            { name: 'Santiago', code: '17400' },
            { name: 'Santo Ângelo', code: '17509' },
            { name: 'Santo Antônio da Patrulha', code: '17608' },
            { name: 'Santo Antônio das Missões', code: '17707' },
            { name: 'Santo Antônio do Palma', code: '17558' },
            { name: 'Santo Antônio do Planalto', code: '17756' },
            { name: 'Santo Augusto', code: '17806' },
            { name: 'Santo Cristo', code: '17905' },
            { name: 'Santo Expedito do Sul', code: '17954' },
            { name: 'São Borja', code: '18002' },
            { name: 'São Domingos do Sul', code: '18051' },
            { name: 'São Francisco de Assis', code: '18101' },
            { name: 'São Francisco de Paula', code: '18200' },
            { name: 'São Gabriel', code: '18309' },
            { name: 'São Jerônimo', code: '18408' },
            { name: 'São João da Urtiga', code: '18424' },
            { name: 'São João do Polêsine', code: '18432' },
            { name: 'São Jorge', code: '18440' },
            { name: 'São José das Missões', code: '18457' },
            { name: 'São José do Herval', code: '18465' },
            { name: 'São José do Hortêncio', code: '18481' },
            { name: 'São José do Inhacorá', code: '18499' },
            { name: 'São José do Norte', code: '18507' },
            { name: 'São José do Ouro', code: '18606' },
            { name: 'São José do Sul', code: '18614' },
            { name: 'São José dos Ausentes', code: '18622' },
            { name: 'São Leopoldo', code: '18705' },
            { name: 'São Lourenço do Sul', code: '18804' },
            { name: 'São Luiz Gonzaga', code: '18903' },
            { name: 'São Marcos', code: '19000' },
            { name: 'São Martinho', code: '19109' },
            { name: 'São Martinho da Serra', code: '19125' },
            { name: 'São Miguel das Missões', code: '19158' },
            { name: 'São Nicolau', code: '19208' },
            { name: 'São Paulo das Missões', code: '19307' },
            { name: 'São Pedro da Serra', code: '19356' },
            { name: 'São Pedro das Missões', code: '19364' },
            { name: 'São Pedro do Butiá', code: '19372' },
            { name: 'São Pedro do Sul', code: '19406' },
            { name: 'São Sebastião do Caí', code: '19505' },
            { name: 'São Sepé', code: '19604' },
            { name: 'São Valentim', code: '19703' },
            { name: 'São Valentim do Sul', code: '19711' },
            { name: 'São Valério do Sul', code: '19737' },
            { name: 'São Vendelino', code: '19752' },
            { name: 'São Vicente do Sul', code: '19802' },
            { name: 'Sapiranga', code: '19901' },
            { name: 'Sapucaia do Sul', code: '20008' },
            { name: 'Sarandi', code: '20107' },
            { name: 'Seberi', code: '20206' },
            { name: 'Sede Nova', code: '20230' },
            { name: 'Segredo', code: '20263' },
            { name: 'Selbach', code: '20305' },
            { name: 'Senador Salgado Filho', code: '20321' },
            { name: 'Sentinela do Sul', code: '20354' },
            { name: 'Serafina Corrêa', code: '20404' },
            { name: 'Sério', code: '20453' },
            { name: 'Sertão', code: '20503' },
            { name: 'Sertão Santana', code: '20552' },
            { name: 'Sete de Setembro', code: '20578' },
            { name: 'Severiano de Almeida', code: '20602' },
            { name: 'Silveira Martins', code: '20651' },
            { name: 'Sinimbu', code: '20677' },
            { name: 'Sobradinho', code: '20701' },
            { name: 'Soledade', code: '20800' },
            { name: 'Tabaí', code: '20859' },
            { name: 'Tapejara', code: '20909' },
            { name: 'Tapera', code: '21006' },
            { name: 'Tapes', code: '21105' },
            { name: 'Taquara', code: '21204' },
            { name: 'Taquari', code: '21303' },
            { name: 'Taquaruçu do Sul', code: '21329' },
            { name: 'Tavares', code: '21352' },
            { name: 'Tenente Portela', code: '21402' },
            { name: 'Terra de Areia', code: '21436' },
            { name: 'Teutônia', code: '21451' },
            { name: 'Tio Hugo', code: '21469' },
            { name: 'Tiradentes do Sul', code: '21477' },
            { name: 'Toropi', code: '21493' },
            { name: 'Torres', code: '21501' },
            { name: 'Tramandaí', code: '21600' },
            { name: 'Travesseiro', code: '21626' },
            { name: 'Três Arroios', code: '21634' },
            { name: 'Três Cachoeiras', code: '21667' },
            { name: 'Três Coroas', code: '21709' },
            { name: 'Três de Maio', code: '21808' },
            { name: 'Três Forquilhas', code: '21832' },
            { name: 'Três Palmeiras', code: '21857' },
            { name: 'Três Passos', code: '21907' },
            { name: 'Trindade do Sul', code: '21956' },
            { name: 'Triunfo', code: '22004' },
            { name: 'Tucunduva', code: '22103' },
            { name: 'Tunas', code: '22152' },
            { name: 'Tupanci do Sul', code: '22186' },
            { name: 'Tupanciretã', code: '22202' },
            { name: 'Tupandi', code: '22251' },
            { name: 'Tuparendi', code: '22301' },
            { name: 'Turuçu', code: '22327' },
            { name: 'Ubiretama', code: '22343' },
            { name: 'União da Serra', code: '22350' },
            { name: 'Unistalda', code: '22376' },
            { name: 'Uruguaiana', code: '22400' },
            { name: 'Vacaria', code: '22509' },
            { name: 'Vale do Sol', code: '22533' },
            { name: 'Vale Real', code: '22541' },
            { name: 'Vale Verde', code: '22525' },
            { name: 'Vanini', code: '22558' },
            { name: 'Venâncio Aires', code: '22608' },
            { name: 'Vera Cruz', code: '22707' },
            { name: 'Veranópolis', code: '22806' },
            { name: 'Vespasiano Corrêa', code: '22855' },
            { name: 'Viadutos', code: '22905' },
            { name: 'Viamão', code: '23002' },
            { name: 'Vicente Dutra', code: '23101' },
            { name: 'Victor Graeff', code: '23200' },
            { name: 'Vila Flores', code: '23309' },
            { name: 'Vila Lângaro', code: '23358' },
            { name: 'Vila Maria', code: '23408' },
            { name: 'Vila Nova do Sul', code: '23457' },
            { name: 'Vista Alegre', code: '23507' },
            { name: 'Vista Alegre do Prata', code: '23606' },
            { name: 'Vista Gaúcha', code: '23705' },
            { name: 'Vitória das Missões', code: '23754' },
            { name: 'Westfália', code: '23770' },
            { name: 'Xangri-lá', code: '23804' },
        ],
    },
    {
        abbreviation: StateAbbreviation.MT,
        name: 'Mato Grosso',
        code: '51',
        cities: [
            { name: 'Acorizal', code: '00102' },
            { name: 'Água Boa', code: '00201' },
            { name: 'Alta Floresta', code: '00250' },
            { name: 'Alto Araguaia', code: '00300' },
            { name: 'Alto Boa Vista', code: '00359' },
            { name: 'Alto Garças', code: '00409' },
            { name: 'Alto Paraguai', code: '00508' },
            { name: 'Alto Taquari', code: '00607' },
            { name: 'Apiacás', code: '00805' },
            { name: 'Araguaiana', code: '01001' },
            { name: 'Araguainha', code: '01209' },
            { name: 'Araputanga', code: '01258' },
            { name: 'Arenápolis', code: '01308' },
            { name: 'Aripuanã', code: '01407' },
            { name: 'Barão de Melgaço', code: '01605' },
            { name: 'Barra do Bugres', code: '01704' },
            { name: 'Barra do Garças', code: '01803' },
            { name: 'Bom Jesus do Araguaia', code: '01852' },
            { name: 'Brasnorte', code: '01902' },
            { name: 'Cáceres', code: '02504' },
            { name: 'Campinápolis', code: '02603' },
            { name: 'Campo Novo do Parecis', code: '02637' },
            { name: 'Campo Verde', code: '02678' },
            { name: 'Campos de Júlio', code: '02686' },
            { name: 'Canabrava do Norte', code: '02694' },
            { name: 'Canarana', code: '02702' },
            { name: 'Carlinda', code: '02793' },
            { name: 'Castanheira', code: '02850' },
            { name: 'Chapada dos Guimarães', code: '03007' },
            { name: 'Cláudia', code: '03056' },
            { name: 'Cocalinho', code: '03106' },
            { name: 'Colíder', code: '03205' },
            { name: 'Colniza', code: '03254' },
            { name: 'Comodoro', code: '03304' },
            { name: 'Confresa', code: '03353' },
            { name: "Conquista D'Oeste", code: '03361' },
            { name: 'Cotriguaçu', code: '03379' },
            { name: 'Cuiabá', code: '03403' },
            { name: 'Curvelândia', code: '03437' },
            { name: 'Denise', code: '03452' },
            { name: 'Diamantino', code: '03502' },
            { name: 'Dom Aquino', code: '03601' },
            { name: 'Feliz Natal', code: '03700' },
            { name: "Figueirópolis D'Oeste", code: '03809' },
            { name: 'Gaúcha do Norte', code: '03858' },
            { name: 'General Carneiro', code: '03908' },
            { name: "Glória D'Oeste", code: '03957' },
            { name: 'Guarantã do Norte', code: '04104' },
            { name: 'Guiratinga', code: '04203' },
            { name: 'Indiavaí', code: '04500' },
            { name: 'Ipiranga do Norte', code: '04526' },
            { name: 'Itanhangá', code: '04542' },
            { name: 'Itaúba', code: '04559' },
            { name: 'Itiquira', code: '04609' },
            { name: 'Jaciara', code: '04807' },
            { name: 'Jangada', code: '04906' },
            { name: 'Jauru', code: '05002' },
            { name: 'Juara', code: '05101' },
            { name: 'Juína', code: '05150' },
            { name: 'Juruena', code: '05176' },
            { name: 'Juscimeira', code: '05200' },
            { name: "Lambari D'Oeste", code: '05234' },
            { name: 'Lucas do Rio Verde', code: '05259' },
            { name: 'Luciara', code: '05309' },
            { name: 'Marcelândia', code: '05580' },
            { name: 'Matupá', code: '05606' },
            { name: "Mirassol d'Oeste", code: '05622' },
            { name: 'Nobres', code: '05903' },
            { name: 'Nortelândia', code: '06000' },
            { name: 'Nossa Senhora do Livramento', code: '06109' },
            { name: 'Nova Bandeirantes', code: '06158' },
            { name: 'Nova Brasilândia', code: '06208' },
            { name: 'Nova Canaã do Norte', code: '06216' },
            { name: 'Nova Guarita', code: '08808' },
            { name: 'Nova Lacerda', code: '06182' },
            { name: 'Nova Marilândia', code: '08857' },
            { name: 'Nova Maringá', code: '08907' },
            { name: 'Nova Monte Verde', code: '08956' },
            { name: 'Nova Mutum', code: '06224' },
            { name: 'Nova Nazaré', code: '06174' },
            { name: 'Nova Olímpia', code: '06232' },
            { name: 'Nova Santa Helena', code: '06190' },
            { name: 'Nova Ubiratã', code: '06240' },
            { name: 'Nova Xavantina', code: '06257' },
            { name: 'Novo Horizonte do Norte', code: '06273' },
            { name: 'Novo Mundo', code: '06265' },
            { name: 'Novo Santo Antônio', code: '06315' },
            { name: 'Novo São Joaquim', code: '06281' },
            { name: 'Paranaíta', code: '06299' },
            { name: 'Paranatinga', code: '06307' },
            { name: 'Pedra Preta', code: '06372' },
            { name: 'Peixoto de Azevedo', code: '06422' },
            { name: 'Planalto da Serra', code: '06455' },
            { name: 'Poconé', code: '06505' },
            { name: 'Pontal do Araguaia', code: '06653' },
            { name: 'Ponte Branca', code: '06703' },
            { name: 'Pontes e Lacerda', code: '06752' },
            { name: 'Porto Alegre do Norte', code: '06778' },
            { name: 'Porto dos Gaúchos', code: '06802' },
            { name: 'Porto Esperidião', code: '06828' },
            { name: 'Porto Estrela', code: '06851' },
            { name: 'Poxoréu', code: '07008' },
            { name: 'Primavera do Leste', code: '07040' },
            { name: 'Querência', code: '07065' },
            { name: 'Reserva do Cabaçal', code: '07156' },
            { name: 'Ribeirão Cascalheira', code: '07180' },
            { name: 'Ribeirãozinho', code: '07198' },
            { name: 'Rio Branco', code: '07206' },
            { name: 'Rondolândia', code: '07578' },
            { name: 'Rondonópolis', code: '07602' },
            { name: 'Rosário Oeste', code: '07701' },
            { name: 'Salto do Céu', code: '07750' },
            { name: 'Santa Carmem', code: '07248' },
            { name: 'Santa Cruz do Xingu', code: '07743' },
            { name: 'Santa Rita do Trivelato', code: '07768' },
            { name: 'Santa Terezinha', code: '07776' },
            { name: 'Santo Afonso', code: '07263' },
            { name: 'Santo Antônio de Leverger', code: '07800' },
            { name: 'Santo Antônio do Leste', code: '07792' },
            { name: 'São Félix do Araguaia', code: '07859' },
            { name: 'São José do Povo', code: '07297' },
            { name: 'São José do Rio Claro', code: '07305' },
            { name: 'São José do Xingu', code: '07354' },
            { name: 'São José dos Quatro Marcos', code: '07107' },
            { name: 'São Pedro da Cipa', code: '07404' },
            { name: 'Sapezal', code: '07875' },
            { name: 'Serra Nova Dourada', code: '07883' },
            { name: 'Sinop', code: '07909' },
            { name: 'Sorriso', code: '07925' },
            { name: 'Tabaporã', code: '07941' },
            { name: 'Tangará da Serra', code: '07958' },
            { name: 'Tapurah', code: '08006' },
            { name: 'Terra Nova do Norte', code: '08055' },
            { name: 'Tesouro', code: '08105' },
            { name: 'Torixoréu', code: '08204' },
            { name: 'União do Sul', code: '08303' },
            { name: 'Vale de São Domingos', code: '08352' },
            { name: 'Várzea Grande', code: '08402' },
            { name: 'Vera', code: '08501' },
            { name: 'Vila Bela da Santíssima Trindade', code: '05507' },
            { name: 'Vila Rica', code: '08600' },
        ],
    },
    {
        abbreviation: StateAbbreviation.AC,
        name: 'Acre',
        code: '12',
        cities: [
            { name: 'Acrelândia', code: '00013' },
            { name: 'Assis Brasil', code: '00054' },
            { name: 'Brasiléia', code: '00104' },
            { name: 'Bujari', code: '00138' },
            { name: 'Capixaba', code: '00179' },
            { name: 'Cruzeiro do Sul', code: '00203' },
            { name: 'Epitaciolândia', code: '00252' },
            { name: 'Feijó', code: '00302' },
            { name: 'Jordão', code: '00328' },
            { name: 'Mâncio Lima', code: '00336' },
            { name: 'Manoel Urbano', code: '00344' },
            { name: 'Marechal Thaumaturgo', code: '00351' },
            { name: 'Plácido de Castro', code: '00385' },
            { name: 'Porto Acre', code: '00807' },
            { name: 'Porto Walter', code: '00393' },
            { name: 'Rio Branco', code: '00401' },
            { name: 'Rodrigues Alves', code: '00427' },
            { name: 'Santa Rosa do Purus', code: '00435' },
            { name: 'Sena Madureira', code: '00500' },
            { name: 'Senador Guiomard', code: '00450' },
            { name: 'Tarauacá', code: '00609' },
            { name: 'Xapuri', code: '00708' },
        ],
    },
    {
        abbreviation: StateAbbreviation.SP,
        name: 'São Paulo',
        code: '35',
        cities: [
            { name: 'Adamantina', code: '00105' },
            { name: 'Adolfo', code: '00204' },
            { name: 'Aguaí', code: '00303' },
            { name: 'Águas da Prata', code: '00402' },
            { name: 'Águas de Lindóia', code: '00501' },
            { name: 'Águas de Santa Bárbara', code: '00550' },
            { name: 'Águas de São Pedro', code: '00600' },
            { name: 'Agudos', code: '00709' },
            { name: 'Alambari', code: '00758' },
            { name: 'Alfredo Marcondes', code: '00808' },
            { name: 'Altair', code: '00907' },
            { name: 'Altinópolis', code: '01004' },
            { name: 'Alto Alegre', code: '01103' },
            { name: 'Alumínio', code: '01152' },
            { name: 'Álvares Florence', code: '01202' },
            { name: 'Álvares Machado', code: '01301' },
            { name: 'Álvaro de Carvalho', code: '01400' },
            { name: 'Alvinlândia', code: '01509' },
            { name: 'Americana', code: '01608' },
            { name: 'Américo Brasiliense', code: '01707' },
            { name: 'Américo de Campos', code: '01806' },
            { name: 'Amparo', code: '01905' },
            { name: 'Analândia', code: '02002' },
            { name: 'Andradina', code: '02101' },
            { name: 'Angatuba', code: '02200' },
            { name: 'Anhembi', code: '02309' },
            { name: 'Anhumas', code: '02408' },
            { name: 'Aparecida', code: '02507' },
            { name: "Aparecida d'Oeste", code: '02606' },
            { name: 'Apiaí', code: '02705' },
            { name: 'Araçariguama', code: '02754' },
            { name: 'Araçatuba', code: '02804' },
            { name: 'Araçoiaba da Serra', code: '02903' },
            { name: 'Aramina', code: '03000' },
            { name: 'Arandu', code: '03109' },
            { name: 'Arapeí', code: '03158' },
            { name: 'Araraquara', code: '03208' },
            { name: 'Araras', code: '03307' },
            { name: 'Arco-Íris', code: '03356' },
            { name: 'Arealva', code: '03406' },
            { name: 'Areias', code: '03505' },
            { name: 'Areiópolis', code: '03604' },
            { name: 'Ariranha', code: '03703' },
            { name: 'Artur Nogueira', code: '03802' },
            { name: 'Arujá', code: '03901' },
            { name: 'Aspásia', code: '03950' },
            { name: 'Assis', code: '04008' },
            { name: 'Atibaia', code: '04107' },
            { name: 'Auriflama', code: '04206' },
            { name: 'Avaí', code: '04305' },
            { name: 'Avanhandava', code: '04404' },
            { name: 'Avaré', code: '04503' },
            { name: 'Bady Bassitt', code: '04602' },
            { name: 'Balbinos', code: '04701' },
            { name: 'Bálsamo', code: '04800' },
            { name: 'Bananal', code: '04909' },
            { name: 'Barão de Antonina', code: '05005' },
            { name: 'Barbosa', code: '05104' },
            { name: 'Bariri', code: '05203' },
            { name: 'Barra Bonita', code: '05302' },
            { name: 'Barra do Chapéu', code: '05351' },
            { name: 'Barra do Turvo', code: '05401' },
            { name: 'Barretos', code: '05500' },
            { name: 'Barrinha', code: '05609' },
            { name: 'Barueri', code: '05708' },
            { name: 'Bastos', code: '05807' },
            { name: 'Batatais', code: '05906' },
            { name: 'Bauru', code: '06003' },
            { name: 'Bebedouro', code: '06102' },
            { name: 'Bento de Abreu', code: '06201' },
            { name: 'Bernardino de Campos', code: '06300' },
            { name: 'Bertioga', code: '06359' },
            { name: 'Bilac', code: '06409' },
            { name: 'Birigui', code: '06508' },
            { name: 'Biritiba Mirim', code: '06607' },
            { name: 'Boa Esperança do Sul', code: '06706' },
            { name: 'Bocaina', code: '06805' },
            { name: 'Bofete', code: '06904' },
            { name: 'Boituva', code: '07001' },
            { name: 'Bom Jesus dos Perdões', code: '07100' },
            { name: 'Bom Sucesso de Itararé', code: '07159' },
            { name: 'Borá', code: '07209' },
            { name: 'Boracéia', code: '07308' },
            { name: 'Borborema', code: '07407' },
            { name: 'Borebi', code: '07456' },
            { name: 'Botucatu', code: '07506' },
            { name: 'Bragança Paulista', code: '07605' },
            { name: 'Braúna', code: '07704' },
            { name: 'Brejo Alegre', code: '07753' },
            { name: 'Brodowski', code: '07803' },
            { name: 'Brotas', code: '07902' },
            { name: 'Buri', code: '08009' },
            { name: 'Buritama', code: '08108' },
            { name: 'Buritizal', code: '08207' },
            { name: 'Cabrália Paulista', code: '08306' },
            { name: 'Cabreúva', code: '08405' },
            { name: 'Caçapava', code: '08504' },
            { name: 'Cachoeira Paulista', code: '08603' },
            { name: 'Caconde', code: '08702' },
            { name: 'Cafelândia', code: '08801' },
            { name: 'Caiabu', code: '08900' },
            { name: 'Caieiras', code: '09007' },
            { name: 'Caiuá', code: '09106' },
            { name: 'Cajamar', code: '09205' },
            { name: 'Cajati', code: '09254' },
            { name: 'Cajobi', code: '09304' },
            { name: 'Cajuru', code: '09403' },
            { name: 'Campina do Monte Alegre', code: '09452' },
            { name: 'Campinas', code: '09502' },
            { name: 'Campo Limpo Paulista', code: '09601' },
            { name: 'Campos do Jordão', code: '09700' },
            { name: 'Campos Novos Paulista', code: '09809' },
            { name: 'Cananéia', code: '09908' },
            { name: 'Canas', code: '09957' },
            { name: 'Cândido Mota', code: '10005' },
            { name: 'Cândido Rodrigues', code: '10104' },
            { name: 'Canitar', code: '10153' },
            { name: 'Capão Bonito', code: '10203' },
            { name: 'Capela do Alto', code: '10302' },
            { name: 'Capivari', code: '10401' },
            { name: 'Caraguatatuba', code: '10500' },
            { name: 'Carapicuíba', code: '10609' },
            { name: 'Cardoso', code: '10708' },
            { name: 'Casa Branca', code: '10807' },
            { name: 'Cássia dos Coqueiros', code: '10906' },
            { name: 'Castilho', code: '11003' },
            { name: 'Catanduva', code: '11102' },
            { name: 'Catiguá', code: '11201' },
            { name: 'Cedral', code: '11300' },
            { name: 'Cerqueira César', code: '11409' },
            { name: 'Cerquilho', code: '11508' },
            { name: 'Cesário Lange', code: '11607' },
            { name: 'Charqueada', code: '11706' },
            { name: 'Chavantes', code: '57204' },
            { name: 'Clementina', code: '11904' },
            { name: 'Colina', code: '12001' },
            { name: 'Colômbia', code: '12100' },
            { name: 'Conchal', code: '12209' },
            { name: 'Conchas', code: '12308' },
            { name: 'Cordeirópolis', code: '12407' },
            { name: 'Coroados', code: '12506' },
            { name: 'Coronel Macedo', code: '12605' },
            { name: 'Corumbataí', code: '12704' },
            { name: 'Cosmópolis', code: '12803' },
            { name: 'Cosmorama', code: '12902' },
            { name: 'Cotia', code: '13009' },
            { name: 'Cravinhos', code: '13108' },
            { name: 'Cristais Paulista', code: '13207' },
            { name: 'Cruzália', code: '13306' },
            { name: 'Cruzeiro', code: '13405' },
            { name: 'Cubatão', code: '13504' },
            { name: 'Cunha', code: '13603' },
            { name: 'Descalvado', code: '13702' },
            { name: 'Diadema', code: '13801' },
            { name: 'Dirce Reis', code: '13850' },
            { name: 'Divinolândia', code: '13900' },
            { name: 'Dobrada', code: '14007' },
            { name: 'Dois Córregos', code: '14106' },
            { name: 'Dolcinópolis', code: '14205' },
            { name: 'Dourado', code: '14304' },
            { name: 'Dracena', code: '14403' },
            { name: 'Duartina', code: '14502' },
            { name: 'Dumont', code: '14601' },
            { name: 'Echaporã', code: '14700' },
            { name: 'Eldorado', code: '14809' },
            { name: 'Elias Fausto', code: '14908' },
            { name: 'Elisiário', code: '14924' },
            { name: 'Embaúba', code: '14957' },
            { name: 'Embu das Artes', code: '15004' },
            { name: 'Embu-Guaçu', code: '15103' },
            { name: 'Emilianópolis', code: '15129' },
            { name: 'Engenheiro Coelho', code: '15152' },
            { name: 'Espírito Santo do Pinhal', code: '15186' },
            { name: 'Espírito Santo do Turvo', code: '15194' },
            { name: 'Estiva Gerbi', code: '57303' },
            { name: 'Estrela do Norte', code: '15301' },
            { name: "Estrela d'Oeste", code: '15202' },
            { name: 'Euclides da Cunha Paulista', code: '15350' },
            { name: 'Fartura', code: '15400' },
            { name: 'Fernando Prestes', code: '15608' },
            { name: 'Fernandópolis', code: '15509' },
            { name: 'Fernão', code: '15657' },
            { name: 'Ferraz de Vasconcelos', code: '15707' },
            { name: 'Flora Rica', code: '15806' },
            { name: 'Floreal', code: '15905' },
            { name: 'Flórida Paulista', code: '16002' },
            { name: 'Florínea', code: '16101' },
            { name: 'Franca', code: '16200' },
            { name: 'Francisco Morato', code: '16309' },
            { name: 'Franco da Rocha', code: '16408' },
            { name: 'Gabriel Monteiro', code: '16507' },
            { name: 'Gália', code: '16606' },
            { name: 'Garça', code: '16705' },
            { name: 'Gastão Vidigal', code: '16804' },
            { name: 'Gavião Peixoto', code: '16853' },
            { name: 'General Salgado', code: '16903' },
            { name: 'Getulina', code: '17000' },
            { name: 'Glicério', code: '17109' },
            { name: 'Guaiçara', code: '17208' },
            { name: 'Guaimbê', code: '17307' },
            { name: 'Guaíra', code: '17406' },
            { name: 'Guapiaçu', code: '17505' },
            { name: 'Guapiara', code: '17604' },
            { name: 'Guará', code: '17703' },
            { name: 'Guaraçaí', code: '17802' },
            { name: 'Guaraci', code: '17901' },
            { name: "Guarani d'Oeste", code: '18008' },
            { name: 'Guarantã', code: '18107' },
            { name: 'Guararapes', code: '18206' },
            { name: 'Guararema', code: '18305' },
            { name: 'Guaratinguetá', code: '18404' },
            { name: 'Guareí', code: '18503' },
            { name: 'Guariba', code: '18602' },
            { name: 'Guarujá', code: '18701' },
            { name: 'Guarulhos', code: '18800' },
            { name: 'Guatapará', code: '18859' },
            { name: 'Guzolândia', code: '18909' },
            { name: 'Herculândia', code: '19006' },
            { name: 'Holambra', code: '19055' },
            { name: 'Hortolândia', code: '19071' },
            { name: 'Iacanga', code: '19105' },
            { name: 'Iacri', code: '19204' },
            { name: 'Iaras', code: '19253' },
            { name: 'Ibaté', code: '19303' },
            { name: 'Ibirá', code: '19402' },
            { name: 'Ibirarema', code: '19501' },
            { name: 'Ibitinga', code: '19600' },
            { name: 'Ibiúna', code: '19709' },
            { name: 'Icém', code: '19808' },
            { name: 'Iepê', code: '19907' },
            { name: 'Igaraçu do Tietê', code: '20004' },
            { name: 'Igarapava', code: '20103' },
            { name: 'Igaratá', code: '20202' },
            { name: 'Iguape', code: '20301' },
            { name: 'Ilha Comprida', code: '20426' },
            { name: 'Ilha Solteira', code: '20442' },
            { name: 'Ilhabela', code: '20400' },
            { name: 'Indaiatuba', code: '20509' },
            { name: 'Indiana', code: '20608' },
            { name: 'Indiaporã', code: '20707' },
            { name: 'Inúbia Paulista', code: '20806' },
            { name: 'Ipaussu', code: '20905' },
            { name: 'Iperó', code: '21002' },
            { name: 'Ipeúna', code: '21101' },
            { name: 'Ipiguá', code: '21150' },
            { name: 'Iporanga', code: '21200' },
            { name: 'Ipuã', code: '21309' },
            { name: 'Iracemápolis', code: '21408' },
            { name: 'Irapuã', code: '21507' },
            { name: 'Irapuru', code: '21606' },
            { name: 'Itaberá', code: '21705' },
            { name: 'Itaí', code: '21804' },
            { name: 'Itajobi', code: '21903' },
            { name: 'Itaju', code: '22000' },
            { name: 'Itanhaém', code: '22109' },
            { name: 'Itaoca', code: '22158' },
            { name: 'Itapecerica da Serra', code: '22208' },
            { name: 'Itapetininga', code: '22307' },
            { name: 'Itapeva', code: '22406' },
            { name: 'Itapevi', code: '22505' },
            { name: 'Itapira', code: '22604' },
            { name: 'Itapirapuã Paulista', code: '22653' },
            { name: 'Itápolis', code: '22703' },
            { name: 'Itaporanga', code: '22802' },
            { name: 'Itapuí', code: '22901' },
            { name: 'Itapura', code: '23008' },
            { name: 'Itaquaquecetuba', code: '23107' },
            { name: 'Itararé', code: '23206' },
            { name: 'Itariri', code: '23305' },
            { name: 'Itatiba', code: '23404' },
            { name: 'Itatinga', code: '23503' },
            { name: 'Itirapina', code: '23602' },
            { name: 'Itirapuã', code: '23701' },
            { name: 'Itobi', code: '23800' },
            { name: 'Itu', code: '23909' },
            { name: 'Itupeva', code: '24006' },
            { name: 'Ituverava', code: '24105' },
            { name: 'Jaborandi', code: '24204' },
            { name: 'Jaboticabal', code: '24303' },
            { name: 'Jacareí', code: '24402' },
            { name: 'Jaci', code: '24501' },
            { name: 'Jacupiranga', code: '24600' },
            { name: 'Jaguariúna', code: '24709' },
            { name: 'Jales', code: '24808' },
            { name: 'Jambeiro', code: '24907' },
            { name: 'Jandira', code: '25003' },
            { name: 'Jardinópolis', code: '25102' },
            { name: 'Jarinu', code: '25201' },
            { name: 'Jaú', code: '25300' },
            { name: 'Jeriquara', code: '25409' },
            { name: 'Joanópolis', code: '25508' },
            { name: 'João Ramalho', code: '25607' },
            { name: 'José Bonifácio', code: '25706' },
            { name: 'Júlio Mesquita', code: '25805' },
            { name: 'Jumirim', code: '25854' },
            { name: 'Jundiaí', code: '25904' },
            { name: 'Junqueirópolis', code: '26001' },
            { name: 'Juquiá', code: '26100' },
            { name: 'Juquitiba', code: '26209' },
            { name: 'Lagoinha', code: '26308' },
            { name: 'Laranjal Paulista', code: '26407' },
            { name: 'Lavínia', code: '26506' },
            { name: 'Lavrinhas', code: '26605' },
            { name: 'Leme', code: '26704' },
            { name: 'Lençóis Paulista', code: '26803' },
            { name: 'Limeira', code: '26902' },
            { name: 'Lindóia', code: '27009' },
            { name: 'Lins', code: '27108' },
            { name: 'Lorena', code: '27207' },
            { name: 'Lourdes', code: '27256' },
            { name: 'Louveira', code: '27306' },
            { name: 'Lucélia', code: '27405' },
            { name: 'Lucianópolis', code: '27504' },
            { name: 'Luís Antônio', code: '27603' },
            { name: 'Luiziânia', code: '27702' },
            { name: 'Lupércio', code: '27801' },
            { name: 'Lutécia', code: '27900' },
            { name: 'Macatuba', code: '28007' },
            { name: 'Macaubal', code: '28106' },
            { name: 'Macedônia', code: '28205' },
            { name: 'Magda', code: '28304' },
            { name: 'Mairinque', code: '28403' },
            { name: 'Mairiporã', code: '28502' },
            { name: 'Manduri', code: '28601' },
            { name: 'Marabá Paulista', code: '28700' },
            { name: 'Maracaí', code: '28809' },
            { name: 'Marapoama', code: '28858' },
            { name: 'Mariápolis', code: '28908' },
            { name: 'Marília', code: '29005' },
            { name: 'Marinópolis', code: '29104' },
            { name: 'Martinópolis', code: '29203' },
            { name: 'Matão', code: '29302' },
            { name: 'Mauá', code: '29401' },
            { name: 'Mendonça', code: '29500' },
            { name: 'Meridiano', code: '29609' },
            { name: 'Mesópolis', code: '29658' },
            { name: 'Miguelópolis', code: '29708' },
            { name: 'Mineiros do Tietê', code: '29807' },
            { name: 'Mira Estrela', code: '30003' },
            { name: 'Miracatu', code: '29906' },
            { name: 'Mirandópolis', code: '30102' },
            { name: 'Mirante do Paranapanema', code: '30201' },
            { name: 'Mirassol', code: '30300' },
            { name: 'Mirassolândia', code: '30409' },
            { name: 'Mococa', code: '30508' },
            { name: 'Mogi das Cruzes', code: '30607' },
            { name: 'Mogi Guaçu', code: '30706' },
            { name: 'Mogi Mirim', code: '30805' },
            { name: 'Mombuca', code: '30904' },
            { name: 'Monções', code: '31001' },
            { name: 'Mongaguá', code: '31100' },
            { name: 'Monte Alegre do Sul', code: '31209' },
            { name: 'Monte Alto', code: '31308' },
            { name: 'Monte Aprazível', code: '31407' },
            { name: 'Monte Azul Paulista', code: '31506' },
            { name: 'Monte Castelo', code: '31605' },
            { name: 'Monte Mor', code: '31803' },
            { name: 'Monteiro Lobato', code: '31704' },
            { name: 'Morro Agudo', code: '31902' },
            { name: 'Morungaba', code: '32009' },
            { name: 'Motuca', code: '32058' },
            { name: 'Murutinga do Sul', code: '32108' },
            { name: 'Nantes', code: '32157' },
            { name: 'Narandiba', code: '32207' },
            { name: 'Natividade da Serra', code: '32306' },
            { name: 'Nazaré Paulista', code: '32405' },
            { name: 'Neves Paulista', code: '32504' },
            { name: 'Nhandeara', code: '32603' },
            { name: 'Nipoã', code: '32702' },
            { name: 'Nova Aliança', code: '32801' },
            { name: 'Nova Campina', code: '32827' },
            { name: 'Nova Canaã Paulista', code: '32843' },
            { name: 'Nova Castilho', code: '32868' },
            { name: 'Nova Europa', code: '32900' },
            { name: 'Nova Granada', code: '33007' },
            { name: 'Nova Guataporanga', code: '33106' },
            { name: 'Nova Independência', code: '33205' },
            { name: 'Nova Luzitânia', code: '33304' },
            { name: 'Nova Odessa', code: '33403' },
            { name: 'Novais', code: '33254' },
            { name: 'Novo Horizonte', code: '33502' },
            { name: 'Nuporanga', code: '33601' },
            { name: 'Ocauçu', code: '33700' },
            { name: 'Óleo', code: '33809' },
            { name: 'Olímpia', code: '33908' },
            { name: 'Onda Verde', code: '34005' },
            { name: 'Oriente', code: '34104' },
            { name: 'Orindiúva', code: '34203' },
            { name: 'Orlândia', code: '34302' },
            { name: 'Osasco', code: '34401' },
            { name: 'Oscar Bressane', code: '34500' },
            { name: 'Osvaldo Cruz', code: '34609' },
            { name: 'Ourinhos', code: '34708' },
            { name: 'Ouro Verde', code: '34807' },
            { name: 'Ouroeste', code: '34757' },
            { name: 'Pacaembu', code: '34906' },
            { name: 'Palestina', code: '35002' },
            { name: 'Palmares Paulista', code: '35101' },
            { name: "Palmeira d'Oeste", code: '35200' },
            { name: 'Palmital', code: '35309' },
            { name: 'Panorama', code: '35408' },
            { name: 'Paraguaçu Paulista', code: '35507' },
            { name: 'Paraibuna', code: '35606' },
            { name: 'Paraíso', code: '35705' },
            { name: 'Paranapanema', code: '35804' },
            { name: 'Paranapuã', code: '35903' },
            { name: 'Parapuã', code: '36000' },
            { name: 'Pardinho', code: '36109' },
            { name: 'Pariquera-Açu', code: '36208' },
            { name: 'Parisi', code: '36257' },
            { name: 'Patrocínio Paulista', code: '36307' },
            { name: 'Paulicéia', code: '36406' },
            { name: 'Paulínia', code: '36505' },
            { name: 'Paulistânia', code: '36570' },
            { name: 'Paulo de Faria', code: '36604' },
            { name: 'Pederneiras', code: '36703' },
            { name: 'Pedra Bela', code: '36802' },
            { name: 'Pedranópolis', code: '36901' },
            { name: 'Pedregulho', code: '37008' },
            { name: 'Pedreira', code: '37107' },
            { name: 'Pedrinhas Paulista', code: '37156' },
            { name: 'Pedro de Toledo', code: '37206' },
            { name: 'Penápolis', code: '37305' },
            { name: 'Pereira Barreto', code: '37404' },
            { name: 'Pereiras', code: '37503' },
            { name: 'Peruíbe', code: '37602' },
            { name: 'Piacatu', code: '37701' },
            { name: 'Piedade', code: '37800' },
            { name: 'Pilar do Sul', code: '37909' },
            { name: 'Pindamonhangaba', code: '38006' },
            { name: 'Pindorama', code: '38105' },
            { name: 'Pinhalzinho', code: '38204' },
            { name: 'Piquerobi', code: '38303' },
            { name: 'Piquete', code: '38501' },
            { name: 'Piracaia', code: '38600' },
            { name: 'Piracicaba', code: '38709' },
            { name: 'Piraju', code: '38808' },
            { name: 'Pirajuí', code: '38907' },
            { name: 'Pirangi', code: '39004' },
            { name: 'Pirapora do Bom Jesus', code: '39103' },
            { name: 'Pirapozinho', code: '39202' },
            { name: 'Pirassununga', code: '39301' },
            { name: 'Piratininga', code: '39400' },
            { name: 'Pitangueiras', code: '39509' },
            { name: 'Planalto', code: '39608' },
            { name: 'Platina', code: '39707' },
            { name: 'Poá', code: '39806' },
            { name: 'Poloni', code: '39905' },
            { name: 'Pompéia', code: '40002' },
            { name: 'Pongaí', code: '40101' },
            { name: 'Pontal', code: '40200' },
            { name: 'Pontalinda', code: '40259' },
            { name: 'Pontes Gestal', code: '40309' },
            { name: 'Populina', code: '40408' },
            { name: 'Porangaba', code: '40507' },
            { name: 'Porto Feliz', code: '40606' },
            { name: 'Porto Ferreira', code: '40705' },
            { name: 'Potim', code: '40754' },
            { name: 'Potirendaba', code: '40804' },
            { name: 'Pracinha', code: '40853' },
            { name: 'Pradópolis', code: '40903' },
            { name: 'Praia Grande', code: '41000' },
            { name: 'Pratânia', code: '41059' },
            { name: 'Presidente Alves', code: '41109' },
            { name: 'Presidente Bernardes', code: '41208' },
            { name: 'Presidente Epitácio', code: '41307' },
            { name: 'Presidente Prudente', code: '41406' },
            { name: 'Presidente Venceslau', code: '41505' },
            { name: 'Promissão', code: '41604' },
            { name: 'Quadra', code: '41653' },
            { name: 'Quatá', code: '41703' },
            { name: 'Queiroz', code: '41802' },
            { name: 'Queluz', code: '41901' },
            { name: 'Quintana', code: '42008' },
            { name: 'Rafard', code: '42107' },
            { name: 'Rancharia', code: '42206' },
            { name: 'Redenção da Serra', code: '42305' },
            { name: 'Regente Feijó', code: '42404' },
            { name: 'Reginópolis', code: '42503' },
            { name: 'Registro', code: '42602' },
            { name: 'Restinga', code: '42701' },
            { name: 'Ribeira', code: '42800' },
            { name: 'Ribeirão Bonito', code: '42909' },
            { name: 'Ribeirão Branco', code: '43006' },
            { name: 'Ribeirão Corrente', code: '43105' },
            { name: 'Ribeirão do Sul', code: '43204' },
            { name: 'Ribeirão dos Índios', code: '43238' },
            { name: 'Ribeirão Grande', code: '43253' },
            { name: 'Ribeirão Pires', code: '43303' },
            { name: 'Ribeirão Preto', code: '43402' },
            { name: 'Rifaina', code: '43600' },
            { name: 'Rincão', code: '43709' },
            { name: 'Rinópolis', code: '43808' },
            { name: 'Rio Claro', code: '43907' },
            { name: 'Rio das Pedras', code: '44004' },
            { name: 'Rio Grande da Serra', code: '44103' },
            { name: 'Riolândia', code: '44202' },
            { name: 'Riversul', code: '43501' },
            { name: 'Rosana', code: '44251' },
            { name: 'Roseira', code: '44301' },
            { name: 'Rubiácea', code: '44400' },
            { name: 'Rubinéia', code: '44509' },
            { name: 'Sabino', code: '44608' },
            { name: 'Sagres', code: '44707' },
            { name: 'Sales', code: '44806' },
            { name: 'Sales Oliveira', code: '44905' },
            { name: 'Salesópolis', code: '45001' },
            { name: 'Salmourão', code: '45100' },
            { name: 'Saltinho', code: '45159' },
            { name: 'Salto', code: '45209' },
            { name: 'Salto de Pirapora', code: '45308' },
            { name: 'Salto Grande', code: '45407' },
            { name: 'Sandovalina', code: '45506' },
            { name: 'Santa Adélia', code: '45605' },
            { name: 'Santa Albertina', code: '45704' },
            { name: "Santa Bárbara d'Oeste", code: '45803' },
            { name: 'Santa Branca', code: '46009' },
            { name: "Santa Clara d'Oeste", code: '46108' },
            { name: 'Santa Cruz da Conceição', code: '46207' },
            { name: 'Santa Cruz da Esperança', code: '46256' },
            { name: 'Santa Cruz das Palmeiras', code: '46306' },
            { name: 'Santa Cruz do Rio Pardo', code: '46405' },
            { name: 'Santa Ernestina', code: '46504' },
            { name: 'Santa Fé do Sul', code: '46603' },
            { name: 'Santa Gertrudes', code: '46702' },
            { name: 'Santa Isabel', code: '46801' },
            { name: 'Santa Lúcia', code: '46900' },
            { name: 'Santa Maria da Serra', code: '47007' },
            { name: 'Santa Mercedes', code: '47106' },
            { name: 'Santa Rita do Passa Quatro', code: '47502' },
            { name: "Santa Rita d'Oeste", code: '47403' },
            { name: 'Santa Rosa de Viterbo', code: '47601' },
            { name: 'Santa Salete', code: '47650' },
            { name: 'Santana da Ponte Pensa', code: '47205' },
            { name: 'Santana de Parnaíba', code: '47304' },
            { name: 'Santo Anastácio', code: '47700' },
            { name: 'Santo André', code: '47809' },
            { name: 'Santo Antônio da Alegria', code: '47908' },
            { name: 'Santo Antônio de Posse', code: '48005' },
            { name: 'Santo Antônio do Aracanguá', code: '48054' },
            { name: 'Santo Antônio do Jardim', code: '48104' },
            { name: 'Santo Antônio do Pinhal', code: '48203' },
            { name: 'Santo Expedito', code: '48302' },
            { name: 'Santópolis do Aguapeí', code: '48401' },
            { name: 'Santos', code: '48500' },
            { name: 'São Bento do Sapucaí', code: '48609' },
            { name: 'São Bernardo do Campo', code: '48708' },
            { name: 'São Caetano do Sul', code: '48807' },
            { name: 'São Carlos', code: '48906' },
            { name: 'São Francisco', code: '49003' },
            { name: 'São João da Boa Vista', code: '49102' },
            { name: 'São João das Duas Pontes', code: '49201' },
            { name: 'São João de Iracema', code: '49250' },
            { name: "São João do Pau d'Alho", code: '49300' },
            { name: 'São Joaquim da Barra', code: '49409' },
            { name: 'São José da Bela Vista', code: '49508' },
            { name: 'São José do Barreiro', code: '49607' },
            { name: 'São José do Rio Pardo', code: '49706' },
            { name: 'São José do Rio Preto', code: '49805' },
            { name: 'São José dos Campos', code: '49904' },
            { name: 'São Lourenço da Serra', code: '49953' },
            { name: 'São Luiz do Paraitinga', code: '50001' },
            { name: 'São Manuel', code: '50100' },
            { name: 'São Miguel Arcanjo', code: '50209' },
            { name: 'São Paulo', code: '50308' },
            { name: 'São Pedro', code: '50407' },
            { name: 'São Pedro do Turvo', code: '50506' },
            { name: 'São Roque', code: '50605' },
            { name: 'São Sebastião', code: '50704' },
            { name: 'São Sebastião da Grama', code: '50803' },
            { name: 'São Simão', code: '50902' },
            { name: 'São Vicente', code: '51009' },
            { name: 'Sarapuí', code: '51108' },
            { name: 'Sarutaiá', code: '51207' },
            { name: 'Sebastianópolis do Sul', code: '51306' },
            { name: 'Serra Azul', code: '51405' },
            { name: 'Serra Negra', code: '51603' },
            { name: 'Serrana', code: '51504' },
            { name: 'Sertãozinho', code: '51702' },
            { name: 'Sete Barras', code: '51801' },
            { name: 'Severínia', code: '51900' },
            { name: 'Silveiras', code: '52007' },
            { name: 'Socorro', code: '52106' },
            { name: 'Sorocaba', code: '52205' },
            { name: 'Sud Mennucci', code: '52304' },
            { name: 'Sumaré', code: '52403' },
            { name: 'Suzanápolis', code: '52551' },
            { name: 'Suzano', code: '52502' },
            { name: 'Tabapuã', code: '52601' },
            { name: 'Tabatinga', code: '52700' },
            { name: 'Taboão da Serra', code: '52809' },
            { name: 'Taciba', code: '52908' },
            { name: 'Taguaí', code: '53005' },
            { name: 'Taiaçu', code: '53104' },
            { name: 'Taiúva', code: '53203' },
            { name: 'Tambaú', code: '53302' },
            { name: 'Tanabi', code: '53401' },
            { name: 'Tapiraí', code: '53500' },
            { name: 'Tapiratiba', code: '53609' },
            { name: 'Taquaral', code: '53658' },
            { name: 'Taquaritinga', code: '53708' },
            { name: 'Taquarituba', code: '53807' },
            { name: 'Taquarivaí', code: '53856' },
            { name: 'Tarabai', code: '53906' },
            { name: 'Tarumã', code: '53955' },
            { name: 'Tatuí', code: '54003' },
            { name: 'Taubaté', code: '54102' },
            { name: 'Tejupá', code: '54201' },
            { name: 'Teodoro Sampaio', code: '54300' },
            { name: 'Terra Roxa', code: '54409' },
            { name: 'Tietê', code: '54508' },
            { name: 'Timburi', code: '54607' },
            { name: 'Torre de Pedra', code: '54656' },
            { name: 'Torrinha', code: '54706' },
            { name: 'Trabiju', code: '54755' },
            { name: 'Tremembé', code: '54805' },
            { name: 'Três Fronteiras', code: '54904' },
            { name: 'Tuiuti', code: '54953' },
            { name: 'Tupã', code: '55000' },
            { name: 'Tupi Paulista', code: '55109' },
            { name: 'Turiúba', code: '55208' },
            { name: 'Turmalina', code: '55307' },
            { name: 'Ubarana', code: '55356' },
            { name: 'Ubatuba', code: '55406' },
            { name: 'Ubirajara', code: '55505' },
            { name: 'Uchoa', code: '55604' },
            { name: 'União Paulista', code: '55703' },
            { name: 'Urânia', code: '55802' },
            { name: 'Uru', code: '55901' },
            { name: 'Urupês', code: '56008' },
            { name: 'Valentim Gentil', code: '56107' },
            { name: 'Valinhos', code: '56206' },
            { name: 'Valparaíso', code: '56305' },
            { name: 'Vargem', code: '56354' },
            { name: 'Vargem Grande do Sul', code: '56404' },
            { name: 'Vargem Grande Paulista', code: '56453' },
            { name: 'Várzea Paulista', code: '56503' },
            { name: 'Vera Cruz', code: '56602' },
            { name: 'Vinhedo', code: '56701' },
            { name: 'Viradouro', code: '56800' },
            { name: 'Vista Alegre do Alto', code: '56909' },
            { name: 'Vitória Brasil', code: '56958' },
            { name: 'Votorantim', code: '57006' },
            { name: 'Votuporanga', code: '57105' },
            { name: 'Zacarias', code: '57154' },
        ],
    },
    {
        abbreviation: StateAbbreviation.ES,
        name: 'Espírito Santo',
        code: '32',
        cities: [
            { name: 'Afonso Cláudio', code: '00102' },
            { name: 'Água Doce do Norte', code: '00169' },
            { name: 'Águia Branca', code: '00136' },
            { name: 'Alegre', code: '00201' },
            { name: 'Alfredo Chaves', code: '00300' },
            { name: 'Alto Rio Novo', code: '00359' },
            { name: 'Anchieta', code: '00409' },
            { name: 'Apiacá', code: '00508' },
            { name: 'Aracruz', code: '00607' },
            { name: 'Atílio Vivácqua', code: '00706' },
            { name: 'Baixo Guandu', code: '00805' },
            { name: 'Barra de São Francisco', code: '00904' },
            { name: 'Boa Esperança', code: '01001' },
            { name: 'Bom Jesus do Norte', code: '01100' },
            { name: 'Brejetuba', code: '01159' },
            { name: 'Cachoeiro de Itapemirim', code: '01209' },
            { name: 'Cariacica', code: '01308' },
            { name: 'Castelo', code: '01407' },
            { name: 'Colatina', code: '01506' },
            { name: 'Conceição da Barra', code: '01605' },
            { name: 'Conceição do Castelo', code: '01704' },
            { name: 'Divino de São Lourenço', code: '01803' },
            { name: 'Domingos Martins', code: '01902' },
            { name: 'Dores do Rio Preto', code: '02009' },
            { name: 'Ecoporanga', code: '02108' },
            { name: 'Fundão', code: '02207' },
            { name: 'Governador Lindenberg', code: '02256' },
            { name: 'Guaçuí', code: '02306' },
            { name: 'Guarapari', code: '02405' },
            { name: 'Ibatiba', code: '02454' },
            { name: 'Ibiraçu', code: '02504' },
            { name: 'Ibitirama', code: '02553' },
            { name: 'Iconha', code: '02603' },
            { name: 'Irupi', code: '02652' },
            { name: 'Itaguaçu', code: '02702' },
            { name: 'Itapemirim', code: '02801' },
            { name: 'Itarana', code: '02900' },
            { name: 'Iúna', code: '03007' },
            { name: 'Jaguaré', code: '03056' },
            { name: 'Jerônimo Monteiro', code: '03106' },
            { name: 'João Neiva', code: '03130' },
            { name: 'Laranja da Terra', code: '03163' },
            { name: 'Linhares', code: '03205' },
            { name: 'Mantenópolis', code: '03304' },
            { name: 'Marataízes', code: '03320' },
            { name: 'Marechal Floriano', code: '03346' },
            { name: 'Marilândia', code: '03353' },
            { name: 'Mimoso do Sul', code: '03403' },
            { name: 'Montanha', code: '03502' },
            { name: 'Mucurici', code: '03601' },
            { name: 'Muniz Freire', code: '03700' },
            { name: 'Muqui', code: '03809' },
            { name: 'Nova Venécia', code: '03908' },
            { name: 'Pancas', code: '04005' },
            { name: 'Pedro Canário', code: '04054' },
            { name: 'Pinheiros', code: '04104' },
            { name: 'Piúma', code: '04203' },
            { name: 'Ponto Belo', code: '04252' },
            { name: 'Presidente Kennedy', code: '04302' },
            { name: 'Rio Bananal', code: '04351' },
            { name: 'Rio Novo do Sul', code: '04401' },
            { name: 'Santa Leopoldina', code: '04500' },
            { name: 'Santa Maria de Jetibá', code: '04559' },
            { name: 'Santa Teresa', code: '04609' },
            { name: 'São Domingos do Norte', code: '04658' },
            { name: 'São Gabriel da Palha', code: '04708' },
            { name: 'São José do Calçado', code: '04807' },
            { name: 'São Mateus', code: '04906' },
            { name: 'São Roque do Canaã', code: '04955' },
            { name: 'Serra', code: '05002' },
            { name: 'Sooretama', code: '05010' },
            { name: 'Vargem Alta', code: '05036' },
            { name: 'Venda Nova do Imigrante', code: '05069' },
            { name: 'Viana', code: '05101' },
            { name: 'Vila Pavão', code: '05150' },
            { name: 'Vila Valério', code: '05176' },
            { name: 'Vila Velha', code: '05200' },
            { name: 'Vitória', code: '05309' },
        ],
    },
    {
        abbreviation: StateAbbreviation.AL,
        name: 'Alagoas',
        code: '27',
        cities: [
            { name: 'Água Branca', code: '00102' },
            { name: 'Anadia', code: '00201' },
            { name: 'Arapiraca', code: '00300' },
            { name: 'Atalaia', code: '00409' },
            { name: 'Barra de Santo Antônio', code: '00508' },
            { name: 'Barra de São Miguel', code: '00607' },
            { name: 'Batalha', code: '00706' },
            { name: 'Belém', code: '00805' },
            { name: 'Belo Monte', code: '00904' },
            { name: 'Boca da Mata', code: '01001' },
            { name: 'Branquinha', code: '01100' },
            { name: 'Cacimbinhas', code: '01209' },
            { name: 'Cajueiro', code: '01308' },
            { name: 'Campestre', code: '01357' },
            { name: 'Campo Alegre', code: '01407' },
            { name: 'Campo Grande', code: '01506' },
            { name: 'Canapi', code: '01605' },
            { name: 'Capela', code: '01704' },
            { name: 'Carneiros', code: '01803' },
            { name: 'Chã Preta', code: '01902' },
            { name: 'Coité do Nóia', code: '02009' },
            { name: 'Colônia Leopoldina', code: '02108' },
            { name: 'Coqueiro Seco', code: '02207' },
            { name: 'Coruripe', code: '02306' },
            { name: 'Craíbas', code: '02355' },
            { name: 'Delmiro Gouveia', code: '02405' },
            { name: 'Dois Riachos', code: '02504' },
            { name: 'Estrela de Alagoas', code: '02553' },
            { name: 'Feira Grande', code: '02603' },
            { name: 'Feliz Deserto', code: '02702' },
            { name: 'Flexeiras', code: '02801' },
            { name: 'Girau do Ponciano', code: '02900' },
            { name: 'Ibateguara', code: '03007' },
            { name: 'Igaci', code: '03106' },
            { name: 'Igreja Nova', code: '03205' },
            { name: 'Inhapi', code: '03304' },
            { name: 'Jacaré dos Homens', code: '03403' },
            { name: 'Jacuípe', code: '03502' },
            { name: 'Japaratinga', code: '03601' },
            { name: 'Jaramataia', code: '03700' },
            { name: 'Jequiá da Praia', code: '03759' },
            { name: 'Joaquim Gomes', code: '03809' },
            { name: 'Jundiá', code: '03908' },
            { name: 'Junqueiro', code: '04005' },
            { name: 'Lagoa da Canoa', code: '04104' },
            { name: 'Limoeiro de Anadia', code: '04203' },
            { name: 'Maceió', code: '04302' },
            { name: 'Major Isidoro', code: '04401' },
            { name: 'Mar Vermelho', code: '04906' },
            { name: 'Maragogi', code: '04500' },
            { name: 'Maravilha', code: '04609' },
            { name: 'Marechal Deodoro', code: '04708' },
            { name: 'Maribondo', code: '04807' },
            { name: 'Mata Grande', code: '05002' },
            { name: 'Matriz de Camaragibe', code: '05101' },
            { name: 'Messias', code: '05200' },
            { name: 'Minador do Negrão', code: '05309' },
            { name: 'Monteirópolis', code: '05408' },
            { name: 'Murici', code: '05507' },
            { name: 'Novo Lino', code: '05606' },
            { name: "Olho d'Água das Flores", code: '05705' },
            { name: "Olho d'Água do Casado", code: '05804' },
            { name: "Olho d'Água Grande", code: '05903' },
            { name: 'Olivença', code: '06000' },
            { name: 'Ouro Branco', code: '06109' },
            { name: 'Palestina', code: '06208' },
            { name: 'Palmeira dos Índios', code: '06307' },
            { name: 'Pão de Açúcar', code: '06406' },
            { name: 'Pariconha', code: '06422' },
            { name: 'Paripueira', code: '06448' },
            { name: 'Passo de Camaragibe', code: '06505' },
            { name: 'Paulo Jacinto', code: '06604' },
            { name: 'Penedo', code: '06703' },
            { name: 'Piaçabuçu', code: '06802' },
            { name: 'Pilar', code: '06901' },
            { name: 'Pindoba', code: '07008' },
            { name: 'Piranhas', code: '07107' },
            { name: 'Poço das Trincheiras', code: '07206' },
            { name: 'Porto Calvo', code: '07305' },
            { name: 'Porto de Pedras', code: '07404' },
            { name: 'Porto Real do Colégio', code: '07503' },
            { name: 'Quebrangulo', code: '07602' },
            { name: 'Rio Largo', code: '07701' },
            { name: 'Roteiro', code: '07800' },
            { name: 'Santa Luzia do Norte', code: '07909' },
            { name: 'Santana do Ipanema', code: '08006' },
            { name: 'Santana do Mundaú', code: '08105' },
            { name: 'São Brás', code: '08204' },
            { name: 'São José da Laje', code: '08303' },
            { name: 'São José da Tapera', code: '08402' },
            { name: 'São Luís do Quitunde', code: '08501' },
            { name: 'São Miguel dos Campos', code: '08600' },
            { name: 'São Miguel dos Milagres', code: '08709' },
            { name: 'São Sebastião', code: '08808' },
            { name: 'Satuba', code: '08907' },
            { name: 'Senador Rui Palmeira', code: '08956' },
            { name: "Tanque d'Arca", code: '09004' },
            { name: 'Taquarana', code: '09103' },
            { name: 'Teotônio Vilela', code: '09152' },
            { name: 'Traipu', code: '09202' },
            { name: 'União dos Palmares', code: '09301' },
            { name: 'Viçosa', code: '09400' },
        ],
    },
    {
        abbreviation: StateAbbreviation.PB,
        name: 'Paraíba',
        code: '25',
        cities: [
            { name: 'Água Branca', code: '00106' },
            { name: 'Aguiar', code: '00205' },
            { name: 'Alagoa Grande', code: '00304' },
            { name: 'Alagoa Nova', code: '00403' },
            { name: 'Alagoinha', code: '00502' },
            { name: 'Alcantil', code: '00536' },
            { name: 'Algodão de Jandaíra', code: '00577' },
            { name: 'Alhandra', code: '00601' },
            { name: 'Amparo', code: '00734' },
            { name: 'Aparecida', code: '00775' },
            { name: 'Araçagi', code: '00809' },
            { name: 'Arara', code: '00908' },
            { name: 'Araruna', code: '01005' },
            { name: 'Areia', code: '01104' },
            { name: 'Areia de Baraúnas', code: '01153' },
            { name: 'Areial', code: '01203' },
            { name: 'Aroeiras', code: '01302' },
            { name: 'Assunção', code: '01351' },
            { name: 'Baía da Traição', code: '01401' },
            { name: 'Bananeiras', code: '01500' },
            { name: 'Baraúna', code: '01534' },
            { name: 'Barra de Santa Rosa', code: '01609' },
            { name: 'Barra de Santana', code: '01575' },
            { name: 'Barra de São Miguel', code: '01708' },
            { name: 'Bayeux', code: '01807' },
            { name: 'Belém', code: '01906' },
            { name: 'Belém do Brejo do Cruz', code: '02003' },
            { name: 'Bernardino Batista', code: '02052' },
            { name: 'Boa Ventura', code: '02102' },
            { name: 'Boa Vista', code: '02151' },
            { name: 'Bom Jesus', code: '02201' },
            { name: 'Bom Sucesso', code: '02300' },
            { name: 'Bonito de Santa Fé', code: '02409' },
            { name: 'Boqueirão', code: '02508' },
            { name: 'Borborema', code: '02706' },
            { name: 'Brejo do Cruz', code: '02805' },
            { name: 'Brejo dos Santos', code: '02904' },
            { name: 'Caaporã', code: '03001' },
            { name: 'Cabaceiras', code: '03100' },
            { name: 'Cabedelo', code: '03209' },
            { name: 'Cachoeira dos Índios', code: '03308' },
            { name: 'Cacimba de Areia', code: '03407' },
            { name: 'Cacimba de Dentro', code: '03506' },
            { name: 'Cacimbas', code: '03555' },
            { name: 'Caiçara', code: '03605' },
            { name: 'Cajazeiras', code: '03704' },
            { name: 'Cajazeirinhas', code: '03753' },
            { name: 'Caldas Brandão', code: '03803' },
            { name: 'Camalaú', code: '03902' },
            { name: 'Campina Grande', code: '04009' },
            { name: 'Capim', code: '04033' },
            { name: 'Caraúbas', code: '04074' },
            { name: 'Carrapateira', code: '04108' },
            { name: 'Casserengue', code: '04157' },
            { name: 'Catingueira', code: '04207' },
            { name: 'Catolé do Rocha', code: '04306' },
            { name: 'Caturité', code: '04355' },
            { name: 'Conceição', code: '04405' },
            { name: 'Condado', code: '04504' },
            { name: 'Conde', code: '04603' },
            { name: 'Congo', code: '04702' },
            { name: 'Coremas', code: '04801' },
            { name: 'Coxixola', code: '04850' },
            { name: 'Cruz do Espírito Santo', code: '04900' },
            { name: 'Cubati', code: '05006' },
            { name: 'Cuité', code: '05105' },
            { name: 'Cuité de Mamanguape', code: '05238' },
            { name: 'Cuitegi', code: '05204' },
            { name: 'Curral de Cima', code: '05279' },
            { name: 'Curral Velho', code: '05303' },
            { name: 'Damião', code: '05352' },
            { name: 'Desterro', code: '05402' },
            { name: 'Diamante', code: '05600' },
            { name: 'Dona Inês', code: '05709' },
            { name: 'Duas Estradas', code: '05808' },
            { name: 'Emas', code: '05907' },
            { name: 'Esperança', code: '06004' },
            { name: 'Fagundes', code: '06103' },
            { name: 'Frei Martinho', code: '06202' },
            { name: 'Gado Bravo', code: '06251' },
            { name: 'Guarabira', code: '06301' },
            { name: 'Gurinhém', code: '06400' },
            { name: 'Gurjão', code: '06509' },
            { name: 'Ibiara', code: '06608' },
            { name: 'Igaracy', code: '02607' },
            { name: 'Imaculada', code: '06707' },
            { name: 'Ingá', code: '06806' },
            { name: 'Itabaiana', code: '06905' },
            { name: 'Itaporanga', code: '07002' },
            { name: 'Itapororoca', code: '07101' },
            { name: 'Itatuba', code: '07200' },
            { name: 'Jacaraú', code: '07309' },
            { name: 'Jericó', code: '07408' },
            { name: 'João Pessoa', code: '07507' },
            { name: 'Joca Claudino', code: '13653' },
            { name: 'Juarez Távora', code: '07606' },
            { name: 'Juazeirinho', code: '07705' },
            { name: 'Junco do Seridó', code: '07804' },
            { name: 'Juripiranga', code: '07903' },
            { name: 'Juru', code: '08000' },
            { name: 'Lagoa', code: '08109' },
            { name: 'Lagoa de Dentro', code: '08208' },
            { name: 'Lagoa Seca', code: '08307' },
            { name: 'Lastro', code: '08406' },
            { name: 'Livramento', code: '08505' },
            { name: 'Logradouro', code: '08554' },
            { name: 'Lucena', code: '08604' },
            { name: "Mãe d'Água", code: '08703' },
            { name: 'Malta', code: '08802' },
            { name: 'Mamanguape', code: '08901' },
            { name: 'Manaíra', code: '09008' },
            { name: 'Marcação', code: '09057' },
            { name: 'Mari', code: '09107' },
            { name: 'Marizópolis', code: '09156' },
            { name: 'Massaranduba', code: '09206' },
            { name: 'Mataraca', code: '09305' },
            { name: 'Matinhas', code: '09339' },
            { name: 'Mato Grosso', code: '09370' },
            { name: 'Maturéia', code: '09396' },
            { name: 'Mogeiro', code: '09404' },
            { name: 'Montadas', code: '09503' },
            { name: 'Monte Horebe', code: '09602' },
            { name: 'Monteiro', code: '09701' },
            { name: 'Mulungu', code: '09800' },
            { name: 'Natuba', code: '09909' },
            { name: 'Nazarezinho', code: '10006' },
            { name: 'Nova Floresta', code: '10105' },
            { name: 'Nova Olinda', code: '10204' },
            { name: 'Nova Palmeira', code: '10303' },
            { name: "Olho d'Água", code: '10402' },
            { name: 'Olivedos', code: '10501' },
            { name: 'Ouro Velho', code: '10600' },
            { name: 'Parari', code: '10659' },
            { name: 'Passagem', code: '10709' },
            { name: 'Patos', code: '10808' },
            { name: 'Paulista', code: '10907' },
            { name: 'Pedra Branca', code: '11004' },
            { name: 'Pedra Lavrada', code: '11103' },
            { name: 'Pedras de Fogo', code: '11202' },
            { name: 'Pedro Régis', code: '12721' },
            { name: 'Piancó', code: '11301' },
            { name: 'Picuí', code: '11400' },
            { name: 'Pilar', code: '11509' },
            { name: 'Pilões', code: '11608' },
            { name: 'Pilõezinhos', code: '11707' },
            { name: 'Pirpirituba', code: '11806' },
            { name: 'Pitimbu', code: '11905' },
            { name: 'Pocinhos', code: '12002' },
            { name: 'Poço Dantas', code: '12036' },
            { name: 'Poço de José de Moura', code: '12077' },
            { name: 'Pombal', code: '12101' },
            { name: 'Prata', code: '12200' },
            { name: 'Princesa Isabel', code: '12309' },
            { name: 'Puxinanã', code: '12408' },
            { name: 'Queimadas', code: '12507' },
            { name: 'Quixaba', code: '12606' },
            { name: 'Remígio', code: '12705' },
            { name: 'Riachão', code: '12747' },
            { name: 'Riachão do Bacamarte', code: '12754' },
            { name: 'Riachão do Poço', code: '12762' },
            { name: 'Riacho de Santo Antônio', code: '12788' },
            { name: 'Riacho dos Cavalos', code: '12804' },
            { name: 'Rio Tinto', code: '12903' },
            { name: 'Salgadinho', code: '13000' },
            { name: 'Salgado de São Félix', code: '13109' },
            { name: 'Santa Cecília', code: '13158' },
            { name: 'Santa Cruz', code: '13208' },
            { name: 'Santa Helena', code: '13307' },
            { name: 'Santa Inês', code: '13356' },
            { name: 'Santa Luzia', code: '13406' },
            { name: 'Santa Rita', code: '13703' },
            { name: 'Santa Teresinha', code: '13802' },
            { name: 'Santana de Mangueira', code: '13505' },
            { name: 'Santana dos Garrotes', code: '13604' },
            { name: 'Santo André', code: '13851' },
            { name: 'São Bentinho', code: '13927' },
            { name: 'São Bento', code: '13901' },
            { name: 'São Domingos', code: '13968' },
            { name: 'São Domingos do Cariri', code: '13943' },
            { name: 'São Francisco', code: '13984' },
            { name: 'São João do Cariri', code: '14008' },
            { name: 'São João do Rio do Peixe', code: '00700' },
            { name: 'São João do Tigre', code: '14107' },
            { name: 'São José da Lagoa Tapada', code: '14206' },
            { name: 'São José de Caiana', code: '14305' },
            { name: 'São José de Espinharas', code: '14404' },
            { name: 'São José de Piranhas', code: '14503' },
            { name: 'São José de Princesa', code: '14552' },
            { name: 'São José do Bonfim', code: '14602' },
            { name: 'São José do Brejo do Cruz', code: '14651' },
            { name: 'São José do Sabugi', code: '14701' },
            { name: 'São José dos Cordeiros', code: '14800' },
            { name: 'São José dos Ramos', code: '14453' },
            { name: 'São Mamede', code: '14909' },
            { name: 'São Miguel de Taipu', code: '15005' },
            { name: 'São Sebastião de Lagoa de Roça', code: '15104' },
            { name: 'São Sebastião do Umbuzeiro', code: '15203' },
            { name: 'São Vicente do Seridó', code: '15401' },
            { name: 'Sapé', code: '15302' },
            { name: 'Serra Branca', code: '15500' },
            { name: 'Serra da Raiz', code: '15609' },
            { name: 'Serra Grande', code: '15708' },
            { name: 'Serra Redonda', code: '15807' },
            { name: 'Serraria', code: '15906' },
            { name: 'Sertãozinho', code: '15930' },
            { name: 'Sobrado', code: '15971' },
            { name: 'Solânea', code: '16003' },
            { name: 'Soledade', code: '16102' },
            { name: 'Sossêgo', code: '16151' },
            { name: 'Sousa', code: '16201' },
            { name: 'Sumé', code: '16300' },
            { name: 'Tacima', code: '16409' },
            { name: 'Taperoá', code: '16508' },
            { name: 'Tavares', code: '16607' },
            { name: 'Teixeira', code: '16706' },
            { name: 'Tenório', code: '16755' },
            { name: 'Triunfo', code: '16805' },
            { name: 'Uiraúna', code: '16904' },
            { name: 'Umbuzeiro', code: '17001' },
            { name: 'Várzea', code: '17100' },
            { name: 'Vieirópolis', code: '17209' },
            { name: 'Vista Serrana', code: '05501' },
            { name: 'Zabelê', code: '17407' },
        ],
    },
    {
        abbreviation: StateAbbreviation.MS,
        name: 'Mato Grosso do Sul',
        code: '50',
        cities: [
            { name: 'Água Clara', code: '00203' },
            { name: 'Alcinópolis', code: '00252' },
            { name: 'Amambai', code: '00609' },
            { name: 'Anastácio', code: '00708' },
            { name: 'Anaurilândia', code: '00807' },
            { name: 'Angélica', code: '00856' },
            { name: 'Antônio João', code: '00906' },
            { name: 'Aparecida do Taboado', code: '01003' },
            { name: 'Aquidauana', code: '01102' },
            { name: 'Aral Moreira', code: '01243' },
            { name: 'Bandeirantes', code: '01508' },
            { name: 'Bataguassu', code: '01904' },
            { name: 'Batayporã', code: '02001' },
            { name: 'Bela Vista', code: '02100' },
            { name: 'Bodoquena', code: '02159' },
            { name: 'Bonito', code: '02209' },
            { name: 'Brasilândia', code: '02308' },
            { name: 'Caarapó', code: '02407' },
            { name: 'Camapuã', code: '02605' },
            { name: 'Campo Grande', code: '02704' },
            { name: 'Caracol', code: '02803' },
            { name: 'Cassilândia', code: '02902' },
            { name: 'Chapadão do Sul', code: '02951' },
            { name: 'Corguinho', code: '03108' },
            { name: 'Coronel Sapucaia', code: '03157' },
            { name: 'Corumbá', code: '03207' },
            { name: 'Costa Rica', code: '03256' },
            { name: 'Coxim', code: '03306' },
            { name: 'Deodápolis', code: '03454' },
            { name: 'Dois Irmãos do Buriti', code: '03488' },
            { name: 'Douradina', code: '03504' },
            { name: 'Dourados', code: '03702' },
            { name: 'Eldorado', code: '03751' },
            { name: 'Fátima do Sul', code: '03801' },
            { name: 'Figueirão', code: '03900' },
            { name: 'Glória de Dourados', code: '04007' },
            { name: 'Guia Lopes da Laguna', code: '04106' },
            { name: 'Iguatemi', code: '04304' },
            { name: 'Inocência', code: '04403' },
            { name: 'Itaporã', code: '04502' },
            { name: 'Itaquiraí', code: '04601' },
            { name: 'Ivinhema', code: '04700' },
            { name: 'Japorã', code: '04809' },
            { name: 'Jaraguari', code: '04908' },
            { name: 'Jardim', code: '05004' },
            { name: 'Jateí', code: '05103' },
            { name: 'Juti', code: '05152' },
            { name: 'Ladário', code: '05202' },
            { name: 'Laguna Carapã', code: '05251' },
            { name: 'Maracaju', code: '05400' },
            { name: 'Miranda', code: '05608' },
            { name: 'Mundo Novo', code: '05681' },
            { name: 'Naviraí', code: '05707' },
            { name: 'Nioaque', code: '05806' },
            { name: 'Nova Alvorada do Sul', code: '06002' },
            { name: 'Nova Andradina', code: '06200' },
            { name: 'Novo Horizonte do Sul', code: '06259' },
            { name: 'Paraíso das Águas', code: '06275' },
            { name: 'Paranaíba', code: '06309' },
            { name: 'Paranhos', code: '06358' },
            { name: 'Pedro Gomes', code: '06408' },
            { name: 'Ponta Porã', code: '06606' },
            { name: 'Porto Murtinho', code: '06903' },
            { name: 'Ribas do Rio Pardo', code: '07109' },
            { name: 'Rio Brilhante', code: '07208' },
            { name: 'Rio Negro', code: '07307' },
            { name: 'Rio Verde de Mato Grosso', code: '07406' },
            { name: 'Rochedo', code: '07505' },
            { name: 'Santa Rita do Pardo', code: '07554' },
            { name: 'São Gabriel do Oeste', code: '07695' },
            { name: 'Selvíria', code: '07802' },
            { name: 'Sete Quedas', code: '07703' },
            { name: 'Sidrolândia', code: '07901' },
            { name: 'Sonora', code: '07935' },
            { name: 'Tacuru', code: '07950' },
            { name: 'Taquarussu', code: '07976' },
            { name: 'Terenos', code: '08008' },
            { name: 'Três Lagoas', code: '08305' },
            { name: 'Vicentina', code: '08404' },
        ],
    },
    {
        abbreviation: StateAbbreviation.RO,
        name: 'Rondônia',
        code: '11',
        cities: [
            { name: "Alta Floresta D'Oeste", code: '00015' },
            { name: 'Alto Alegre dos Parecis', code: '00379' },
            { name: 'Alto Paraíso', code: '00403' },
            { name: "Alvorada D'Oeste", code: '00346' },
            { name: 'Ariquemes', code: '00023' },
            { name: 'Buritis', code: '00452' },
            { name: 'Cabixi', code: '00031' },
            { name: 'Cacaulândia', code: '00601' },
            { name: 'Cacoal', code: '00049' },
            { name: 'Campo Novo de Rondônia', code: '00700' },
            { name: 'Candeias do Jamari', code: '00809' },
            { name: 'Castanheiras', code: '00908' },
            { name: 'Cerejeiras', code: '00056' },
            { name: 'Chupinguaia', code: '00924' },
            { name: 'Colorado do Oeste', code: '00064' },
            { name: 'Corumbiara', code: '00072' },
            { name: 'Costa Marques', code: '00080' },
            { name: 'Cujubim', code: '00940' },
            { name: "Espigão D'Oeste", code: '00098' },
            { name: 'Governador Jorge Teixeira', code: '01005' },
            { name: 'Guajará-Mirim', code: '00106' },
            { name: 'Itapuã do Oeste', code: '01104' },
            { name: 'Jaru', code: '00114' },
            { name: 'Ji-Paraná', code: '00122' },
            { name: "Machadinho D'Oeste", code: '00130' },
            { name: 'Ministro Andreazza', code: '01203' },
            { name: 'Mirante da Serra', code: '01302' },
            { name: 'Monte Negro', code: '01401' },
            { name: "Nova Brasilândia D'Oeste", code: '00148' },
            { name: 'Nova Mamoré', code: '00338' },
            { name: 'Nova União', code: '01435' },
            { name: 'Novo Horizonte do Oeste', code: '00502' },
            { name: 'Ouro Preto do Oeste', code: '00155' },
            { name: 'Parecis', code: '01450' },
            { name: 'Pimenta Bueno', code: '00189' },
            { name: 'Pimenteiras do Oeste', code: '01468' },
            { name: 'Porto Velho', code: '00205' },
            { name: 'Presidente Médici', code: '00254' },
            { name: 'Primavera de Rondônia', code: '01476' },
            { name: 'Rio Crespo', code: '00262' },
            { name: 'Rolim de Moura', code: '00288' },
            { name: "Santa Luzia D'Oeste", code: '00296' },
            { name: "São Felipe D'Oeste", code: '01484' },
            { name: 'São Francisco do Guaporé', code: '01492' },
            { name: 'São Miguel do Guaporé', code: '00320' },
            { name: 'Seringueiras', code: '01500' },
            { name: 'Teixeirópolis', code: '01559' },
            { name: 'Theobroma', code: '01609' },
            { name: 'Urupá', code: '01708' },
            { name: 'Vale do Anari', code: '01757' },
            { name: 'Vale do Paraíso', code: '01807' },
            { name: 'Vilhena', code: '00304' },
        ],
    },
    {
        abbreviation: StateAbbreviation.RR,
        name: 'Roraima',
        code: '14',
        cities: [
            { name: 'Alto Alegre', code: '00050' },
            { name: 'Amajari', code: '00027' },
            { name: 'Boa Vista', code: '00100' },
            { name: 'Bonfim', code: '00159' },
            { name: 'Cantá', code: '00175' },
            { name: 'Caracaraí', code: '00209' },
            { name: 'Caroebe', code: '00233' },
            { name: 'Iracema', code: '00282' },
            { name: 'Mucajaí', code: '00308' },
            { name: 'Normandia', code: '00407' },
            { name: 'Pacaraima', code: '00456' },
            { name: 'Rorainópolis', code: '00472' },
            { name: 'São João da Baliza', code: '00506' },
            { name: 'São Luiz', code: '00605' },
            { name: 'Uiramutã', code: '00704' },
        ],
    },
    {
        abbreviation: StateAbbreviation.AM,
        name: 'Amazonas',
        code: '13',
        cities: [
            { name: 'Alvarães', code: '00029' },
            { name: 'Amaturá', code: '00060' },
            { name: 'Anamã', code: '00086' },
            { name: 'Anori', code: '00102' },
            { name: 'Apuí', code: '00144' },
            { name: 'Atalaia do Norte', code: '00201' },
            { name: 'Autazes', code: '00300' },
            { name: 'Barcelos', code: '00409' },
            { name: 'Barreirinha', code: '00508' },
            { name: 'Benjamin Constant', code: '00607' },
            { name: 'Beruri', code: '00631' },
            { name: 'Boa Vista do Ramos', code: '00680' },
            { name: 'Boca do Acre', code: '00706' },
            { name: 'Borba', code: '00805' },
            { name: 'Caapiranga', code: '00839' },
            { name: 'Canutama', code: '00904' },
            { name: 'Carauari', code: '01001' },
            { name: 'Careiro', code: '01100' },
            { name: 'Careiro da Várzea', code: '01159' },
            { name: 'Coari', code: '01209' },
            { name: 'Codajás', code: '01308' },
            { name: 'Eirunepé', code: '01407' },
            { name: 'Envira', code: '01506' },
            { name: 'Fonte Boa', code: '01605' },
            { name: 'Guajará', code: '01654' },
            { name: 'Humaitá', code: '01704' },
            { name: 'Ipixuna', code: '01803' },
            { name: 'Iranduba', code: '01852' },
            { name: 'Itacoatiara', code: '01902' },
            { name: 'Itamarati', code: '01951' },
            { name: 'Itapiranga', code: '02009' },
            { name: 'Japurá', code: '02108' },
            { name: 'Juruá', code: '02207' },
            { name: 'Jutaí', code: '02306' },
            { name: 'Lábrea', code: '02405' },
            { name: 'Manacapuru', code: '02504' },
            { name: 'Manaquiri', code: '02553' },
            { name: 'Manaus', code: '02603' },
            { name: 'Manicoré', code: '02702' },
            { name: 'Maraã', code: '02801' },
            { name: 'Maués', code: '02900' },
            { name: 'Nhamundá', code: '03007' },
            { name: 'Nova Olinda do Norte', code: '03106' },
            { name: 'Novo Airão', code: '03205' },
            { name: 'Novo Aripuanã', code: '03304' },
            { name: 'Parintins', code: '03403' },
            { name: 'Pauini', code: '03502' },
            { name: 'Presidente Figueiredo', code: '03536' },
            { name: 'Rio Preto da Eva', code: '03569' },
            { name: 'Santa Isabel do Rio Negro', code: '03601' },
            { name: 'Santo Antônio do Içá', code: '03700' },
            { name: 'São Gabriel da Cachoeira', code: '03809' },
            { name: 'São Paulo de Olivença', code: '03908' },
            { name: 'São Sebastião do Uatumã', code: '03957' },
            { name: 'Silves', code: '04005' },
            { name: 'Tabatinga', code: '04062' },
            { name: 'Tapauá', code: '04104' },
            { name: 'Tefé', code: '04203' },
            { name: 'Tonantins', code: '04237' },
            { name: 'Uarini', code: '04260' },
            { name: 'Urucará', code: '04302' },
            { name: 'Urucurituba', code: '04401' },
        ],
    },
    {
        abbreviation: StateAbbreviation.AP,
        name: 'Amapá',
        code: '16',
        cities: [
            { name: 'Amapá', code: '00105' },
            { name: 'Calçoene', code: '00204' },
            { name: 'Cutias', code: '00212' },
            { name: 'Ferreira Gomes', code: '00238' },
            { name: 'Itaubal', code: '00253' },
            { name: 'Laranjal do Jari', code: '00279' },
            { name: 'Macapá', code: '00303' },
            { name: 'Mazagão', code: '00402' },
            { name: 'Oiapoque', code: '00501' },
            { name: 'Pedra Branca do Amapari', code: '00154' },
            { name: 'Porto Grande', code: '00535' },
            { name: 'Pracuúba', code: '00550' },
            { name: 'Santana', code: '00600' },
            { name: 'Serra do Navio', code: '00055' },
            { name: 'Tartarugalzinho', code: '00709' },
            { name: 'Vitória do Jari', code: '00808' },
        ],
    },
    {
        abbreviation: StateAbbreviation.SE,
        name: 'Sergipe',
        code: '28',
        cities: [
            { name: 'Amparo do São Francisco', code: '00100' },
            { name: 'Aquidabã', code: '00209' },
            { name: 'Aracaju', code: '00308' },
            { name: 'Arauá', code: '00407' },
            { name: 'Areia Branca', code: '00506' },
            { name: 'Barra dos Coqueiros', code: '00605' },
            { name: 'Boquim', code: '00670' },
            { name: 'Brejo Grande', code: '00704' },
            { name: 'Campo do Brito', code: '01009' },
            { name: 'Canhoba', code: '01108' },
            { name: 'Canindé de São Francisco', code: '01207' },
            { name: 'Capela', code: '01306' },
            { name: 'Carira', code: '01405' },
            { name: 'Carmópolis', code: '01504' },
            { name: 'Cedro de São João', code: '01603' },
            { name: 'Cristinápolis', code: '01702' },
            { name: 'Cumbe', code: '01900' },
            { name: 'Divina Pastora', code: '02007' },
            { name: 'Estância', code: '02106' },
            { name: 'Feira Nova', code: '02205' },
            { name: 'Frei Paulo', code: '02304' },
            { name: 'Gararu', code: '02403' },
            { name: 'General Maynard', code: '02502' },
            { name: 'Gracho Cardoso', code: '02601' },
            { name: 'Ilha das Flores', code: '02700' },
            { name: 'Indiaroba', code: '02809' },
            { name: 'Itabaiana', code: '02908' },
            { name: 'Itabaianinha', code: '03005' },
            { name: 'Itabi', code: '03104' },
            { name: "Itaporanga d'Ajuda", code: '03203' },
            { name: 'Japaratuba', code: '03302' },
            { name: 'Japoatã', code: '03401' },
            { name: 'Lagarto', code: '03500' },
            { name: 'Laranjeiras', code: '03609' },
            { name: 'Macambira', code: '03708' },
            { name: 'Malhada dos Bois', code: '03807' },
            { name: 'Malhador', code: '03906' },
            { name: 'Maruim', code: '04003' },
            { name: 'Moita Bonita', code: '04102' },
            { name: 'Monte Alegre de Sergipe', code: '04201' },
            { name: 'Muribeca', code: '04300' },
            { name: 'Neópolis', code: '04409' },
            { name: 'Nossa Senhora Aparecida', code: '04458' },
            { name: 'Nossa Senhora da Glória', code: '04508' },
            { name: 'Nossa Senhora das Dores', code: '04607' },
            { name: 'Nossa Senhora de Lourdes', code: '04706' },
            { name: 'Nossa Senhora do Socorro', code: '04805' },
            { name: 'Pacatuba', code: '04904' },
            { name: 'Pedra Mole', code: '05000' },
            { name: 'Pedrinhas', code: '05109' },
            { name: 'Pinhão', code: '05208' },
            { name: 'Pirambu', code: '05307' },
            { name: 'Poço Redondo', code: '05406' },
            { name: 'Poço Verde', code: '05505' },
            { name: 'Porto da Folha', code: '05604' },
            { name: 'Propriá', code: '05703' },
            { name: 'Riachão do Dantas', code: '05802' },
            { name: 'Riachuelo', code: '05901' },
            { name: 'Ribeirópolis', code: '06008' },
            { name: 'Rosário do Catete', code: '06107' },
            { name: 'Salgado', code: '06206' },
            { name: 'Santa Luzia do Itanhy', code: '06305' },
            { name: 'Santa Rosa de Lima', code: '06503' },
            { name: 'Santana do São Francisco', code: '06404' },
            { name: 'Santo Amaro das Brotas', code: '06602' },
            { name: 'São Cristóvão', code: '06701' },
            { name: 'São Domingos', code: '06800' },
            { name: 'São Francisco', code: '06909' },
            { name: 'São Miguel do Aleixo', code: '07006' },
            { name: 'Simão Dias', code: '07105' },
            { name: 'Siriri', code: '07204' },
            { name: 'Telha', code: '07303' },
            { name: 'Tobias Barreto', code: '07402' },
            { name: 'Tomar do Geru', code: '07501' },
            { name: 'Umbaúba', code: '07600' },
        ],
    },
    {
        abbreviation: StateAbbreviation.RJ,
        name: 'Rio de Janeiro',
        code: '33',
        cities: [
            { name: 'Angra dos Reis', code: '00100' },
            { name: 'Aperibé', code: '00159' },
            { name: 'Araruama', code: '00209' },
            { name: 'Areal', code: '00225' },
            { name: 'Armação dos Búzios', code: '00233' },
            { name: 'Arraial do Cabo', code: '00258' },
            { name: 'Barra do Piraí', code: '00308' },
            { name: 'Barra Mansa', code: '00407' },
            { name: 'Belford Roxo', code: '00456' },
            { name: 'Bom Jardim', code: '00506' },
            { name: 'Bom Jesus do Itabapoana', code: '00605' },
            { name: 'Cabo Frio', code: '00704' },
            { name: 'Cachoeiras de Macacu', code: '00803' },
            { name: 'Cambuci', code: '00902' },
            { name: 'Campos dos Goytacazes', code: '01009' },
            { name: 'Cantagalo', code: '01108' },
            { name: 'Carapebus', code: '00936' },
            { name: 'Cardoso Moreira', code: '01157' },
            { name: 'Carmo', code: '01207' },
            { name: 'Casimiro de Abreu', code: '01306' },
            { name: 'Comendador Levy Gasparian', code: '00951' },
            { name: 'Conceição de Macabu', code: '01405' },
            { name: 'Cordeiro', code: '01504' },
            { name: 'Duas Barras', code: '01603' },
            { name: 'Duque de Caxias', code: '01702' },
            { name: 'Engenheiro Paulo de Frontin', code: '01801' },
            { name: 'Guapimirim', code: '01850' },
            { name: 'Iguaba Grande', code: '01876' },
            { name: 'Itaboraí', code: '01900' },
            { name: 'Itaguaí', code: '02007' },
            { name: 'Italva', code: '02056' },
            { name: 'Itaocara', code: '02106' },
            { name: 'Itaperuna', code: '02205' },
            { name: 'Itatiaia', code: '02254' },
            { name: 'Japeri', code: '02270' },
            { name: 'Laje do Muriaé', code: '02304' },
            { name: 'Macaé', code: '02403' },
            { name: 'Macuco', code: '02452' },
            { name: 'Magé', code: '02502' },
            { name: 'Mangaratiba', code: '02601' },
            { name: 'Maricá', code: '02700' },
            { name: 'Mendes', code: '02809' },
            { name: 'Mesquita', code: '02858' },
            { name: 'Miguel Pereira', code: '02908' },
            { name: 'Miracema', code: '03005' },
            { name: 'Natividade', code: '03104' },
            { name: 'Nilópolis', code: '03203' },
            { name: 'Niterói', code: '03302' },
            { name: 'Nova Friburgo', code: '03401' },
            { name: 'Nova Iguaçu', code: '03500' },
            { name: 'Paracambi', code: '03609' },
            { name: 'Paraíba do Sul', code: '03708' },
            { name: 'Paraty', code: '03807' },
            { name: 'Paty do Alferes', code: '03856' },
            { name: 'Petrópolis', code: '03906' },
            { name: 'Pinheiral', code: '03955' },
            { name: 'Piraí', code: '04003' },
            { name: 'Porciúncula', code: '04102' },
            { name: 'Porto Real', code: '04110' },
            { name: 'Quatis', code: '04128' },
            { name: 'Queimados', code: '04144' },
            { name: 'Quissamã', code: '04151' },
            { name: 'Resende', code: '04201' },
            { name: 'Rio Bonito', code: '04300' },
            { name: 'Rio Claro', code: '04409' },
            { name: 'Rio das Flores', code: '04508' },
            { name: 'Rio das Ostras', code: '04524' },
            { name: 'Rio de Janeiro', code: '04557' },
            { name: 'Santa Maria Madalena', code: '04607' },
            { name: 'Santo Antônio de Pádua', code: '04706' },
            { name: 'São Fidélis', code: '04805' },
            { name: 'São Francisco de Itabapoana', code: '04755' },
            { name: 'São Gonçalo', code: '04904' },
            { name: 'São João da Barra', code: '05000' },
            { name: 'São João de Meriti', code: '05109' },
            { name: 'São José de Ubá', code: '05133' },
            { name: 'São José do Vale do Rio Preto', code: '05158' },
            { name: 'São Pedro da Aldeia', code: '05208' },
            { name: 'São Sebastião do Alto', code: '05307' },
            { name: 'Sapucaia', code: '05406' },
            { name: 'Saquarema', code: '05505' },
            { name: 'Seropédica', code: '05554' },
            { name: 'Silva Jardim', code: '05604' },
            { name: 'Sumidouro', code: '05703' },
            { name: 'Tanguá', code: '05752' },
            { name: 'Teresópolis', code: '05802' },
            { name: 'Trajano de Moraes', code: '05901' },
            { name: 'Três Rios', code: '06008' },
            { name: 'Valença', code: '06107' },
            { name: 'Varre-Sai', code: '06156' },
            { name: 'Vassouras', code: '06206' },
            { name: 'Volta Redonda', code: '06305' },
        ],
    },
    {
        abbreviation: StateAbbreviation.DF,
        name: 'Distrito Federal',
        code: '53',
        cities: [{ name: 'Brasília', code: '00108' }],
    },
];
