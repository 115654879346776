<!-- Loading -->
<app-loading [isOpen]="state.loadingModal || state.loading"></app-loading>

<mat-toolbar class="material-dialog-toolbar tm-dialog-toolbar-color" color="primary">
  <button mat-icon-button aria-label="Close Modal" (click)="onCloseModal()" tabindex="-1">
    <i class="las la-times"></i>
  </button>
  <span *ngIf="data.edit" class="title">{{ trans.button.editField | label }}</span>
  <span *ngIf="!data.edit" class="title">{{ trans.button.createField | label }}</span>
</mat-toolbar>

<mat-dialog-content class="material-dialog-content">
  <mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form class="custom-form-field" [formGroup]="firstFormGroup">
        <div fxLayout="column" fxLayout.gt-xs="row wrap" fxLayout.gt-md="row wrap">
          <!-- name -->
          <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
            <input
              matInput
              type="text"
              id="name"
              formControlName="name"
              [appDisableControl]="true"
              required
            />
            <mat-error>
              {{ getInputMessageError('name', firstFormGroup.get('name')?.errors) | translate }}
            </mat-error>
          </mat-form-field>

          <!-- alias -->
          <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
            <input
              matInput
              type="text"
              id="alias"
              formControlName="alias"
              placeholder="{{ trans.placeholder.alias | translate }}"
              minlength="2"
              maxlength="64"
              [pattern]="regex.text"
              cdkFocusInitial
            />
            <mat-error>
              {{ getInputMessageError('alias', firstFormGroup.get('alias')?.errors) | translate }}
            </mat-error>
          </mat-form-field>

          <!-- glebe -->
          <mat-form-field fxFlex="100%" fxFlex.gt-xs="0 1 50%">
            <mat-label>{{ trans.label.glebe | label }}</mat-label>
            <mat-select
              #materialSelect
              id="glebeId"
              formControlName="glebeId"
              (selectionChange)="onSelectionChangeGlebe($event.value)"
              required
            >
              <mat-option
                class="material-select-option-message"
                *ngIf="state.glebes.length <= 0"
                [disabled]="true"
              >
                {{ trans.text.noRegisteredGlebes | label }}</mat-option
              >
              <mat-option
                *ngFor="let glebe of state.glebes"
                [value]="glebe.id"
                [disabled]="glebe.harvested"
              >
                {{ glebe.name }}
                <span *ngIf="glebe.alias && glebe.alias !== ''">( {{ glebe.alias }} )</span>
                <span
                  style="float: right"
                  matTooltipClass="material-tooltip-class"
                  [matTooltip]="trans.text.harvestedDate | translate"
                  [matTooltipPosition]="'left'"
                  *ngIf="glebe.harvested"
                >
                  {{ moment(glebe.finalHarvestDate.toDate()).format(trans.text.date | translate) }}
                </span>
              </mat-option>
            </mat-select>
            <mat-error>
              {{ getInputMessageError('glebe', firstFormGroup.get('glebe')?.errors) | label }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" *ngIf="!data.edit">
      <div>
        <mat-label class="material-text-two-lines"
          >{{ trans.label.drawPolygonToField | label }}:</mat-label
        >
        <!-- google maps polygon -->
        <div
          fxLayout="column"
          fxLayout.gt-xs="row wrap"
          fxLayout.gt-md="row wrap"
          *ngIf="stepper.selectedIndex === 1"
        >
          <app-google-maps-draw-polygon
            fxFlex="100%"
            [height]="'365px'"
            [polygon]="secondFormGroup.get('polygon')?.value"
            [setPolygons]="polygonsByGlebe"
            (eventDrawEnd)="onDrawEndPolygon($event)"
            [enableDrawing]="!state.hasTrapLinkedField"
          ></app-google-maps-draw-polygon>
        </div>
        <mat-error *ngIf="!secondFormGroup.get('polygon')?.pristine">
          {{ getInputMessageError('polygon', secondFormGroup.get('polygon')?.errors) | translate }}
        </mat-error>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>

<mat-dialog-actions class="material-dialog-actions" align="space-between">
  <button mat-button (click)="onCloseModal()">{{ trans.button.close | translate }}</button>
  <div>
    <button
      mat-button
      color="primary"
      (click)="stepper.previous()"
      *ngIf="stepper.selectedIndex === 1"
    >
      {{ trans.button.back | translate }}
    </button>
    <button
      mat-button
      color="primary"
      (click)="onNext(stepper)"
      *ngIf="stepper.selectedIndex === 0 && !data.edit"
    >
      {{ trans.button.next | translate }}
    </button>
    <button
      mat-button
      color="primary"
      (click)="onFormClick()"
      *ngIf="stepper.selectedIndex === 1 || data.edit"
    >
      {{ trans.button.save | translate }}
    </button>
  </div>
</mat-dialog-actions>
