import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MATERIAL_DATE_YEAR } from 'src/app/constants/mask';
import { Regex, regex } from 'src/app/constants/regex';
import { CREATE_FARM, GET_USERS, HIDE_MODAL, UPDATE_FARM } from 'src/app/view/farm/farm.actions';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateTypes } from 'src/app/services/translation.service';
import { CreateFarmData, FarmState, UpdateFarmData, farmStateDefault } from '../farm.state';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import * as Moment from 'moment';
import * as _ from 'lodash';
import {
  CreateFarmForm,
  FarmForm,
  UpdateFarmForm,
} from '@tarvos-ag/tarvos-firestore-models/src/httpFunctions/farm';
import { FormService } from 'src/app/services/form.service';
import { MyToastrService } from 'src/app/services/toastr.service';
import { LabelPipe } from 'src/app/pipes/label.pipe';
import { HarvestPeriod } from '@tarvos-ag/tarvos-firestore-models/src/enums';
import { StatesAndCitiesConst } from '@tarvos-ag/tarvos-firestore-models/src/constants/mapa';
import {
  City,
  State,
  StatesAndCities,
} from '@tarvos-ag/tarvos-firestore-models/src/interfaces/StatesAndCities';

@Component({
  selector: 'app-farm-form',
  templateUrl: './farm-form.component.html',
  styleUrls: ['./farm-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATE_YEAR },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class FarmFormComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) public form!: NgForm;

  public farmState$: Observable<FarmState>;
  public state: FarmState = farmStateDefault;
  public subscribe!: Subscription;
  public cols!: Observable<number | undefined>;
  public regex: Regex = regex;
  public moment: any = Moment;
  public farmBeforeEdit?: UpdateFarmData;

  public statesAndCities = StatesAndCitiesConst;
  // public stateValues: Array<StateUF> = (Object.keys(StatesAndCitiesConst) as Array<StateUF>).filter(
  //   (state) => Object.keys(StatesAndCitiesConst[state].cities).length > 0
  // );
  public statesValues: StatesAndCities = StatesAndCitiesConst.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  public cityValues: Array<City> = [];
  public selectedState?: State = undefined;
  public selectedCity?: City = undefined;

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<FarmFormComponent>,
    public applicationService: ApplicationService,
    public formService: FormService,
    public toastrService: MyToastrService,
    private labelPipe: LabelPipe,
    public trans: TranslateTypes,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.farmState$ = this.store.pipe(select('farm'));
  }

  public ngOnInit(): void {
    this.store.dispatch(GET_USERS());

    this.subscribe = this.farmState$.subscribe((state: FarmState) => {
      this.state = state;

      if (state && state.closeModal) {
        this.closeModal();
      }
    });

    if (this.data.edit) {
      this.farmBeforeEdit = _.cloneDeep(this.state.farmForm) as UpdateFarmData;
      this.selectedState = StatesAndCitiesConst.find((s) => s.name === this.state.farmForm.state);
      this.cityValues =
        this.selectedState?.cities.sort((a, b) => a.name.localeCompare(b.name)) || [];
      this.selectedCity = this.selectedState?.cities.find(
        (c) => c.name === this.state.farmForm.city
      );
    }
  }

  /**
   * Este método é executado quando o componente e destruído
   */
  public ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  /**
   * Este método limpa o redux e o formulário, além de fechar a modal
   */
  public closeModal(): void {
    this.form.reset();
    this.store.dispatch(HIDE_MODAL());
    this.dialogRef.close();
  }

  /**
   * Este método cadastra ou edita
   */
  public create(): void {
    if (!this.data.edit) {
      const farm = this.generatesCreateFarmForm();
      this.store.dispatch(
        CREATE_FARM({
          farm,
        })
      );
    } else {
      const farm = this.generatesUpdateFarmForm();
      const farmForm = _.cloneDeep(this.state.farmForm);
      const id = farmForm.id ? farmForm.id : '';
      if (Object.values(farm).length <= 0) {
        return this.toastrService.error(this.labelPipe.transform(this.trans.text.noFieldsChanged));
      }
      this.store.dispatch(
        UPDATE_FARM({
          farm: {
            ...farm,
            id,
          },
        })
      );
    }
  }

  /**
   * Este método seta o ano e fecha o datepicker
   */
  public chosenYearHandler(normalizedYear: any, datepicker: MatDatepicker<any>): void {
    if (this.state.farmForm.harvest) {
      this.state.farmForm.harvest.harvestPeriod = 'FIRST_HARVEST';
      this.state.farmForm.harvest.name = '';
      this.state.farmForm.harvest.currentYear = normalizedYear;
    }

    datepicker.close();
  }

  /**
   * Este método converte um enum em lista
   */
  public getEnumType(): Array<HarvestPeriod> {
    return Object.values(HarvestPeriod);
  }
  private generatesCreateFarmForm(): CreateFarmForm {
    const farmForm = _.cloneDeep(this.state.farmForm);
    delete farmForm.id;
    const farm: FarmForm = {
      ...farmForm,
    } as CreateFarmData;
    return {
      farmData: farm,
      customerId: this.applicationService.getCustomerId(),
    };
  }

  private generatesUpdateFarmForm(): UpdateFarmData {
    const farmForm = _.cloneDeep(this.state.farmForm);
    delete farmForm.harvest;
    const farm: UpdateFarmForm = farmForm as UpdateFarmData;

    const farmAfterEdit: UpdateFarmForm = {
      id: farm.id,
      city: farm.city,
      coordinates: farm.coordinates,
      country: farm.country,
      district: farm.district,
      name: farm.name,
      state: farm.state,
    };

    const glebeDataEdit = this.formService.getDifferences(this.farmBeforeEdit, farmAfterEdit);
    return { ...glebeDataEdit };
  }

  public onSelectionChangeState(stateName: string): void {
    this.selectedState = this.statesValues.find((state) => state.name === stateName);
    this.cityValues = this.selectedState!.cities.sort((a, b) => a.name.localeCompare(b.name));
  }

  public onSelectionChangeCity(cityName: string): void {
    this.selectedCity = this.cityValues.find((city) => city.name === cityName);
  }
}
